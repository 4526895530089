export const SetOffLine=(valor)=>{
    window.localStorage.setItem('offline', JSON.stringify({ modeOffline: valor }));
}
export const GetOffLine=()=>{
    const {modeOffline}=JSON.parse(window.localStorage.getItem('offline'));    
    if (modeOffline!==null && modeOffline!==undefined){
        return modeOffline;
    }
    else{
        return true;
    }
    
}

export const CargarObjEjecucionEtapaTarea = async(idtarea, idreporte, etapatarea) => {
    const idreporteFinal= idreporte===' ' ? 'rptOffline'.concat((Math.random()  * 22).toString(36).substring(2)) : idreporte;
    let promesa = new Promise(function(resolve, reject) {

        const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));
        const tareaSeleccionada = JSON.parse(window.localStorage.getItem('recSel'));
        const {descripcion,foto,variables}=tareaSeleccionada.length>=1?tareaSeleccionada[0]:tareaSeleccionada;
        const variablesOffline= JSON.parse(window.localStorage.getItem('variablesOffline'));
        const itemReportes = variablesOffline!==undefined && variablesOffline!==null ?  variablesOffline.filter(x => x.idreporte.toLowerCase() === idreporteFinal.toLowerCase()         
                                                    && x.idtarea.toLowerCase() === idtarea.toLowerCase()
                                                    && x.etapatarea.toLowerCase() === etapatarea.toLowerCase()) 
                                                    : null;
        const variablesFinales=itemReportes!==null && itemReportes.length>0 ? itemReportes[itemReportes.length-1] : variables;
        let response = {
            "datatable": [],
            "listadoobjetosestado": [{
                    "id": "CmdCerrar",
                    "nombre": "Cerrar",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "LblDescripcion",
                    "nombre": "Datatable.DescripcionTarea",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "LblProgramacion",
                    "nombre": "Datatable.TextoProgramacion",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "LblFecha",
                    "nombre": "Fecha Inicio",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "LblUnidadMedida",
                    "nombre": "Datatable.IdUnidadMedida",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "CmdInicio",
                    "nombre": "Inicio",
                    "activo": etapatarea === "Inicio" ? false : true,
                    //"visible": etapatarea === "Inicio"
                    "visible": false
                },                
                {
                    "id": "CmdFin",
                    "nombre": "Fin",
                    "activo": etapatarea === "Fin" ? false : true,
                    //"visible": etapatarea === "Fin"
                    "visible": false
                },
                {
                    "id": "CmdRelevante",
                    "nombre": "Relevante",
                    "activo": etapatarea === "Relevante" ? false : true,
                    //"visible": etapatarea === "Relevante" 
                    "visible": false
                },
                {
                    "id": "CmdAmarillado",
                    "nombre": "Amarillado",
                    "activo": false,
                    "visible": false
                },
                {
                    "id": "TxtObsConcepto",
                    "nombre": "Datatable.ObservacionSupervisor",
                    "activo": false,
                    "visible": false
                },
                {
                    "id": "CmdConfirmar",
                    "nombre": "Confirmar",
                    "activo": false,
                    "visible": false
                },
                {
                    "id": "CmdRechazar",
                    "nombre": "Rechazar",
                    "activo": false,
                    "visible": false
                },
                {
                    "id": "CmdAprobar",
                    "nombre": "Aprobar",
                    "activo": false,
                    "visible": false
                }
            ],
            "etapatarea": etapatarea,
            "variables": variablesFinales!==undefined && variablesFinales!==null ? {...variablesFinales} :  [],
            "idnuevoreporte": idreporteFinal            
        };
        let estadoEjecucionTarea={
            "id": "CmdEjecucion",
            "nombre": "Ejecución",
            "activo": etapatarea === "Ejecución" ? false : true,
            //"visible": etapatarea === "Ejecución"
            "visible" : false
        };

        if (ejecOff !== null) {
            let itemEtapa = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporteFinal.toLowerCase() && x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea === etapatarea)
            if (itemEtapa !== undefined && itemEtapa !== null && itemEtapa.length > 0) {
                console.log(itemEtapa[0]);
                const { fechaconcepto, cantidadreportada, data64file, observacion, tareaterminada,textoprogramacion, idunidadmedida } = itemEtapa[0];
                response.datatable.push({
                    "idreporte": idreporteFinal,
                    "idtarea": idtarea,
                    "rol": "Offline",
                    "fechareporte": new Date(new Date(fechaconcepto).toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                    "etapatarea": etapatarea,
                    "cantidad": cantidadreportada,
                    "idunidadmedida": idunidadmedida!==undefined && idunidadmedida!==null ? idunidadmedida:"",
                    "foto": data64file,
                    "observacioncapataz": observacion,
                    "idSupervisor": null,
                    "conceptosupervisor": "No confirmado",
                    "observacionsupervisor": null,
                    "idingeniero": null,
                    "conceptoingeniero": "No confirmado",
                    "observacioningeniero": null,
                    "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                    "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",                    
                    "tareaterminada": tareaterminada
                });
                response.listadoobjetosestado.push({
                    "id": "CmdRecursos",
                    "nombre": "Personal/Equipos",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "CmdVariables",
                    "nombre": "Variables",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "LblTituloPantalla",
                    "nombre": "Reporte ".concat( tareaterminada ? "NO SINCRONIZADO" : "OFFLINE", " Etapa "," ",etapatarea),
                    "activo": true,
                    "visible": true
                }, {
                    "id": "TxtFecha",
                    "nombre": "Datatable.FechaReporte",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "LblCantidad",
                    "nombre": "Cantidad Reportada Etapa:".concat(etapatarea),
                    "activo": true,
                    "visible": true
                }, {
                    "id": "TxtCantidad",
                    "nombre": "Datatable.Cantidad",
                    "activo": tareaterminada === true || etapatarea==='Inicio' ? false : true,
                    "visible": true
                }, {
                    "id": "foto",
                    "nombre": "Datatable.foto",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "CmdCapturar",
                    "nombre": "",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "CmdBuscar",
                    "nombre": "",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "CmdBorrar",
                    "nombre": "",
                    "activo": false,
                    "visible": true
                }, {
                    "id": "TxtObservacion",
                    "nombre": "Datatable.ObservacionCapataz",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                }, {
                    "id": "CmdTerminar",
                    "nombre": "Terminar",
                    "activo": tareaterminada === false ? true : false,
                    "visible": true
                });
                if (etapatarea==="Inicio" && tareaterminada){
                    estadoEjecucionTarea.activo=true;
                    //estadoEjecucionTarea.visible=true;
                    estadoEjecucionTarea.visible=false;
                }
                
                let itemDifEtapa = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporteFinal.toLowerCase() && x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea !== etapatarea)
                if (itemDifEtapa !== undefined && itemDifEtapa != null && itemDifEtapa.length > 0) {
                    Object.keys(itemDifEtapa).forEach((item) => {
                        console.log(item, ':', itemDifEtapa[item]);
                        const { fechaconcepto, cantidadreportada, data64file, observacion, tareaterminada ,textoprogramacion, idunidadmedida} = itemDifEtapa[item];
                        response.datatable.push({
                            "idreporte": idreporteFinal,
                            "idtarea": idtarea,
                            "rol": "Offline",
                            "fechareporte": new Date(new Date(fechaconcepto).toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                            "etapatarea": etapatarea,
                            "cantidad": cantidadreportada,
                            "idunidadmedida": idunidadmedida!==undefined && idunidadmedida!==null ? idunidadmedida:"",
                            "foto": data64file,
                            "observacioncapataz": observacion,
                            "idSupervisor": null,
                            "conceptosupervisor": "No confirmado",
                            "observacionsupervisor": null,
                            "idingeniero": null,
                            "conceptoingeniero": "No confirmado",
                            "observacioningeniero": null,
                            "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                            "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                            "tareaterminada": tareaterminada
                        });
                    });
                }
                //}
            } else {
                response.listadoobjetosestado.push({
                    "id": "CmdRecursos",
                    "nombre": "Personal/Equipos",
                    "activo": etapatarea === "Inicio" ? false : true,
                    "visible": true
                }, {
                    "id": "CmdVariables",
                    "nombre": "Variables",
                    "activo": true,
                    "visible": true
                }, {
                    "id": "LblTituloPantalla",
                    "nombre": "Reporte OFFLINE Etapa ".concat(etapatarea),
                    "activo": true,
                    "visible": true
                }, {
                    "id": "TxtFecha",
                    "nombre": "Datatable.FechaReporte",
                    "activo": true,
                    "visible": true
                }, {
                    "id": "LblCantidad",
                    "nombre": "Cantidad Reportada Etapa:".concat(etapatarea),
                    "activo": true,
                    "visible": true
                }, {
                    "id": "TxtCantidad",
                    "nombre": "Datatable.Cantidad",
                    "activo": etapatarea==='Inicio' ? false : true,
                    "visible": true
                }, {
                    "id": "foto",
                    "nombre": "Datatable.foto",
                    "activo": true,
                    "visible": true
                }, {
                    "id": "CmdCapturar",
                    "nombre": "",
                    "activo": true,
                    "visible": true
                }, {
                    "id": "CmdBuscar",
                    "nombre": "",
                    "activo": true,
                    "visible": true
                }, {
                    "id": "CmdBorrar",
                    "nombre": "",
                    "activo": false,
                    "visible": false
                }, {
                    "id": "TxtObservacion",
                    "nombre": "Datatable.ObservacionCapataz",
                    "activo": true,
                    "visible": true
                }, {
                    "id": "CmdTerminar",
                    "nombre": "Terminar",
                    "activo": true,
                    "visible": true
                });
            }
        } else {
            response.listadoobjetosestado.push({
                "id": "CmdRecursos",
                "nombre": "Personal/Equipos",
                "activo": etapatarea === "Inicio" ? false : true,
                "visible": true
            }, {
                "id": "CmdVariables",
                "nombre": "Variables",
                "activo": true,
                "visible": true
            }, {
                "id": "LblTituloPantalla",
                "nombre": "Reporte OFFLINE Etapa ".concat(etapatarea),
                "activo": true,
                "visible": true
            }, {
                "id": "TxtFecha",
                "nombre": "Datatable.FechaReporte",
                "activo": true,
                "visible": true
            }, {
                "id": "LblCantidad",
                "nombre": "Cantidad Reportada Etapa:".concat(etapatarea),
                "activo": true,
                "visible": true
            }, {
                "id": "TxtCantidad",
                "nombre": "Datatable.Cantidad",
                "activo": etapatarea==='Inicio' ? false : true,
                "visible": true
            }, {
                "id": "foto",
                "nombre": "Datatable.foto",
                "activo": true,
                "visible": true
            }, {
                "id": "CmdCapturar",
                "nombre": "",
                "activo": true,
                "visible": true
            }, {
                "id": "CmdBuscar",
                "nombre": "",
                "activo": true,
                "visible": true
            }, {
                "id": "CmdBorrar",
                "nombre": "",
                "activo": false,
                "visible": false
            }, {
                "id": "TxtObservacion",
                "nombre": "Datatable.ObservacionCapataz",
                "activo": true,
                "visible": true
            }, {
                "id": "CmdTerminar",
                "nombre": "Terminar",
                "activo": true,
                "visible": true
            });
        }

        let valEjecEtapa = response.datatable.filter(x => x.idreporte.toLowerCase() === idreporteFinal.toLowerCase() && x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea === etapatarea);
        if (valEjecEtapa === undefined || valEjecEtapa === null || valEjecEtapa.length <= 0) {
            response.datatable.push({
                "idreporte": idreporteFinal,
                "idtarea": idtarea,
                "rol": "Offline",
                "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                "etapatarea": etapatarea,
                "cantidad": 0,
                "idunidadmedida": "Offline",
                "foto": foto!==undefined?foto:null,
                "observacioncapataz": null,
                "idSupervisor": null,
                "conceptosupervisor": "No confirmado",
                "observacionsupervisor": null,
                "idingeniero": null,
                "conceptoingeniero": "No confirmado",
                "observacioningeniero": null,
                "descripciontarea": descripcion!==undefined && descripcion!==null? 
                etapatarea==="Ejecución" ? descripcion.replace('Inicio',etapatarea) : descripcion : "Pendiente la descripcion",
                "textoprogramacion": "Programación no disponible (Nuevo reporte Offline)",
                "tareaterminada": false,
                "sincronizado":false,
            });
        }
        response.listadoobjetosestado.push({...estadoEjecucionTarea});        
        resolve(response);
    });
    return await promesa;
}

export const GenObjetoEjecucionEtapaTarea = async(idtarea, idreporte) => {
    const idreporteFinal= idreporte===' ' ? 'rptOffline'.concat((Math.random()  * 22).toString(36).substring(2)) : idreporte;
    let promesa = new Promise(function(resolve, reject) {
        let actividadesArbol = JSON.parse(window.localStorage.getItem('actividades'));
        let ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));
        if (actividadesArbol !== null) {
            let itemActividad = actividadesArbol.datatable.filter(x => x.idreporte.toLowerCase() === idreporteFinal.toLowerCase() && x.idtarea.toLowerCase() === idtarea.toLowerCase());
            const { descripcion, etapatarea, foto, textoprogramacion } = itemActividad[0];

            let objOffline = {
                "datatable": [{
                        "idreporte": idreporteFinal,
                        "idtarea": idtarea,
                        "rol": "Offline",
                        "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                        "etapatarea": "Inicio",
                        "cantidad": 0,
                        "idunidadmedida": "Offline",
                        "foto":  foto!==undefined?foto:null,
                        "observacioncapataz": null,
                        "idSupervisor": null,
                        "conceptosupervisor": "No confirmado",
                        "observacionsupervisor": null,
                        "idingeniero": null,
                        "conceptoingeniero": "No confirmado",
                        "observacioningeniero": null,
                        "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                        "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                        "tareaterminada": false
                    }, {
                        "idreporte": idreporteFinal,
                        "idtarea": idtarea,
                        //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                        "rol": "Offline",
                        "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                        "etapatarea": "Fin",
                        "cantidad": 0,
                        "idunidadmedida": "Offline",
                        "foto":  foto!==undefined?foto:null,
                        "observacioncapataz": null,
                        "idSupervisor": null,
                        "conceptosupervisor": "No confirmado",
                        "observacionsupervisor": null,
                        "idingeniero": null,
                        "conceptoingeniero": "No confirmado",
                        "observacioningeniero": null,
                        "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                        "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                        "tareaterminada": false
                    },
                    {
                        "idreporte": idreporteFinal,
                        "idtarea": idtarea,
                        //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                        "rol": "Offline",
                        "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                        "etapatarea": "Ejecución",
                        "cantidad": 0,
                        "idunidadmedida": "Offline",
                        "foto":  foto!==undefined?foto:null,
                        "observacioncapataz": null,
                        "idSupervisor": null,
                        "conceptosupervisor": "No confirmado",
                        "observacionsupervisor": null,
                        "idingeniero": null,
                        "conceptoingeniero": "No confirmado",
                        "observacioningeniero": null,
                        "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                        "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                        "tareaterminada": false
                    },
                    {
                        "idreporte": idreporteFinal,
                        "idtarea": idtarea,
                        //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                        "rol": "Offline",
                        "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                        "etapatarea": "Relevante",
                        "cantidad": 0,
                        "idunidadmedida": "Offline",
                        "foto": foto!==undefined?foto:null,
                        "observacioncapataz": null,
                        "idSupervisor": null,
                        "conceptosupervisor": "No confirmado",
                        "observacionsupervisor": null,
                        "idingeniero": null,
                        "conceptoingeniero": "No confirmado",
                        "observacioningeniero": null,
                        "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                        "textoprogramacion":textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                        "tareaterminada": false
                    }
                ],
                "listadoobjetosestado": [{
                        "id": "CmdRecursos",
                        "nombre": "Personal/Equipos",
                        "activo": etapatarea === "Inicio" ? false : true,
                        "visible": true
                    },
                    {
                        "id": "CmdVariables",
                        "nombre": "Variables",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "CmdCerrar",
                        "nombre": "Cerrar",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "LblTituloPantalla",
                        "nombre": "Reporte OFFLINE Etapa " + etapatarea,
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "LblDescripcion",
                        "nombre": "Datatable.DescripcionTarea",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "LblProgramacion",
                        "nombre": "Datatable.TextoProgramacion",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "LblFecha",
                        "nombre": "Fecha Inicio",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "TxtFecha",
                        "nombre": "Datatable.FechaReporte",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "LblCantidad",
                        "nombre": "Cantidad Reportada Etapa:" + etapatarea,
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "TxtCantidad",
                        "nombre": "Datatable.Cantidad",
                        "activo":  etapatarea==='Inicio' ? false : true,
                        "visible": true
                    },
                    {
                        "id": "LblUnidadMedida",
                        "nombre": "Datatable.IdUnidadMedida",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "foto",
                        "nombre": "Datatable.foto",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "CmdCapturar",
                        "nombre": "",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "CmdBuscar",
                        "nombre": "",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "CmdBorrar",
                        "nombre": "",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdInicio",
                        "nombre": "Inicio",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdEjecucion",
                        "nombre": "Ejecución",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdFin",
                        "nombre": "Fin",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdRelevante",
                        "nombre": "Relevante",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdAmarillado",
                        "nombre": "Amarillado",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "TxtObservacion",
                        "nombre": "Datatable.ObservacionCapataz",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "TxtObsConcepto",
                        "nombre": "Datatable.ObservacionSupervisor",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdTerminar",
                        "nombre": "Terminar",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "CmdConfirmar",
                        "nombre": "Confirmar",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdRechazar",
                        "nombre": "Rechazar",
                        "activo": false,
                        "visible": false
                    },
                    {
                        "id": "CmdAprobar",
                        "nombre": "Aprobar",
                        "activo": false,
                        "visible": false
                    }
                ],
                "etapatarea": etapatarea
            };
            if (ejecOff !== null) {
                let etapaInicio = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporteFinal.toLowerCase() && x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea === "Inicio");
                if (etapaInicio !== null && etapaInicio.length > 0) {
                    const { cantidadreportada, data64file, fechaconcepto, observacion,textoprogramacion, idunidadmedida } = etapaInicio[0];
                    objOffline = {
                        "datatable": [{
                                "idreporte": idreporteFinal,
                                "idtarea": idtarea,
                                //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                                "rol": "Offline",
                                //"fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                                "fechareporte": fechaconcepto === undefined ? new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0] : fechaconcepto,
                                "etapatarea": etapatarea,
                                "cantidad": cantidadreportada === undefined ? 0 : cantidadreportada,
                                "idunidadmedida": idunidadmedida!==undefined && idunidadmedida!==null ? idunidadmedida:"",
                                "foto": data64file === undefined ? null : data64file,
                                "observacioncapataz": observacion === undefined ? null : observacion,
                                "idSupervisor": null,
                                "conceptosupervisor": "No confirmado",
                                "observacionsupervisor": null,
                                "idingeniero": null,
                                "conceptoingeniero": "No confirmado",
                                "observacioningeniero": null,
                                "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                                "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                                "tareaterminada": true
                            }, {
                                "idreporte": idreporteFinal,
                                "idtarea": idtarea,
                                //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                                "rol": "Offline",
                                "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                                "etapatarea": "Fin",
                                "cantidad": 0,
                                "idunidadmedida": idunidadmedida!==undefined && idunidadmedida!==null ? idunidadmedida:"",
                                "foto": null,
                                "observacioncapataz": null,
                                "idSupervisor": null,
                                "conceptosupervisor": "No confirmado",
                                "observacionsupervisor": null,
                                "idingeniero": null,
                                "conceptoingeniero": "No confirmado",
                                "observacioningeniero": null,
                                "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                                "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                                "tareaterminada": false
                            },
                            {
                                "idreporte": idreporteFinal,
                                "idtarea": idtarea,
                                //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                                "rol": "Offline",
                                "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                                "etapatarea": "Ejecución",
                                "cantidad": 0,
                                "idunidadmedida": idunidadmedida!==undefined && idunidadmedida!==null ? idunidadmedida:"",
                                "foto": null,
                                "observacioncapataz": null,
                                "idSupervisor": null,
                                "conceptosupervisor": "No confirmado",
                                "observacionsupervisor": null,
                                "idingeniero": null,
                                "conceptoingeniero": "No confirmado",
                                "observacioningeniero": null,
                                "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                                "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                                "tareaterminada": false
                            },
                            {
                                "idreporte": idreporteFinal,
                                "idtarea": idtarea,
                                //"idpersona": "CBF14730-6CE3-4181-B8DC-CD06AD440E22",
                                "rol": "Offline",
                                "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                                "etapatarea": "Relevante",
                                "cantidad": 0,
                                "idunidadmedida": idunidadmedida!==undefined && idunidadmedida!==null ? idunidadmedida:"",
                                "foto": null,
                                "observacioncapataz": null,
                                "idSupervisor": null,
                                "conceptosupervisor": "No confirmado",
                                "observacionsupervisor": null,
                                "idingeniero": null,
                                "conceptoingeniero": "No confirmado",
                                "observacioningeniero": null,
                                "descripciontarea": descripcion!==undefined && descripcion!==null? descripcion : "Pendiente la descripcion",
                                "textoprogramacion": textoprogramacion!==undefined && textoprogramacion!==null ? textoprogramacion : "Offline",
                                "tareaterminada": false
                            }
                        ],
                        "listadoobjetosestado": [{
                                "id": "CmdRecursos",
                                "nombre": "Personal/Equipos",
                                "activo": etapatarea === "Inicio" ? false : true,
                                "visible": true
                            },
                            {
                                "id": "CmdVariables",
                                "nombre": "Variables",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "CmdCerrar",
                                "nombre": "Cerrar",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "LblTituloPantalla",
                                "nombre": "Reporte OFFLINE Etapa ".concat(etapatarea),
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "LblDescripcion",
                                "nombre": "Datatable.DescripcionTarea",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "LblProgramacion",
                                "nombre": "Datatable.TextoProgramacion",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "LblFecha",
                                "nombre": "Fecha Inicio",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "TxtFecha",
                                "nombre": "Datatable.FechaReporte",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "LblCantidad",
                                "nombre": "Cantidad Reportada Etapa:".concat(etapatarea),
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "TxtCantidad",
                                "nombre": "Datatable.Cantidad",
                                "activo": etapatarea==='Inicio' ? false : true,
                                "visible": true
                            },
                            {
                                "id": "LblUnidadMedida",
                                "nombre": "Datatable.IdUnidadMedida",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "foto",
                                "nombre": "Datatable.foto",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "CmdCapturar",
                                "nombre": "",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "CmdBuscar",
                                "nombre": "",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "CmdBorrar",
                                "nombre": "",
                                "activo": false,
                                "visible": false
                            },
                            {
                                "id": "CmdInicio",
                                "nombre": "Inicio",
                                "activo": etapatarea === "Inicio" ? true : false,
                                "visible": false
                            },
                            {
                                "id": "CmdEjecucion",
                                "nombre": "Ejecución",
                                "activo": etapatarea === "Ejecución" ? true : false,
                                "visible": false
                            },
                            {
                                "id": "CmdFin",
                                "nombre": "Fin",
                                "activo": etapatarea === "Fin" || etapatarea === "Inicio" ? true : false,
                                "visible": false
                            },
                            {
                                "id": "CmdRelevante",
                                "nombre": "Relevante",
                                "activo": etapatarea === "Fin" || etapatarea === "Inicio" ? true : false,
                                "visible": false
                            },
                            {
                                "id": "CmdAmarillado",
                                "nombre": "Amarillado",
                                "activo": false,
                                "visible": false
                            },
                            {
                                "id": "TxtObservacion",
                                "nombre": "Datatable.ObservacionCapataz",
                                "activo": true,
                                "visible": true
                            },
                            {
                                "id": "TxtObsConcepto",
                                "nombre": "Datatable.ObservacionSupervisor",
                                "activo": false,
                                "visible": false
                            },
                            {
                                "id": "CmdTerminar",
                                "nombre": "Terminar",
                                "activo": true,
                                "visible": false
                            },
                            {
                                "id": "CmdConfirmar",
                                "nombre": "Confirmar",
                                "activo": false,
                                "visible": false
                            },
                            {
                                "id": "CmdRechazar",
                                "nombre": "Rechazar",
                                "activo": false,
                                "visible": false
                            },
                            {
                                "id": "CmdAprobar",
                                "nombre": "Aprobar",
                                "activo": false,
                                "visible": false
                            }
                        ],
                        "etapatarea": etapatarea
                    }
                }
            }
            resolve(objOffline);
        }
    });
    return await promesa;
}

export const GuardaEjecucionEtapaTarea = (terminaEtapaRequest, actualizaRecsel=false) => {
    const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'));
    terminaEtapaRequest.sincronizado=false;
    terminaEtapaRequest.recursos=[];
    if (ejecOff !== null) {
        let existe = false;

        //validar que la ejecucuón no exista, si existe se reemplaza
        let ejecFinalOff = ejecOff.map(dato => {
            if (dato.idreporte.toLowerCase() === terminaEtapaRequest.idreporte.toLowerCase() &&
                dato.idtarea.toLowerCase() === terminaEtapaRequest.idtarea.toLowerCase() &&
                dato.etapatarea === terminaEtapaRequest.etapatarea) {
                dato = {...terminaEtapaRequest,usuario};                
                existe = true;
            }
            return dato;
        });
        if (existe === false) {
            ejecFinalOff.push({...terminaEtapaRequest,usuario});
        }
        window.localStorage.setItem('ejecucionOffline', JSON.stringify(ejecFinalOff));
    } else {
        window.localStorage.setItem('ejecucionOffline', JSON.stringify([{...terminaEtapaRequest,usuario}]));
    }
    if (actualizaRecsel){
        let tareaSeleccionada = JSON.parse(window.localStorage.getItem('recSel'));
        let apiActividades = JSON.parse(window.localStorage.getItem('actividades'))
        if (tareaSeleccionada!==undefined && tareaSeleccionada!==null && 
            apiActividades!==undefined && apiActividades!==null && apiActividades?.datatable !==null){                       
            let dataTable=apiActividades.datatable;//.filter(x=> x.idreporte !== tareaSeleccionada[0].idreporte); 
            /*if (dataTable===undefined || dataTable===null){
                dataTable=[];
            }*/
            tareaSeleccionada[0].id=terminaEtapaRequest.idreporte;
            tareaSeleccionada[0].etapatarea=terminaEtapaRequest.etapatarea;
            tareaSeleccionada[0].idreporte=terminaEtapaRequest.idreporte;
            tareaSeleccionada[0].descripcion=terminaEtapaRequest.descripciontarea;
            dataTable.push(tareaSeleccionada[0]);
            apiActividades.datatable=[...dataTable];
            window.localStorage.setItem('actividades', JSON.stringify(apiActividades))               
            window.localStorage.setItem('recSel', JSON.stringify(tareaSeleccionada));            
        }  
    }
}

export const EliminaEjecucionEtapaTarea = async(idtarea, idreporte, etapatarea) => {
    let ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));
    if (ejecOff!==undefined && ejecOff !==null){
        ejecOff.forEach(function(item, index, object) {
            if (item.idtarea.toLowerCase() === idtarea.toLowerCase() && item.idreporte.toLowerCase() === idreporte.toLowerCase() && item.etapatarea === etapatarea) {
                object.splice(index, 1);
            }
        });
        window.localStorage.setItem('ejecucionOffline', JSON.stringify(ejecOff));
    }        
    let ejecVarOff = JSON.parse(window.localStorage.getItem('variablesOffline'));
    if (ejecVarOff!==undefined && ejecVarOff !==null){
        ejecVarOff.forEach(function(item, index, object) {
            if (item.idtarea.toLowerCase() === idtarea.toLowerCase() && item.idreporte.toLowerCase() === idreporte.toLowerCase() && item.etapatarea === etapatarea) {
                object.splice(index, 1);
            }
        });
        window.localStorage.setItem('variablesOffline', JSON.stringify(ejecVarOff));
    }   
    EliminaRecursosTarea(idtarea,idreporte);
}

export const EliminaRecursosTarea = (idtarea, idreporte, idrecurso=null) => {
    let ejecOff = JSON.parse(window.localStorage.getItem('recursosOffline'));
    if (ejecOff!==undefined && ejecOff !==null && ejecOff.length>0){
        ejecOff.forEach(function(item, index, object) {
            if (idrecurso===null){
                if (item.Idtarea.toLowerCase() === idtarea.toLowerCase() && 
                item.Idreporte.toLowerCase() === idreporte.toLowerCase() ) {
                object.splice(index, 1);
            }
            }else{            
                if (item.Idtarea.toLowerCase() === idtarea.toLowerCase() && 
                    item.Idreporte.toLowerCase() === idreporte.toLowerCase() && 
                    item.Idrecurso.toLowerCase() === idrecurso.toLowerCase()) {
                    object.splice(index, 1);
                }
            }   
        });
        window.localStorage.setItem('recursosOffline', JSON.stringify([...ejecOff]));
    }            
}


export const CargarObjVariablesEjeEtapaTarea = async(idtarea, idreporte, etapatarea) => {
    // hace falta acá tomar del árbol las últimas variables que se reportaron

    // extraer el rpoerte de variables del local storage
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario')).perfilUsuario.nombreCompleto;

    let promesa = new Promise(function(resolve, reject) {
        
    let ejecOff = JSON.parse(window.localStorage.getItem('variablesOffline'));
    const offLine = JSON.parse(window.localStorage.getItem('offline'));
    const tarSel = JSON.parse(window.localStorage.getItem('recSel'));
    const date =new Date();
    const {descripcion,variables} = tarSel[0];
    const { area,calidad,documentos,equipos,herramientas,hse,materiales,personas, observacion} = variables!== undefined && variables!==null ? variables : {area:true,calidad:true,documentos:true,equipos:true,herramientas:true,hse:true,materiales:true,personas:true, observacion:null} ;
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    //console.log(date.toLocaleDateString('es-CO', options));


    let response = {
        "id": idreporte,
        "idtarea": idtarea,
        "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
        "idactividad": null,
        "tituloPantalla": offLine.modeOffline ? "Reporte OffLine Cumplimiento Variables Etapa " + etapatarea : "Reporte Cumplimiento Variables Etapa " + etapatarea,
        "lblobjeto3": "Hoy " + date.toLocaleDateString('es-CO', options) + " yo " + usuario + " confirmo que las siguientes variables están cumplidas en sú totalidad:",
        "lblobjeto3no": "Hoy "+ date.toLocaleDateString('es-CO', options) + " yo " + usuario + " confirmo que la tarea "+ descripcion + " no puede ser ejecutada por falta de:",
        "lblobjeto5": "Por tanto; la tarea " + descripcion + ", puede ser ejecutada.",
        "lblobjeto5no": "Por las siguientes razones:",
        "lblobjeto7": "Siguiente",
        "lblobjeto8": "3",
        "personas": personas!== undefined && personas!==null ? personas : true,
        "hse": hse === undefined && hse!==null ?  hse : true,
        "documentos":documentos === undefined && documentos!==null ? documentos : true,
        "calidad":calidad === undefined && calidad!==null ?  calidad : true,
        "area": area!== undefined && area!==null ? area : true,
        "materiales": materiales === undefined && materiales!==null ? materiales : true,
        "herramientas":herramientas  === undefined && herramientas!==null ? herramientas : true,
        "equipos": equipos === undefined && equipos!==null ? equipos : true,
        "observacion": observacion === undefined && observacion!==null ? observacion : null,
        "listadoObjetosEstado": [{
                "id": "cmdguardar",
                "nombre": "Guardar",
                "activo": true,
                "visible": true
            },
            {
                "id": "cmdeliminar",
                "nombre": "Eliminar",
                "activo": true,
                "visible": true
            },
            {
                "id": "cmdcerrar",
                "nombre": "Cerrar",
                "activo": true,
                "visible": true
            },
            {
                "id": "txtobservacion",
                "nombre": null,
                "activo": true,
                "visible": true
            }
        ],
        
    };

    if (ejecOff !== null) {
        let itemEtapa = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporte.toLowerCase() && 
                        x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea === etapatarea)
        if (itemEtapa !== undefined && itemEtapa != null && itemEtapa.length > 0) {
            console.log(itemEtapa[itemEtapa.length-1]);
            const { personas, hse, documentos, calidad, area, materiales, herramientas, equipos, observacion } = itemEtapa[itemEtapa.length-1];
            response.personas = personas;
            response.hse = hse;
            response.documentos = documentos;
            response.calidad = calidad;
            response.area = area;
            response.materiales = materiales;
            response.herramientas = herramientas;
            response.equipos = equipos;
            response.observacion = observacion;
        }
    }
    resolve(response);
    });
    return await promesa;
}

export const GuardaVariablesEtapaTarea = (variablesEjecucionDto) => {
    let ejecOff = JSON.parse(window.localStorage.getItem('variablesOffline'));
    if (ejecOff !== null) {
        let existe = false;
        //validar que la ejecucuón no exista, si existe se reemplaza      
        const objEjecOff = ejecOff.map(dato => {
            if (dato.idreporte.toLowerCase() === variablesEjecucionDto.idreporte.toLowerCase() &&
                dato.idtarea.toLowerCase() === variablesEjecucionDto.idtarea.toLowerCase() &&
                dato.etapatarea === variablesEjecucionDto.etapatarea) {
                dato = variablesEjecucionDto;
                existe = true;
            }
            return dato;
        });
        if (existe === false) {
            objEjecOff.push(variablesEjecucionDto);
        }
        window.localStorage.setItem('variablesOffline', JSON.stringify(objEjecOff));
    } else {
        window.localStorage.setItem('variablesOffline', JSON.stringify([variablesEjecucionDto]));
    }
}


export const VariablesEjeEtapaTarea = (idtarea, idreporte, etapatarea) => {
    //const usuario = JSON.parse(window.localStorage.getItem('logearUsuario')).perfilUsuario.nombreCompleto;

    let ejecOff = JSON.parse(window.localStorage.getItem('variablesOffline'));

    let response = {
        "fechareporte": new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
        "personas": false,
        "hse": false,
        "documentos": false,
        "calidad": false,
        "area": false,
        "materiales": false,
        "herramientas": false,
        "equipos": false,
        "observacion": null
    };

    if (ejecOff != null) {
        let itemEtapa = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporte.toLowerCase() && x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea === etapatarea)
        if (itemEtapa !== undefined && itemEtapa !== null && itemEtapa.length > 0) {
            console.log(itemEtapa[itemEtapa.length-1]);
            const { personas, hse, documentos, calidad, area, materiales, herramientas, equipos, observacion } = itemEtapa[itemEtapa.length-1];
            response.personas = personas;
            response.hse = hse;
            response.documentos = documentos;
            response.calidad = calidad;
            response.area = area;
            response.materiales = materiales;
            response.herramientas = herramientas;
            response.equipos = equipos;
            response.observacion = observacion;
        }
    }
    return response;
}

export const CargarObjRecursosEjeEtapaTarea = async(idtarea, idreporte) => {
    // extraer el rpoerte de variables del local storage    
    let promesa = new Promise(function(resolve, reject) {
        
        const usuario = JSON.parse(window.localStorage.getItem('logearUsuario')).perfilUsuario.nombreCompleto;
        const tareaSereccionada = JSON.parse(window.localStorage.getItem('recSel'));
        const { etapatarea } = tareaSereccionada[0];        
        const configFecha = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12:"false"};        
                        
        let response =
            {
                "id": '00000000-0000-0000-0000-000000000000',//idreporte,
                "idtarea":idtarea,
                "etapatarea": etapatarea,
                "etapaterminada": false,
                "fechafinal":new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                "fechainicial":new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
                "tituloPantalla": "Reporte Recursos " + etapatarea,
                "lblobjeto3": "Hoy " + Date().toLocaleString("es-ES", configFecha) + usuario + " confirmo que usé los siguientes recursos para ejecutar la tarea: ",
                "listadoObjetosEstado": [
                    {
                        "id": "cmdnuevo",
                        "nombre": "Nuevo",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "cmdmodificar",
                        "nombre": "Modificar",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "cmdeliminar",
                        "nombre": "Eliminar",
                        "activo": true,
                        "visible": true
                    },
                    {
                        "id": "cmdcerrar",
                        "nombre": "Cerrar",
                        "activo": true,
                        "visible": true
                    }
                ],
                "datatable": [                   
                ]
        };
        
        const recursosOffline = CargarRecursosTarea(idtarea,idreporte);
        recursosOffline.forEach((item)=>{
            response.datatable.push(item);                                     
        })
        console.log(recursosOffline);
        resolve(response);
    });
    return await promesa;
}

export const CargarRecursosTarea = (idtarea, idreporte) => {
    // extraer el rpoerte de variables del local storage    
                      
    const maestrasOff = JSON.parse(window.localStorage.getItem('maestrasUsuario'))
    if (maestrasOff!==undefined){                    
        const {MaestrasUsuario} = maestrasOff;
        let response=[];        
        const recursosOff = JSON.parse(window.localStorage.getItem('recursosOffline'));
        if (recursosOff!== undefined && recursosOff !== null && recursosOff.length>0) {
            const recursosReporte = recursosOff.filter(x => x.Idreporte.toLowerCase() === idreporte.toLowerCase() && x.Idtarea.toLowerCase() === idtarea.toLowerCase())            
            if (recursosReporte !== undefined && recursosReporte !== null && recursosReporte.length > 0 && MaestrasUsuario!==undefined && MaestrasUsuario!==null) {
                recursosReporte.forEach((item)=>{
                    const ItemMaestra = MaestrasUsuario.find( itemMaestra => itemMaestra.maestra==='Recursos' && itemMaestra.id.toLowerCase()===item.Idrecurso.toLowerCase());
                    if (ItemMaestra!==undefined && ItemMaestra!==null){
                        response.push(                            
                            {
                            id: item.Id,
                            idrecurso: ItemMaestra.id,
                            descripcion: ItemMaestra.descripcion,
                            fechainicial: item.fechainicial,
                            fechaFinal: item.fechafinal,
                            cantidad: item.cantidad,
                            tiporecurso : ItemMaestra?.tiporecurso,
                            programacion:false,
                            actividad:false,
                        });
                    }                                        
                });
            }
        }
        return(response);
    }else{
        return([]);
    }

}

export const GuardaRecursosEtapaTarea = (recursosEjecucionDto) => {
    let recursosOff = JSON.parse(window.localStorage.getItem('recursosOffline'));
    if (recursosEjecucionDto.Id===''){
        recursosEjecucionDto.Id=generarIdRecurso();
    }
    if (recursosOff!== undefined && recursosOff !== null) {
        let existe = false;
        //validar que la ejecucuón no exista, si existe se reemplaza      
        const objEjecOff = recursosOff.map(dato => {
            if (dato.Idreporte === recursosEjecucionDto.Idreporte &&
                dato.Idtarea === recursosEjecucionDto.Idtarea &&                
                dato.Idrecurso === recursosEjecucionDto.Idrecurso) {
                dato = recursosEjecucionDto;
                existe = true;
            }
            return dato;
        });
        if (existe === false) {
            objEjecOff.push(recursosEjecucionDto);
        }
        window.localStorage.setItem('recursosOffline', JSON.stringify([...objEjecOff]));
    } else {
        window.localStorage.setItem('recursosOffline', JSON.stringify([recursosEjecucionDto]));
    }
}


export const GeneraRespuestaNuevaActividadCampo = (idActividad) => {
    // extraer el rpoerte de variables del local storage
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario')).perfilUsuario.nombreCompleto;
    const maestrasOff = [...JSON.parse(window.localStorage.getItem('actividades')).usuariosActividadCampo];
    const idProyecto = JSON.parse(window.localStorage.getItem('recSel'))[0].idProyecto;
    if (maestrasOff!==undefined && usuario!==undefined && idProyecto!==undefined && idProyecto!==null){                            
        
        const comboSupervisor = maestrasOff.filter( itemMaestra => itemMaestra.maestra===idProyecto & itemMaestra.supervisor===true);
        const comboCapataz = maestrasOff.filter( itemMaestra => itemMaestra.maestra===idProyecto & itemMaestra.descripcion===usuario);
        const comboIngeniero = maestrasOff.filter( itemMaestra => itemMaestra.maestra===idProyecto & itemMaestra.tiporecurso==='Ingeniero');
        const comboUnidadMedida = JSON.parse(window.localStorage.getItem('maestrasUsuario')).MaestrasUsuario.filter(item=> item.maestra==='UnidadMedida');
        
        
        const date =new Date();
                
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        //console.log(date.toLocaleDateString('es-CO', options));
        let datos = {
            "titulopantalla": "Actividad de Campo - No Iniciada",
            "lblobjeto3": "Detalle de la Actividad",
            "idactividad": idActividad,
            "idactividadpmo": generarIdActividadCampo(),
            "descripcion": null,
            "lblobjeto5": "Inicio Planeado: " + date.toLocaleDateString('es-CO', options),
            "lblobjeto6": "Unidad Medida",
            "idunidadmedida": null,
            "lblobjeto7": "Cantidad Programada",
            "cantidad": 0.00,
            "lblobjeto8": "Inicio Programado:",
            "fechainiprog": new Date(date.toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
            "lblobjeto9": "Fin Programado",
            "fechafinprog": new Date(date.toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
            "lblobjeto12": "Atras",
            "lblobjeto10": "Siguiente",
            "lblobjeto11": "1",
            "comboactividad": [                
                {
                    "nombre": "idactividad",
                    "id": null,
                    "descripcion": "Seleccionar",
                    "activo": true
                }
            ],
            "comboUnidadMedida": comboUnidadMedida!== undefined && comboUnidadMedida!==null ? [{id:null,descripcion:'Seleccionar'},...comboUnidadMedida] : [{id:null,descripcion:'Seleccionar'}],
            "comboCapataz": comboIngeniero!== undefined && comboIngeniero!==null ? [{id:null,descripcion:'Seleccionar'},...comboIngeniero] : [{id:null,descripcion:'Seleccionar'}],
            "comboSupervisor": comboSupervisor!== undefined && comboSupervisor!==null ? [{id:null,descripcion:'Seleccionar'},...comboSupervisor] : [{id:null,descripcion:'Seleccionar'}],
            "listadoObjetosEstado": [
                {
                    "id": "Cantidad",
                    "nombre": "Cantidad Obra",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "cmdcerrar",
                    "nombre": "Cerrar",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "descripcion",
                    "nombre": "Descripción",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "cmdeliminar",
                    "nombre": "Eliminar",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "fechafinprog",
                    "nombre": "Fin Programado",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "combocapataz",
                    "nombre": "IdCapataz",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "comboactividad",
                    "nombre": "IdPMO",
                    "activo": false,
                    "visible": false
                },
                {
                    "id": "combosupervisor",
                    "nombre": "IdSupervisor",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "fechainiprog",
                    "nombre": "Inicio Programado",
                    "activo": true,
                    "visible": true
                },
                {
                    "id": "idUnidadMedida",
                    "nombre": "Unidades Medidas",
                    "activo": false,
                    "visible": true
                }
            ],
            "idsupervisor": null,
            "idcapataz": comboCapataz !== undefined && comboCapataz !== null ? comboCapataz[0].id : null
        }        
        return datos;
    } else {
        return {errores:[{
            linea: 0,
            mensaje : 'No existen datos debe cerrar sesión e ingresar de nuevo al sistema.'
        }]};
    }
    /*if (ejecOff !== null) {
        let itemEtapa = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporte.toLowerCase() && 
                        x.idtarea.toLowerCase() === idtarea.toLowerCase() && x.etapatarea === etapatarea)
        if (itemEtapa !== undefined && itemEtapa != null && itemEtapa.length > 0) {
            console.log(itemEtapa[itemEtapa.length-1]);
            const { personas, hse, documentos, calidad, area, materiales, herramientas, equipos, observacion } = itemEtapa[itemEtapa.length-1];
            response.personas = personas;
            response.hse = hse;
            response.documentos = documentos;
            response.calidad = calidad;
            response.area = area;
            response.materiales = materiales;
            response.herramientas = herramientas;
            response.equipos = equipos;
            response.observacion = observacion;
        }
    }*/
   
}


const generarIdRecurso = () =>{
    return ('offline'.concat((Math.random()  * 22).toString(36).substring(7)));
}

const generarIdActividadCampo = () =>{
    return ('offline-ActCampo-'.concat((Math.random()  * 22).toString(36).substring(7)));
}