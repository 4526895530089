import React, { useEffect, useState } from 'react'
import { IconContext } from "react-icons"
import SweetAlert from 'react-bootstrap-sweetalert'
import { FcSupport, FcOldTimeCamera, FcSearch, FcFullTrash, FcInspection } from "react-icons/fc"
import { colores } from '../../componentes/Actividades/constantes'
import noImg from '../../imagenes/no-photo-available.png'
import { ModalCamera } from '../../componentes/Actividades/ModalCamera'
import { ConEjecutarActividad, GetConsultarEtapaTarea, TerminarEtapaTarea, ReiniciarEtapaTarea, ConceptoEjecucionEtapaTarea } from '../../componentes/ConsumirApiRest'
import { resizeBase64Img } from '../../componentes/cambiarImagen'
import Cargando from '../../componentes/Cargar'
import {GuardaEjecucionEtapaTarea, VariablesEjeEtapaTarea, CargarRecursosTarea } from '../../componentes/Ejecucion/ejecucionOffline'
import {Variables} from '../../paginas/unaActividad/Variables'
import { Recursos } from '../../paginas/unaActividad/Recursos'
import { fechaUTC } from '../../componentes/controlFechas'

const titulo="Variables"
const initState = {
    respuestaGetEjecutarActividad: {
        datatable: [],
        etapatarea: '',
        listadoobjetosestado: [{
            activo: false,
            id: '',
            nombre: '',
            visible: true
        }],
        variables:[{
            personas: true,
            hse: true,
            documentos: true,
            calidad: true,
            area: true,
            materiales: true,
            herramientas: true,
            equipos: true,
            observacion: null
        }]
    },
    form: {
        TxtFecha: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtCantidad: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtObservacion: { id: '', nombre: '', activo: false, visible: '', value: '' },
        TxtObsConcepto: { id: '', nombre: '', activo: false, visible: '', value: '' },
        foto: { id: '', nombre: '', activo: false, visible: '', value: '' },
    },

    openCamera: false,
}

export const EjecutarActividad = (props) => {

	const [cargando, setCargando] = useState(false)
    const [stateEjecutarActividad, setStateEjecutarActividad] = useState(initState)	
	const [showActividadCampo, setShowActividadCampo] = useState(false)
    const [showRecurso, setShowRecurso] = useState(false)
    const [idAbrirActividad, setIdAbrirActividad] = useState(null)   
    const [idReporte, setidReporte] = useState(null)    
    const [paginaVer, setpaginaVer] = useState(null)   
    const [etapaTarea, setetapaTarea] = useState(null)   
    const { form } = stateEjecutarActividad;
    const { TxtFecha, TxtCantidad, TxtObservacion, TxtObsConcepto } = form;    	
    const [openCamera, setOpenCamera] = useState(false);
    const [objRecursos, setobjRecursos] = useState(
                            {idreporte: '',
                            idtarea : '',
                            etapatarea: '',
                            fechaInicial:null,
                            fechaFinal:null
                            });
    const [apiActividadRecurso,setapiActividadRecurso] = useState(null);
    const [soloLectura, setSoloLectura] = useState(false);
    const onAbrirActividad =()=>setShowActividadCampo(true)
    const onAbrirRecurso =()=>setShowRecurso(true)
    const ValidReporte =(idreporte)=>setidReporte(idreporte)
    const ValidapaginaVer =(pagina)=>setpaginaVer(pagina)
    const ValidaetapaTarea =(etapa)=>setetapaTarea(etapa)
    const cerrar = () => {
        if (props.actualizarDatos!==undefined){
            props.setactualizarDatos(props.actualizarDatos===false ? true : false) 
        }
        else if (props.setactualizarDatosTareas!==undefined){
            props.setactualizarDatosTareas();
        }
        
        props.setShowEjecutar(false)
    }

    // obtiene el valor de los labels para cada elemento del dom
    const getLabel = (id) => {
        if (cargando!==undefined && cargando!==null && cargando===false){
            const { respuestaGetEjecutarActividad } = stateEjecutarActividad;
            const { listadoobjetosestado, datatable, etapatarea } = respuestaGetEjecutarActividad;
            if (id==='CmdTerminar' && etapatarea==='Inicio'){
                return ('Iniciar');
            } else{
                    if (datatable.length > 0) {
                        const data = datatable.filter(e => e.etapatarea === etapatarea)[0];
                        const dataById = listadoobjetosestado.filter(e => e.id.toLowerCase() === id.toLowerCase());
        
                        if (dataById.length > 0) {
                            if (data!==undefined && dataById[0].nombre.includes('Datatable')) {
                                const nombre = dataById[0].nombre.split('.')[1];
                                const nombreFromDataTable = data[nombre.toLocaleLowerCase()];
                                return (nombreFromDataTable);
                            } else {
                                return (dataById[0].nombre ? dataById[0].nombre : '');
                            }
        
                        }
                    }
            }      
            
        }
        return('');
    }

    // Renderiza labels y valores de la vista
    const renderizarLabelsValores = (response, idreporte) => {
        // renderizar valores form
        if (response!==undefined && response!==null){        
            const { listadoobjetosestado, datatable } = response;
            //const reportesEtapa = datatable.filter(e => e.etapatarea === etapatarea && e.foto !== null);
            const reportesEtapa = datatable.filter(e => e.idreporte.toLowerCase() === idreporte.toLowerCase());
            const data = reportesEtapa[reportesEtapa.length-1];
            const propForm = Object.keys(form);
            const valuesForm = {};
            let fotoReporte= noImg.replace('data:image/png;base64,','');
            propForm.forEach(prop => {
                const tempDato = listadoobjetosestado.filter(e => e.id === prop)[0];
                if (tempDato) {
                    if (tempDato.nombre.includes('Datatable')) {
                        const nombre = tempDato.nombre.split('.')[1];
                        const nombreFromDataTable = data[nombre.toLocaleLowerCase()];
                        tempDato.value = nombreFromDataTable;
                        if (tempDato.id==='foto'){
                            fotoReporte=nombreFromDataTable;
                        }
                    } else {
                        tempDato.value = tempDato.nombre ? tempDato.nombre : '';
                    }
                }
                valuesForm[prop] = tempDato;

            });
            
            handleForm({ name: 'foto', value: fotoReporte });            
            setStateEjecutarActividad({...stateEjecutarActividad, respuestaGetEjecutarActividad: response, form: valuesForm });
        }
    }

    const getVisibleActivoElement = (id, propiedad) => {
        const { respuestaGetEjecutarActividad } = stateEjecutarActividad;
        const { listadoobjetosestado } = respuestaGetEjecutarActividad;
        const dataById = listadoobjetosestado.filter(e => e.id === id);
        //desde la vista ViewTareas no es posible agregar o modificar recursos/variables
        if (props.origen==='Tareas' && propiedad==='visible' && (id==='CmdRecursos'|| id==='CmdVariables')){
            return false;
        }
        if (soloLectura && propiedad==='activo' && id!=='CmdCerrar'){
            return false;
        }
        if (dataById.length > 0) {
            return dataById[0][propiedad];
        } else {
            return true;
        }
    }

    const searchPhoto = (target) => {
        let img = target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            handleForm({ name: 'foto', value: reader.result })
        };
        reader.readAsDataURL(img);
    }

    const handleForm = (target) => {
        const { name, value } = target;
        const { form } = stateEjecutarActividad;
        setStateEjecutarActividad({...stateEjecutarActividad, form: {...form, [name]: {...form[name], value: value } } });
    }
    
    // Realiza lógica objetos 16 al 20
    const btnSelected = async(btnSlected) => {
        console.log(btnSlected)
        try {
			setCargando(true);
            let  idtarea, idreporte  = null;
            let apiActividades = JSON.parse(window.localStorage.getItem('actividades'))
            let tareaSeleccionada = JSON.parse(window.localStorage.getItem('recSel'));
            let reporteExisteOffline=false;
            let data = apiActividades.datatable.filter(e => e.etapatarea === btnSlected && e.idtarea === tareaSeleccionada[0].idtarea);
            //let data = stateEjecutarActividad.respuestaGetEjecutarActividad.datatable.filter(e => e.etapatarea === btnSlected)[0];
            if (data === undefined || data === null || data.length===0){
                data = stateEjecutarActividad.respuestaGetEjecutarActividad.datatable[0];
                idtarea=tareaSeleccionada[0].idtarea;                
                idreporte='rptOffline'.concat((Math.random()  * 22).toString(36).substring(2));
            }
            else{
                data=data[0];
                idtarea  = data.idtarea;
                idreporte = idReporte!==undefined && idReporte!== null ? idReporte : data.idreporte;
                reporteExisteOffline=true;
            }
            
            const response = await GetConsultarEtapaTarea(idtarea, idreporte, btnSlected); 
            const responseCode= response.respuestaCode !== undefined && response.respuestaCode !== null && response.respuestaCode ? response.respuestaCode : 0;
            ValidaetapaTarea(btnSlected);            
            if (idReporte.toUpperCase() !== response.idnuevoreporte.toUpperCase()){     
                if (idreporte.indexOf('ptOffline')>=0){                
                    const {descripcion, idunidadmedida,descripciontarea } = data;
                    const terminaEtapaRequest = {
                        idreporte: response.idnuevoreporte,
                        idtarea: idtarea,
                        idnotificacion: '',
                        etapatarea: btnSlected,
                        fechaconcepto: new Date(Date().toLocaleString('es-CO')),
                        cantidadreportada: parseFloat('0'),
                        observacion: null,
                        fechafoto: null,
                        filename: null,
                        data64file: null,
                        tareaterminada: false,
                        descripcion: descripcion,
                        sincronizado:false,
                        textoprogramacion: getLabel("LblProgramacion"),
                        idunidadmedida: idunidadmedida,
                        descripciontarea: descripciontarea !== undefined ? descripciontarea.replace('Inicio',btnSlected).replace('inicio',btnSlected) : descripcion
                    };
                    if (reporteExisteOffline===false && responseCode!==200){
                        GuardaEjecucionEtapaTarea(terminaEtapaRequest,true);
                    }
                    
                    cerrar();
                    return;
                                        
                    //props.setShowEjecutar(false);                                                                                                               	
                }
                ValidReporte(response.idnuevoreporte);
                renderizarLabelsValores(response,response.idnuevoreporte);
            }else{
                renderizarLabelsValores(response,idReporte);
            }

            setCargando(false);            
           
        } catch (error) {
			setCargando(false);
            console.log(error);
            props.setShowEjecutar(false);
        }
    }

    const btnEliminar = async(btnSlected) => {
            console.log(btnSlected)
            try {
                
                const data = stateEjecutarActividad.respuestaGetEjecutarActividad.datatable.filter(e => e.etapatarea === stateEjecutarActividad.respuestaGetEjecutarActividad.etapatarea)[0];
                const { idtarea, idreporte, etapatarea } = data;

                const reiniciaEtapaRptRequest = {
                    idreporte : idReporte!==undefined && idReporte!== null ? idReporte : idreporte,
                    //idreporte: idreporte,
                    idtarea: idtarea,
                    idnotificacion: '',
                    etapatarea: etapatarea,
                    fechaconcepto: fechaUTC(),
                    observacion: stateEjecutarActividad.form.TxtObservacion.value
                };
                let response = await ReiniciarEtapaTarea(reiniciaEtapaRptRequest);
                if (response !== undefined && response.hasOwnProperty('linea')) {
                    console.log(response.mensaje);
                } else {
                    renderizarLabelsValores(response);
                }



            } catch (error) {
                console.log(error)
                props.setShowEjecutar(false);
            }
		}
        // Realiza lógica objetos 23
    const btnTerminar = async(btnSlected) => {
        console.log(btnSlected)
        try {
			setCargando(true);
            
            //const reportesEtapa =stateEjecutarActividad.respuestaGetEjecutarActividad.datatable.filter(e => e.etapatarea === stateEjecutarActividad.respuestaGetEjecutarActividad.etapatarea);
            //const data = reportesEtapa[reportesEtapa.length-1];
            const data = stateEjecutarActividad.respuestaGetEjecutarActividad.datatable.filter(e => e.idreporte.toLowerCase() === idReporte.toLowerCase());
            const { idtarea, idreporte, etapatarea, descripciontarea, idunidadmedida } = data[data.length-1];
            //const archivo = document.getElementById('img').files			 
            const archivo = stateEjecutarActividad.form.foto;
            const cantidadReportada=parseFloat(stateEjecutarActividad.form.TxtCantidad.value);
            if (cantidadReportada<0){
                window.alert('La cantidad a reportar debe ser mayor a cero');
                setCargando(false);
            }else{
                if (archivo !== undefined &&  archivo!==null && archivo.value!==null && archivo.value.indexOf('https') === -1) {
                    let extensionFoto=archivo.value.substring(0,20).replace('data:image/','')
                        extensionFoto=extensionFoto.substring(0,extensionFoto.indexOf(';'))                
                    let nuevoType = String.prototype.concat('data:image/',extensionFoto,';base64,')
                    var image = new Image();
                    image.src = archivo.value;

                    const vardataCompre = archivo.value.length > 12000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.3) :
                        archivo.value.length > 9000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.4) :
                        archivo.value.length > 6000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.45) :
                        archivo.value.length > 4000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.50) :
                        archivo.value.length > 3000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.6) :
                        archivo.value.length > 2000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.7) :
                        archivo.value.length > 1000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.8) :
                        archivo.value;

                    //const vardataCompre= await resizeBase64Img(archivo.value,image.width,image.height);
                    const variablesTarea = VariablesEjeEtapaTarea(idtarea,idreporte,etapatarea);       
                    const recursostarea = CargarRecursosTarea(idtarea,idreporte);
                    const terminaEtapaRequest = {
                        idreporte: idreporte,
                        idtarea: idtarea,
                        idnotificacion: '',
                        etapatarea: etapatarea,
                        fechaconcepto: new Date(Date().toLocaleString('es-CO')),//new Date(stateEjecutarActividad.form.TxtFecha.value),
                        cantidadreportada: parseFloat(stateEjecutarActividad.form.TxtCantidad.value),
                        observacion: stateEjecutarActividad.form.TxtObservacion.value,
                        fechafoto: fechaUTC(),
                        filename: etapatarea + '.' + extensionFoto,
                        data64file: vardataCompre,
                        data64filetype:nuevoType,
                        tareaterminada: true,
                        descripcion: descripciontarea,
                        variables : variablesTarea,
                        sincronizado:false,
                        recursos:recursostarea,
                        textoprogramacion: getLabel("LblProgramacion"),
                        idunidadmedida: idunidadmedida,
                        descripciontarea: descripciontarea 
                    };
                    
                    const response = await TerminarEtapaTarea(terminaEtapaRequest);
                    setCargando(false);
                    if (response !== undefined && response.hasOwnProperty('linea')) {
                        window.alert(response.mensaje);
                        console.log(response);

                    } else {
                        setSoloLectura(true);
                        renderizarLabelsValores(response,idreporte);
                        if (!response.ok && etapatarea==='Inicio'){
                            const terminaEtapaRequest = {
                                idreporte: 'rptOffline'.concat((Math.random()  * 22).toString(36).substring(2)),
                                idtarea: idtarea,
                                idnotificacion: '',
                                etapatarea: 'Ejecución',
                                fechaconcepto: new Date(Date().toLocaleString('es-CO')),
                                cantidadreportada: parseFloat('0'),
                                observacion: null,
                                fechafoto: null,
                                filename: null,
                                data64file: null,
                                tareaterminada: false,
                                descripcion: descripciontarea,
                                sincronizado:false,
                                textoprogramacion: getLabel("LblProgramacion"),
                                idunidadmedida: idunidadmedida,
                                descripciontarea: descripciontarea !== undefined ? descripciontarea.replace('Inicio','Ejecución').replace('inicio','Ejecución') : descripciontarea
                            };                            
                            GuardaEjecucionEtapaTarea(terminaEtapaRequest,true);
                        }
                    }				
                } else {
                    // console.log('Es necesario asociar una imagen');
                    // throw new Error('Es necesario asociar una imagen');
                    window.alert('Es necesario asociar una imagen');
                    setCargando(false);
                }
            }
        } catch (error) {
			setCargando(false);
            console.log(error)
            props.setShowEjecutar(false);
        }
    }
	
	const btnGuardar = async(btnSlected) => {
        console.log(btnSlected)
        try {			
            const data = stateEjecutarActividad.respuestaGetEjecutarActividad.datatable.filter(e => e.etapatarea === stateEjecutarActividad.respuestaGetEjecutarActividad.etapatarea)[0];
            const { idtarea, idreporte, etapatarea, descripcion, idunidadmedida,descripciontarea } = data;
            //const archivo = document.getElementById('img').files			 
            const archivo = stateEjecutarActividad.form.foto;
            if (archivo !== undefined) {

                var image = new Image();
                image.src = archivo.value;
                let extensionFoto=archivo.value.substring(0,20).replace('data:image/','')
                extensionFoto=extensionFoto.substring(0,extensionFoto.indexOf(';'))                
                

                const vardataCompre = archivo.value.length > 12000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.3) :
                    archivo.value.length > 9000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.4) :
                    archivo.value.length > 6000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.45) :
                    archivo.value.length > 4000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.50) :
                    archivo.value.length > 3000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.6) :
                    archivo.value.length > 2000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.7) :
                    archivo.value.length > 1000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.8) :
                    archivo.value;

                //const vardataCompre= await resizeBase64Img(archivo.value,image.width,image.height);
                
                const terminaEtapaRequest = {
                    idreporte:  idReporte!==undefined && idReporte!== null ? idReporte : idreporte,
                    idtarea: idtarea,
                    idnotificacion: '',
                    etapatarea: etapatarea,
                    fechaconcepto: new Date(Date().toLocaleString('es-CO')),
                    cantidadreportada: parseFloat(stateEjecutarActividad.form.TxtCantidad.value),
                    observacion: stateEjecutarActividad.form.TxtObservacion.value,
                    fechafoto: fechaUTC(),
                    filename: etapatarea + '.' + extensionFoto,
                    data64file: vardataCompre,
                    tareaterminada: false,
                    descripcion: descripcion,
                    sincronizado:false,
                    textoprogramacion: getLabel("LblProgramacion"),
                    idunidadmedida: idunidadmedida,
                    descripciontarea: descripciontarea
                };				
                //await GuardaEjecucionEtapaTarea(terminaEtapaRequest);	
                GuardaEjecucionEtapaTarea(terminaEtapaRequest);					
                //handleForm({name:'TxtFecha',value:new Date(terminaEtapaRequest.fechaconcepto)});
            } else {
                console.log('Es necesario asociar una imagen');
                //throw new Error('Es necesario asociar una imagen');
                window.alert('Es necesario asociar una imagen');
            }
        } catch (error) {
            console.log(error)
            props.setShowEjecutar(false);
        }
    }

    // Realiza lógica objetos 24 al 26
    const btnConcepto = async(btnSlected) => {
        console.log(btnSlected)
        try {
            const data = stateEjecutarActividad.respuestaGetEjecutarActividad.datatable.filter(e => e.etapatarea === stateEjecutarActividad.respuestaGetEjecutarActividad.etapatarea)[0];
            const { idtarea, idreporte, etapatarea } = data;
            const idreporteFinal = idReporte!==undefined && idReporte!== null ? idReporte : idreporte;
            const variablesTarea = VariablesEjeEtapaTarea(idtarea,idreporteFinal,etapatarea);       
            const recursostarea = CargarRecursosTarea(idtarea,idreporteFinal);
            const archivo = stateEjecutarActividad.form.foto;
            let conceptoEtapaRptRequest = {
                idreporte: idreporteFinal,
                idtarea: idtarea,
                idnotificacion: '',
                concepto: btnSlected,
                fechaconcepto: fechaUTC(),
                observacion: stateEjecutarActividad.form.TxtObsConcepto.value,
                cantidadconcepto: parseFloat(stateEjecutarActividad.form.TxtCantidad.value),
                variables:variablesTarea,
                recursos : recursostarea,   
                etapatarea: etapatarea,             
            };
            if (archivo !== undefined &&  archivo!==null && archivo.value!==null && archivo.value.indexOf('https') === -1) {
                let extensionFoto=archivo.value.substring(0,20).replace('data:image/','')
                extensionFoto=extensionFoto.substring(0,extensionFoto.indexOf(';'))                
                let nuevoType = String.prototype.concat('data:image/',extensionFoto,';base64,')              
                
                var image = new Image();
                image.src = archivo.value;

                const vardataCompre = archivo.value.length > 12000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.3) :
                    archivo.value.length > 9000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.4) :
                    archivo.value.length > 6000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.45) :
                    archivo.value.length > 4000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.50) :
                    archivo.value.length > 3000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.6) :
                    archivo.value.length > 2000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.7) :
                    archivo.value.length > 1000000 ? await resizeBase64Img(archivo.value, image.height, image.width, 0.8) :
                    archivo.value;

                conceptoEtapaRptRequest.fechafoto = fechaUTC();
                conceptoEtapaRptRequest.filename = etapatarea + '.' + extensionFoto;
                conceptoEtapaRptRequest.data64file = vardataCompre;
                conceptoEtapaRptRequest.data64filetype = nuevoType;                    
            }
            let response = await ConceptoEjecucionEtapaTarea(conceptoEtapaRptRequest);
            if (response !== undefined && response.hasOwnProperty('errores') && response.errores!==null) {
                let mensaje='';
                response.errores.forEach((registro)=>{
                    mensaje=mensaje.concat('Linea: ', registro.linea, '. Error:', registro.mensaje, '\n');
                    
                })
                console.log(mensaje);
                window.alert(mensaje);
            } else {
                window.alert('Se realizó con éxito el concepto de ejecución');
                cerrar();
            }
        } catch (error) {
            console.log(error);
            props.setShowEjecutar(false);
            window.alert(error.message);
        }
    }

    const camera = () => {
        setOpenCamera(true)
    }

    // Relaliza la petición al back y pasa a renderizar labels y valores de la vista
    const consultaEjecucion = async(idtarea, idreporte, etapatarea) => {        
        try 
        {
            let response;
            if (idreporte === undefined || idreporte === null ){
                // esta api debe crear el reporte de ejecucuíón en la etapa indicada
                response = await GetConsultarEtapaTarea(idtarea, ' ', etapatarea);
                ValidReporte(response.idnuevoreporte);
                idreporte=(response.idnuevoreporte)
            }
            else{
                response = await ConEjecutarActividad(idtarea, idreporte, etapatarea);
                ValidReporte(idreporte);

            }
            setobjRecursos({
                idreporte: idreporte,
                idtarea : idtarea,
                etapatarea: etapatarea,
                idactividad:idAbrirActividad,
                fechaInicial:response.fechainicial,
                fechaFinal:response.fechafinal
            });
            if (response.ok===true){
                ValidaetapaTarea(response.etapatarea);
                setapiActividadRecurso(response);
                renderizarLabelsValores(response, idreporte);
            }else{
                window.alert(response.mensaje);
                props.setShowEjecutar(false);
            }
            
            
        } catch (error) {
            console.log(error)
            window.alert(error.message);
            props.setShowEjecutar(false);
        }
        // setStateEjecutarActividad({...stateEjecutarActividad, respuestaGetEjecutarActividad: response});
    }


    useEffect(() => {
        if (props.showEjecutar) {
            // ejecutar consulta 
			setCargando(true);
            let finalizoApi=false;
            if (props.origen === undefined || props.origen === null){
                const { arbol, idAbrirActividad } = props;
                const tareaSeleccionada = arbol.filter(e => e.id.toLowerCase() === idAbrirActividad.toLowerCase());
                const { idtarea, idreporte, etapatarea } = tareaSeleccionada[0]!==null?tareaSeleccionada[0]:null;                
                consultaEjecucion(idtarea, idreporte, etapatarea)
                .then( ()=>{finalizoApi=true})                
                setIdAbrirActividad(idtarea);
                ValidReporte(idreporte);            
                ValidaetapaTarea(etapatarea);
            }
            else{
                const tareaSeleccionada =  JSON.parse(window.localStorage.getItem('tarSel'));
                const { id, idReporte, estado } = tareaSeleccionada[0];
                consultaEjecucion(id, idReporte, estado)
                .then( ()=>{finalizoApi=true})                
                setIdAbrirActividad(id);
                ValidReporte(idReporte);            
                ValidaetapaTarea(estado);
            }
            
			setCargando(false);
        }

        return () => {
            // TODO: limpiar stateEjecutarActividad
        }
    }, [props.showEjecutar]);
	
    if(cargando){
        return (<Cargando />);
    }else{
        return (
            <SweetAlert
                show={props.showEjecutar}
                title={''}
                // onConfirm={async()=>SincronizaReportesEtapaTarea()}
                onConfirm={()=>null}
                showCancel={false}
                showConfirm={false}
                confirmBtnText={"Aceptar"}
                closeOnClickOutside={false}
                showCloseButton={true}>
                <div>
                    <div style={{...estilos.fila, borderBottom:'2px #0000002b solid', marginBottom:'5px', paddingBottom:'5px'}}>
                        {
                            getVisibleActivoElement("LblTituloPantalla", "visible") && 
                            <h5 style={{width:'100%', textAlign:'center', color: getVisibleActivoElement("LblTituloPantalla", "activo") ? 'black': 'gray'}} id="LblTituloPantalla">{getLabel("LblTituloPantalla")}</h5>
                        }
                        {
                            getVisibleActivoElement("CmdCerrar", "visible") &&
                            <button onClick={()=>{
                                    if(getVisibleActivoElement("CmdCerrar", "activo")) cerrar();
                                }}
                                type="button" className="btn btn-outline-danger btn-sm" id="CmdCerrar" style={{color:getVisibleActivoElement("CmdCerrar", "activo") ? 'black': 'gray'}}>{getLabel("CmdCerrar")}</button>
                        }
                        </div>
                    <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'3em' }}>
                        <div style={{...estilos.fila, justifyContent:'space-around'}}>
                            {getVisibleActivoElement("CmdRecursos", "visible") &&
                                <div style={{textAlign:'center'}}>
                                    <FcSupport onClick={()=>{
                                            if(getVisibleActivoElement("CmdRecursos", "activo")) {
                                                ValidapaginaVer(1);
                                                onAbrirRecurso();
                                            }
                                        }} name="Agregar" className="cursor" title='Personal/Equipos' 
                                        style={{
                                            ...estilos.estiloIcono,
                                             cursor: getVisibleActivoElement("CmdRecursos", "activo") ? 'pointer' :'default'}}
                                    />
                                    <p style={{fontWeight:'400', color:getVisibleActivoElement("CmdRecursos", "activo") ? 'black': 'gray'}}>{getLabel("CmdRecursos")}</p>
                                </div>
                            }
                            {
                                getVisibleActivoElement("CmdVariables", "visible") &&
                                <div style={{textAlign:'center'}}>
                                    <FcInspection onClick={()=>{
                                            if(getVisibleActivoElement("CmdVariables", "activo")){
                                                ValidapaginaVer(2);
                                                onAbrirActividad();
                                            } 
                                        }} 
                                        className="cursor" title='Variables' style={{
                                            ...estilos.estiloIcono,
                                             cursor: getVisibleActivoElement("CmdVariables", "activo") ? 'pointer' :'default'}}
                                    />
                                    <p style={{
                                        fontWeight:'400',
                                        color:getVisibleActivoElement("CmdVariables", "activo") ? 'black': 'gray',
                                        }}>{getLabel("CmdVariables")}</p>
                                </div>
                            }
                        </div>
                        
                    </IconContext.Provider>
                    { getVisibleActivoElement("LblDescripcion", "visible") && <h3 id="LblDescripcion" style={{textAlign:'center', color:getVisibleActivoElement("LblDescripcion", "activo") ? 'black': 'gray'}}>
                        {getLabel("LblDescripcion")}</h3> 
                    }
                    <div style={{...estilos.fila}}>
                        { getVisibleActivoElement("LblProgramacion", "visible") && <p id="LblProgramacion" style={{fontSize:'small', color:getVisibleActivoElement("LblProgramacion", "activo") ? 'black': 'gray'}}>
                            {getLabel("LblProgramacion")}</p>
                        }
                    </div>
                    <div>
                        { getVisibleActivoElement("LblFecha", "visible") && <p style={{fontWeight:'bold', color:getVisibleActivoElement("LblFecha", "activo") ? 'black': 'gray'}}>
                            {getLabel("LblFecha")}</p> 
                        }
                        <div className="input-group mb-3">
                            { getVisibleActivoElement("TxtFecha", "visible") &&
                                <input onChange={({target})=>handleForm(target)} disabled={!getVisibleActivoElement("TxtFecha", "activo")} name="TxtFecha" type="datetime-local" className="form-control" aria-label="TxtFecha"
                                aria-describedby="basic-addon1" value={TxtFecha.value}/>
                            }
                        </div>
                    </div>
                    <div>
                        { getVisibleActivoElement("LblCantidad", "visible") && <p id="LblCantidad" style={{fontWeight:'bold', color:getVisibleActivoElement("LblCantidad", "activo") ? 'black': 'gray'}}>
                            {getLabel("LblCantidad")}</p> 
                        }
                        <div className=" mb-3" style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                            <input id="TxtCantidad" style={{marginRight:'5px', width:'60%', display: getVisibleActivoElement("TxtCantidad", "visible") ? 'block' : 'none'}} 
                                onChange={({target})=>handleForm(target)} name="TxtCantidad" type="number" className="form-control"
                                disabled={!getVisibleActivoElement("TxtCantidad", "activo")} placeholder="Ingresa una cantidad" aria-label="TxtCantidad" aria-describedby="basic-addon1" 
                                value={TxtCantidad.value}/>
                            <p id="LblUnidadMedida" 
                                style={{
                                    fontWeight:'bold',
                                    display: getVisibleActivoElement("LblUnidadMedida", "visible") ? 'block' : 'none',
                                    color:getVisibleActivoElement("LblUnidadMedida", "activo") ? 'black': 'gray'}}
                                >
                                {getLabel("LblUnidadMedida")}</p>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-around', marginBottom:'15px'}}>
                            <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'3em' }}>
                                <div style={{display:'flex', flexDirection:'column', }}>
                                    <FcOldTimeCamera onClick={()=>{
                                        if(getVisibleActivoElement("CmdCapturar", "activo")) camera();
                                        }} className="cursor" title='Capturar foto' style={{
                                            ...estilos.estiloIcono,
                                            cursor: getVisibleActivoElement("CmdCapturar", "activo") ? 'pointer' :'default',
                                            display: getVisibleActivoElement("CmdCapturar", "visible") ? 'block' : 'none'}} />
                                        <input id="searhFoto" type="file" accept="image/*" style={{ display: "none" }}
                                            onChange={({target})=>{searchPhoto(target)}}/>
                                        <label htmlFor="searhFoto">
                                                {/* <FcOldTimeCamera className="cursor" title='takePhoto' style={estilos.estiloIcono} /> */}
                                                <FcSearch 
                                                    className="cursor" title='Asociar Evidencia' style={{
                                                        ...estilos.estiloIcono,
                                                        cursor: getVisibleActivoElement("CmdBuscar", "activo") ? 'pointer' :'default',
                                                        display: getVisibleActivoElement("CmdBuscar", "visible") ? 'block' : 'none'}}
                                                />
                                        </label>
                                    <FcFullTrash onClick={()=>{
                                        if(getVisibleActivoElement("CmdBorrar", "activo"))  btnEliminar('trash')
                                        }} className="cursor" title='Eliminar' style={{
                                            ...estilos.estiloIcono,
                                            cursor: getVisibleActivoElement("CmdBorrar", "activo") ? 'pointer' :'default',
                                            display: getVisibleActivoElement("CmdBorrar", "visible") ? 'block' : 'none' }}/>
                                </div>
                            </IconContext.Provider>
                            <img src={form.foto && form.foto.value!==null ? form.foto.value : noImg} alt="" style={{width:'280px', borderRadius:'20px'}}/>
                    </div>
                    <div id="btnsGroup1" style={{display:'flex', justifyContent:'space-around', marginBottom:'10px', textAlign:'center', width:'100%'}}>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdInicio", "activo"))  btnSelected('Inicio')
                            }} style={{
                                ...estilos.botonEjeAct,
                                color:getVisibleActivoElement("CmdInicio", "activo") ? 'white': 'gray',
                                display: getVisibleActivoElement("CmdInicio", "visible") ? 'block' : 'none',
                                cursor: getVisibleActivoElement("CmdInicio", "activo") ? 'pointer' :'default'}}>
                                    {getLabel("CmdInicio")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdEjecucion", "activo"))  btnSelected('Ejecución')
                            }} style={{
                                ...estilos.botonEjeAct,
                                color:getVisibleActivoElement("CmdEjecucion", "activo") ? 'white': 'gray',
                                display: getVisibleActivoElement("CmdEjecucion", "visible") ? 'block' : 'none',
                                cursor: getVisibleActivoElement("CmdEjecucion", "activo") ? 'pointer' :'default'}}>
                                    {getLabel("CmdEjecucion")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdFin", "activo"))  btnSelected('Fin')
                            }} style={{
                                ...estilos.botonEjeAct,
                                color:getVisibleActivoElement("CmdFin", "activo") ? 'white': 'gray', width:'50px',
                                display: getVisibleActivoElement("CmdFin", "visible") ? 'block' : 'none',
                                cursor: getVisibleActivoElement("CmdFin", "activo") ? 'pointer' :'default'}}>
                                    {getLabel("CmdFin")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdRelevante", "activo"))  btnSelected('Relevante')
                            }} style={{
                                ...estilos.botonEjeAct,
                                color:getVisibleActivoElement("CmdRelevante", "activo") ? 'white': 'gray',
                                display: getVisibleActivoElement("CmdRelevante", "visible") ? 'block' : 'none',
                                cursor: getVisibleActivoElement("CmdRelevante", "activo") ? 'pointer' :'default'}}>
                                    {getLabel("CmdRelevante")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdAmarillado", "activo"))  btnSelected('Amarillado')
                            }} style={{
                                ...estilos.botonEjeAct,
                                color:getVisibleActivoElement("CmdAmarillado", "activo") ? 'white': 'gray',
                                display: getVisibleActivoElement("CmdAmarillado", "visible") ? 'block' : 'none',
                                cursor: getVisibleActivoElement("CmdAmarillado", "activo") ? 'pointer' :'default'}}>
                                    {getLabel("CmdAmarillado")}
                        </button>
                    </div>
                    <div style={{marginBottom:'10px', textAlign:'center', width:'100%'}}>
                        <textarea onChange={({target})=>handleForm(target)} name="TxtObservacion" className="form-control" value={TxtObservacion.value ? TxtObservacion.value : ''}
                        disabled={!getVisibleActivoElement("TxtObservacion", "activo")} aria-label="With textarea" placeholder="Observaciones en la ejecución de la actividad" 
                            style={{height: "100px", display: getVisibleActivoElement("TxtObservacion", "visible") ? 'block' : 'none'}} />
                            
                        <textarea onChange={({target})=>handleForm(target)} name="TxtObsConcepto" className="form-control"
                        disabled={!getVisibleActivoElement("TxtObsConcepto", "activo")} aria-label="With textarea" placeholder="Observaciones en la confirmación o rechazo de la actividad" 
                        style={{height: "100px", marginTop:'10px', display: getVisibleActivoElement("TxtObsConcepto", "visible") ? 'block' : 'none'}} value={TxtObsConcepto.value ? TxtObsConcepto.value : ''}/>
                    </div>
                    <div id="btnsGroup2" style={{display:'flex', justifyContent:'space-around', marginBottom:'10px', textAlign:'center', width:'100%'}}>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdTerminar", "activo"))  btnTerminar('Terminar')
                            }}  style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdTerminar", "activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdTerminar", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdTerminar", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdTerminar")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdConfirmar", "activo"))  btnConcepto('Confirmar')
                            }} style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdConfirmar","activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdConfirmar", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdConfirmar", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdConfirmar")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdRechazar", "activo"))  btnConcepto('Rechazar')
                            }}  style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdRechazar", "activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdRechazar", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdRechazar", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdRechazar")}
                        </button>
                        <button onClick={()=>{
                            if(getVisibleActivoElement("CmdAprobar", "activo"))  btnConcepto('Aprobar')
                            }}   style={{
                                ...estilos.botonEjeAct,
                                 color:getVisibleActivoElement("CmdAprobar", "activo") ? 'white': 'gray',
                                 display: getVisibleActivoElement("CmdAprobar", "visible") ? 'block' : 'none',
                                 cursor: getVisibleActivoElement("CmdAprobar", "activo") ? 'pointer' :'default'}}>
                                     {getLabel("CmdAprobar")}
                        </button>
                    </div>
    
                    <ModalCamera openModal={openCamera} setOpenModal={setOpenCamera} btnTerminar={btnGuardar} handleForm={handleForm}/>
                    
                    {showActividadCampo &&
                    <Variables showActividadCampo={showActividadCampo} 
                               setShowActividadCampo={setShowActividadCampo}
                               titulo={titulo}
                               idAbrirActividad={idAbrirActividad}
                               setIdAbrirActividad={setIdAbrirActividad}
                               stepInicio={paginaVer}
                               idreporte={idReporte}
                               etapatarea={etapaTarea}                           
                              />}
    
                    {showRecurso && <Recursos objsolicitudRecursos={objRecursos}
                                apiActividadRecurso={apiActividadRecurso}
                                stepInicio={1}
                                showActividadCampo={showRecurso} 
                                setShowActividadCampo={setShowRecurso}
                                esProgramacion={false}
                                idReporteEjecucion={idReporte}
                                origenPantalla='EjecutarActividad'
                        />}
                    
                </div>
            </SweetAlert>
        );
    }   
}

const estilos = {
    fila:{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'5px', marginTop:'5px'},
    botonEjeAct:{
        borderRadius: '10px',
        padding:'10px 5px',
        backgroundColor: colores.primario,
        color:'white',
        fontWeight:'bold',
        fontSize:'15px',
        marginLeft:'2px',
        border:'blue'
    },
    estiloIcono: {marginLeft:'0px', marginRight:'0px',}
}