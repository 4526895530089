import React, {useState, useEffect} from 'react'
import {FaFileExcel, FaUserSecret, FaEdit, FaTasks, FaCamera, FaThumbsUp} from 'react-icons/fa'
import Cargando from '../componentes/Cargar'
import {GetDatosMaestra,ExportarTodoCsvAsync,GetTareasActividad} from '../componentes/ConsumirApiRest'
import { GetOffLine} from '../componentes/Ejecucion/ejecucionOffline'
import ArbolDatos from '../componentes/Actividades/arbolDatos'
import {Actividad} from './unaActividad/Actividad'
import {EjecutarActividad} from './ejecutarActividad/EjecutarActividad'
import {ViewTareas} from './tareaActividad/ViewTareas'
//import  {SincronizacionReportes} from '../componentes/Ejecucion/SincronizacionReportes'

export default function Actividades3w(props){
    
    const [error, setError] = useState(null)
    const [verEjecutar, setVerEjecutar] = useState(false)
    const [cargando, setCargando] = useState(true)
    const [titulo, setTitulo] = useState()
    const [showActividadCampo, setShowActividadCampo] = useState(false)
    const [showActividadTarea, setShowActividadTarea] = useState(false)
    const [showEjecutar, setShowEjecutar] = useState(false)
    const [idAbrirActividad, setIdAbrirActividad] = useState(null)
    const [actualizarDatos, setactualizarDatos] = useState(false)
    const [modoActividadCampo, setModoActividadCampo]= useState(false)
    const [estadoBotones, setEstadoBotones] = useState({CmdActividadCampo:{visible: false, activo: false},
                                                        CmdCargaMasiva:{visible: false, activo: false},
                                                        CmdProgramar:{visible: false, activo: false},
                                                        CmdEjecutar:{visible: false, activo: false},
                                                        CmdConfirmar:{visible: false, activo: false},
                                                        CmdExportar:{visible: false, activo: false},
                                                        CmdAuditoria:{visible: false, activo: false},
                                                        CmdCerrar:{visible: false, activo: false}})
    //const [sincronizando,setSincronizando]=useState(null)                                               
    const [arbol, setArbol] = useState([])
    
    const [apiActividadVariable, setApiActividadVariable] = useState({idreporte:'',
                                                                     idtarea :'',
                                                                     etapatarea:''})

    const [apiActividadTarea, setApiActividadTarea] = useState({listadoobjetosestado:[],
                                                                objetosDataColumn:[],
                                                                datatable:[],
                                                                endpoint:''})

    const [objActividadTarea, setObjActividadTarea] =useState ({idactividad: '',
                                                                estadoactividad: '',
                                                                rows:[],
                                                                fechaInicial:null,
                                                                fechaFinal:null
                                                                })
    const onCerrarActividades = () =>{
        setCargando(true)
        setTimeout(() =>{
            props.setPantalla(antes=>{
                               return {...antes,
                                       actividad: {activa: false, configuracion: null, nuevallamada: false}, 
                                       principal:{activa: true}}
                            })
            setCargando(false)
        },200)
    }

    /*
    const onSyncActividades = () =>{
        setCargando(true)
        setTitulo(null)
        const obtieneDatos = async ()=>{
            let apiActividades = await GetDatosMaestra(props, ()=>console.log)
                                .then((datos)=>{ return datos })
            if(apiActividades!==false){
                window.localStorage.setItem('actividades', JSON.stringify(apiActividades))
            }else{
                apiActividades = JSON.parse(window.localStorage.getItem('actividades'))
            }
            if(apiActividades!==null){
                setTitulo(apiActividades.tituloPantalla)
                setEstadoBotones(antes=>{
                    let old = []
                    apiActividades.listadoObjetosEstado.map(estado =>{
                        old[estado.id] = {visible: estado.visible, activo: estado.activo}
                    })
                    return old
                })
                setArbol(apiActividades.datatable)
            }else{
                setError(`No existen actividades en el dispositivo. Por favor intente de 
                          nuevo cuando cuente con acceso a internet`)
            }
            setCargando(false)
        }
        obtieneDatos()
    }
    */
    const onAbrirCargaMasiva =()=>{
        let microServicio = null
        let loginUser = JSON.parse(window.localStorage.getItem('logearUsuario'))
        let menu = loginUser.perfilUsuario.menu
        for(let obj of menu){
            if(obj.pantalla === 'Carga Masiva'){
                microServicio = obj.microServicio
                break
            }
        }
        props.setCargaMasiva({show:true,configuracion: {microServicio: microServicio}})
    }


    const onAbrirActividad =()=>{
        if (idAbrirActividad!==null){
            const tareaSeleccionada = arbol.filter(e => e.id === idAbrirActividad);
            if (tareaSeleccionada!==null && tareaSeleccionada.length > 0) {
                const {tiporegistro} = tareaSeleccionada[0];
                if (tiporegistro!==null && tiporegistro==='Actividad'){
                    setShowActividadCampo(true)
                }
                else{
                    window.alert('Esta opción solo es válida para actividades, usted seleccionó un reporte de ejecución')
                }
            }
        }
        else{
            window.alert('Es necesario seleccionar un registro')
        }
    }

    

    const onAbrirActividadCampo =()=>{
        if (idAbrirActividad!==null){
            const tareaSeleccionada = arbol.filter(e => e.id === idAbrirActividad);
            if (tareaSeleccionada!==null && tareaSeleccionada.length > 0) {
                const {tiporegistro} = tareaSeleccionada[0];
                if (tiporegistro!==null && tiporegistro==='Actividad'){        
                    setModoActividadCampo(true);            
                    setShowActividadCampo(true);
                }
                else{
                    window.alert('Esta opción solo es válida para actividades, usted seleccionó un reporte de ejecución')
                }
            }
        }
        else{
            window.alert('Es necesario seleccionar un registro')
        }
    }
    
    const onAbrirTareas = async () =>{
        if (idAbrirActividad!==null){
            const tareaSeleccionada = arbol.filter(e => e.id === idAbrirActividad);
            if (tareaSeleccionada!==null && tareaSeleccionada.length > 0) {
                const {tiporegistro} = tareaSeleccionada[0];

                if (tiporegistro!==null && tiporegistro==='Actividad'){
                    const respuestaServicio = await GetTareasActividad(idAbrirActividad);
                    
                    if (respuestaServicio!==undefined &&  respuestaServicio!==null){
                        if (respuestaServicio.hasOwnProperty('mensaje')){
                            window.alert(respuestaServicio.mensaje);
                        } else if (respuestaServicio.hasOwnProperty('message')){
                            const mensajeError = respuestaServicio.message.indexOf('etch')>=0 ? 'No hay internet en su dispositivo' : respuestaServicio.message;
                            window.alert(mensajeError);
                        }
                        else
                        {
                            let objsolicitudTareas =
                            {
                                idactividad: respuestaServicio.datatable.length>0 ?
                                                respuestaServicio.datatable[0].idactividad :
                                                idAbrirActividad,
                                estadoactividad : respuestaServicio.datatable.length>0 ? 
                                                respuestaServicio.datatable[0].estado :
                                                'Programada',
                                rows: [],
                                apiActividadRecurso:[],
                                fechaInicial:respuestaServicio.fechainicial,
                                fechaFinal:respuestaServicio.fechafinal
                            }

                            if (respuestaServicio.datatable!==undefined && respuestaServicio.datatable.length>0 )
                            {
                                //se hace  para generar las filas de recursos
                                respuestaServicio.datatable.forEach((item)=>{
                                    const itemRec ={
                                        "id": item.idtarea,
                                        "idReporte": item.idreporte,
                                        "estado": item.estado,
                                        "tarea": item.descripcion,
                                        "unidad": item.idunidadmedida,
                                        "cantidad": item.cantidad,
                                        "fechainicial" : item.fechainiprog,
                                        "fechafinal":item.fechaFinprog
                                    }
                                    objsolicitudTareas.rows.push(itemRec);
                                });    
                            }
                            setObjActividadTarea(objsolicitudTareas)
                            setApiActividadTarea(respuestaServicio)
                            setShowActividadTarea(true)
                        }
                    }
                }
                else{
                    window.alert('Esta opción solo es válida para actividades, usted seleccionó un reporte de ejecución')
                }
            }
        }
        else{
            window.alert('Es necesario seleccionar un registro')
        }
        
    }

    const onExportar = async () =>{
        const viewExportarExcelRequest = {
            'idproyecto': '*',
            'idespecialidad': '*',
            'fechainicial': null,
            'fechafinal': null,
        }
       // objeto que responde el servicio se hago o no el llamado a api response = {
       //     "enviado": true,
       //     "destino": usuario.correo,
       //     "error": null
        // };

        let respuestaServicio = await ExportarTodoCsvAsync(viewExportarExcelRequest);
      //  .then(resp => resp.json())
      //  .then(respObj => {
      //      const mensajeRespuesta='Se remitieron los archivos CSV al correo ' + respObj.destino;
      //      console.log(mensajeRespuesta);           
      //  })
      //  .catch(error => {
      //      console.log({ error })
      //      
      //  }); 
       window.alert(respuestaServicio.error);
       console.log(respuestaServicio);
    }


    const onAbrirEjecutar =()=> {
        const tareaSeleccionada = JSON.parse(window.localStorage.getItem('recSel'));
        if (tareaSeleccionada!==undefined && tareaSeleccionada!==null){
            const {incidencia}=tareaSeleccionada.length>=1?tareaSeleccionada[0]:tareaSeleccionada;
            if (incidencia!==undefined && incidencia!==null){
                if (parseInt(incidencia.replace('%',''))>99 && GetOffLine()){
                    window.alert("No es posible ejecutar un reporte Offline en una tarea cuyo avance es superior o igual al 100%");
                }else{
                    setShowEjecutar(true);
                }                
            }else{
                window.alert("Es necesario seleccionar un reporte");
            }            
        }else{
            window.alert("Es necesario seleccionar un reporte");
        }
        
    }
    //const validarSincronizar =() =>{
    //    setSincronizando(true);
    //}
    useEffect(() => {
        setCargando(true);
        setTitulo(null);
        if (showActividadTarea===false){
            localStorage.removeItem('recSel');
            localStorage.removeItem('tarSel');
        }
            
        const obtieneDatos = async (itemReportes)=>{            
            let finalizoApi=false;                                                   
            let apiActividades = await GetDatosMaestra(props, ()=>console.log)
                .then((datos)=>{ 
                    finalizoApi=true;
                    return datos;
                });

            if(apiActividades!==false){
                if (itemReportes && itemReportes.length>0){
                    let dataFInal=[];       
                    let reportesOffline=  [...itemReportes];           
                    apiActividades.datatable.forEach((registro)=>{
                        const resultado=itemReportes.find(x=>x.idreporte===registro.idreporte && x.etapatarea===registro.etapatarea && 
                                                            x.idtarea===registro.idtarea);
                        if (resultado && resultado!=null){
                            reportesOffline = reportesOffline.filter((item) => item !== resultado)                            
                            const itemNuevo={
                                'id': registro.id ,
                                'idEspecialidad': registro.idEspecialidad ,
                                'idPadre': registro.idPadre ,
                                'idProyecto': registro.idProyecto ,
                                'idreporte': resultado.idreporte ,
                                'idtarea': resultado.idtarea ,
                                'incidencia': registro.incidencia ,
                                'padre': registro.padre ,
                                'rangoFecha': registro.rangoFecha ,
                                'tiporegistro': registro.tiporegistro ,
                                'variables': registro.variables ,
                                'foto': resultado.data64file ,
                                'etapatarea': resultado.etapatarea ,
                                'descripcion': resultado.descripcion ,
                                'bloqueadoPor': null,
                            };
                            dataFInal.push(itemNuevo);
                        }else{
                            dataFInal.push(registro);
                        }                                            
                   }) ;  
                   
                   reportesOffline.forEach((rptOffline)=>{ 
                        const rptApi=apiActividades.datatable.find(x=> x.etapatarea==='Inicio' && x.idtarea===rptOffline.idtarea);
                        if (rptApi!== undefined && rptApi!==null){
                            const itemNuevoOffline={
                                'id': rptOffline.idreporte ,
                                'idtarea': rptOffline.idtarea ,
                                'idreporte': rptOffline.idreporte ,
                                'idProyecto': rptApi.idProyecto ,
                                'idEspecialidad': rptApi.idEspecialidad ,
                                'idPadre': rptApi.idPadre ,
                                'incidencia': rptApi.incidencia ,
                                'padre': rptApi.padre ,
                                'rangoFecha': rptApi.rangoFecha ,
                                'tiporegistro': rptApi.tiporegistro ,
                                'variables': null ,
                                'foto': rptOffline.data64file ,
                                'etapatarea': rptOffline.etapatarea ,
                                'descripcion': rptOffline.descripcion ,
                                'bloqueadoPor': null,
                            };
                            dataFInal.push(itemNuevoOffline);
                        }                    
                   });      
                   apiActividades.datatable=dataFInal;
                }
                window.localStorage.setItem('actividades', JSON.stringify(apiActividades));
                
                
            }else{
                apiActividades = JSON.parse(window.localStorage.getItem('actividades'))
            }

            if(apiActividades!==null){
                setTitulo(apiActividades.tituloPantalla)
                setEstadoBotones(antes=>{
                    let old = []
                    apiActividades.listadoObjetosEstado.map(estado =>{
                        old[estado.id] = {visible: estado.visible, activo: estado.activo}
                    })
                    return old
                })
                setArbol(apiActividades.datatable)
            }else{
                setError(`No existen actividades en el dispositivo. Por favor intente de 
                          nuevo cuando cuente con acceso a internet`)
            }
            setCargando(false)
        }
        
        const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));
        let itemReportes=[];
        if (ejecOff!==undefined && ejecOff!==null){
            itemReportes = ejecOff.filter(x => x.sincronizado===false && x.tareaterminada===true);
            //if (itemReportes && itemReportes.length>0){                
                //setIdAbrirActividad(idAbrirActividad.concat(' ').trim);
                //window.alert('No es posible actualizar. Es necesario sincronizar los reportes offline');                                        
            //}                
        }
        obtieneDatos(itemReportes);
    },[actualizarDatos])

    useEffect(() => {
        const tareaSeleccionada = arbol.filter(e => e.id === idAbrirActividad);
        if (tareaSeleccionada.length > 0) {
            const {idtarea, idreporte,etapatarea,id,tiporegistro} = tareaSeleccionada[0];
            //if(!idtarea && !idreporte){
            //    console.log({idtarea, idreporte})
                //alert(JSON.stringify({idtarea, idreporte}))
            //    setIdAbrirActividad(null)
            //}
            
            window.localStorage.setItem('recSel', JSON.stringify(tareaSeleccionada));
            setIdAbrirActividad(id)
            setVerEjecutar(tiporegistro==='Reporte'?true:false)
           
            setApiActividadVariable(
            {
                idreporte:idreporte,
                idtarea:idtarea,
                etapatarea:etapatarea
            })

            setApiActividadTarea(
                {
                    idactividad:idAbrirActividad,
                    estadoactividad:etapatarea,
                    rows:[]
                })
        }else{
            localStorage.removeItem('recSel');
            localStorage.removeItem('tarSel');
        }
        return () => {}
    }, [idAbrirActividad])

    //if(cargando && sincronizando){
        //return (<SincronizacionReportes terminoSincronizacion={setSincronizando}/>);
    //}else 
    if(cargando){    
        return (<Cargando/>);
    }else if(error!==null){
            return (<div>
                        <div className="panel panel-default">
                            <div className="panel-heading text-center border"><b>Actividades 3w - {titulo}</b></div>
                            <div className="panel-body border">
                                <div className="alert alert-danger text-center m-2 "> {error}{titulo}</div>
                            </div>
                        </div>
                    </div>)
    }else{        
        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading text-center border">
                        <b>{titulo}</b>
                        <button className="btn btn-danger btn-sm m-0" 
                                style={{float:'right'}} 
                                onClick={()=>onCerrarActividades()}>Cerrar</button>                   
                    </div>
                    <div className="panel-body border">
                        {(estadoBotones.CmdActividadCampo.visible) && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                                !estadoBotones.CmdActividadCampo.activo}  
                                    onClick={() => onAbrirActividadCampo()}>
                                        <span><FaEdit className="tamanoImg" />
                                            <p className="tamanoLetraImg">Actividad Campo</p>
                                        </span>
                            </button>}
                        {estadoBotones.CmdCargaMasiva.visible && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                                !estadoBotones.CmdCargaMasiva.activo}  
                                    onClick={() => onAbrirCargaMasiva()}>
                                        <span><FaFileExcel className="tamanoImg" />
                                            <p className="tamanoLetraImg">Carga Masiva</p>
                                        </span>
                            </button>}
                        {estadoBotones.CmdProgramar.visible && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                                !estadoBotones.CmdProgramar.activo} 
                                    onClick={() => onAbrirTareas()}>
                                        <span><FaTasks className="tamanoImg" />
                                            <p className="tamanoLetraImg">Tareas</p>
                                        </span>
                            </button>}
                        {estadoBotones.CmdEjecutar.visible && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={!estadoBotones.CmdEjecutar.activo || idAbrirActividad === null || !verEjecutar} 
                                    onClick={() => onAbrirEjecutar()}>
                                        <span><FaCamera className="tamanoImg" />
                                            <p className="tamanoLetraImg">Ejecutar</p>
                                        </span>
                            </button>}
                        {estadoBotones.CmdConfirmar.visible && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                                !estadoBotones.CmdConfirmar.activo  || idAbrirActividad === null} 
                                    onClick={() => onAbrirActividad()}>
                                        <span><FaThumbsUp className="tamanoImg" />
                                            <p className="tamanoLetraImg">Programar</p>
                                        </span>
                            </button>}
                        {estadoBotones.CmdExportar.visible && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                                !estadoBotones.CmdExportar.activo}  
                                    onClick={() => onExportar()}>
                                        <span><FaFileExcel className="tamanoImg" />
                                            <p className="tamanoLetraImg">Exportar</p>
                                        </span>
                            </button>}
                        {estadoBotones.CmdAuditoria.visible && 
                            <button className="btn btn-light botonesSuperior" 
                                    disabled={JSON.parse(window.localStorage.getItem('offline')).modeOffline||
                                                !estadoBotones.CmdAuditoria.activo} 
                                    onClick={() => null}>
                                        <span><FaUserSecret className="tamanoImg" />
                                            <p className="tamanoLetraImg">Auditoria</p>
                                        </span>
                            </button>}
                        
                    </div>
                    <ArbolDatos arbol={arbol} setIdAbrirActividad={setIdAbrirActividad}/>
                    {showActividadCampo &&
                    <Actividad showActividadCampo={showActividadCampo} 
                            setShowActividadCampo={setShowActividadCampo}
                            titulo={titulo}
                            idAbrirActividad={idAbrirActividad}
                            setIdAbrirActividad={setIdAbrirActividad}
                            apiActividadVariable={apiActividadVariable}
                            apiActividadRecurso={apiActividadVariable}
                            esActividadCampo={modoActividadCampo}
                            />}

                    <EjecutarActividad showEjecutar={showEjecutar} idAbrirActividad={idAbrirActividad}
                        arbol={arbol} setShowEjecutar={setShowEjecutar}
                        setactualizarDatos={setactualizarDatos} actualizarDatos={actualizarDatos}
                        />
                    {showActividadTarea &&
                    <ViewTareas objsolicitudTareas={objActividadTarea}
                                apiActividadTareas={apiActividadTarea}
                                abirModal={showActividadTarea}
                                setAbirModal ={setShowActividadTarea}
                                setactualizarDatos={setactualizarDatos} actualizarDatos={actualizarDatos}
                                setactualizarDatosTareas={onAbrirTareas}
                    />}
                </div>
            </div>
        )
    }
    
}