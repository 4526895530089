import React from 'react'
import { DataGrid } from '@material-ui/data-grid';


const columns = [
  /* { field: 'id', headerName: 'ID', width: 90 }, */
  {
    field: 'recurso',
    headerName: 'RECURSOS',
    width: 135,
    editable: true,
  },
  {
    field: 'fecha',
    headerName: 'FECHA',
    width: 110,
    editable: true,
  },
  {
    field: 'cantidad',
    headerName: 'CANTIDAD',
    type: 'number',
    width: 128,
    editable: true,
  },

];


export const DatosRecursos = ({idreporte,idtarea,etapatarea, rows}) => {
  const [recursoSeleccionado, setSelection] = React.useState([]);
  let ejecOff = JSON.parse(window.localStorage.getItem('recursosOffline'));
  let response = {
                  "idreporte": idreporte,
                  "idtarea":idtarea,
                  "etapatarea":etapatarea,
                  "rows":[]};

  if (rows!==undefined && rows!==null && rows[0]!==undefined){
      response = {
        "idreporte": idreporte,
        "idtarea":idtarea,
        "etapatarea":etapatarea,
        "rows": rows.slice()
        };
    }
    if (ejecOff != null) {
    //let itemEtapa = ejecOff.filter(x => x.Idreporte === idreporte && x.Idtarea === idtarea && x.etapatarea === etapatarea)
    let itemEtapa = ejecOff.filter(x => x.Idreporte === idreporte && x.Idtarea === idtarea)
    if (itemEtapa !== undefined && itemEtapa !== null && itemEtapa.length > 0) {
        itemEtapa.forEach((item)=>{
            const itemRecurso ={
                "id": item.id,
                "idrecurso": item.idrecurso,
                "recurso": item.descripcion,
                "fecha": item.fecha,
                "fechaFinal": item.fechaFinal,
                "cantidad": item.cantidad
            }
            response.rows.push(itemRecurso);
        });
        
    }
}
    return (
        <div style={{ height: 380 }}>
            <DataGrid
              
                rows={response.rows}
                columns={columns}
                pageSize={7}
                checkboxSelection={true}
                disableSelectionOnClick={true}
                density='compact'
                onRowClick={(e)=>console.log(e.row)}
                onSelectionChange={(newSelection) => {
                  setSelection(newSelection.rows) ;
                  console.log(newSelection) ;
                 
                }}
                autoPageSize={true}
                scrollbarSize={10}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  ); 
                  console.log(selectedRowData.length===1?selectedRowData:selectedRowData.slice(0,1));
                  setSelection(selectedRowData.length===1?selectedRowData:selectedRowData.slice(0,1));
                  if (selectedRowData.length===0){
                    window.localStorage.setItem('recursoSel', null);
                  }
                  else{
                    window.localStorage.setItem('recursoSel', JSON.stringify(selectedRowData.length===1?selectedRowData:selectedRowData.slice(0,1)));
                  }
                 
                }}
            />
        </div>

    )
}
