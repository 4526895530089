import React, { useState, useEffect } from 'react'

export const ViewVariables = (props) => {
    const variables = props.apiActividadVariable;
    const modoActividadCampo= props.esActividadCampo!== undefined && props.esActividadCampo!==null ? props.esActividadCampo : false;
    const [stateVariablesCumplidas, setStateVariablesCumplidas] = useState(
        modoActividadCampo===false || props.variablesActCampo===undefined || props.variablesActCampo===null ?
        {
            titlePag:'',
            formulario:{
                personas: true,
                HSE: true,
                Documentos: true,
                Calidad: true,
                Area: true,
                Materiales:true,
                Herramientas: true,
                Equipos: true,
                observations:'',
            }
        } :
        {
            titlePag:'',
            formulario:{
                personas: props.variablesActCampo.personas,
                HSE: props.variablesActCampo.hse,
                Documentos: props.variablesActCampo.documentos,
                Calidad: props.variablesActCampo.calidad,
                Area: props.variablesActCampo.area,
                Materiales:props.variablesActCampo.materiales,
                Herramientas: props.variablesActCampo.herramientas,
                Equipos: props.variablesActCampo.equipos,
                observations:props.variablesActCampo.observaciones,
            }
        }
    );
    const {formulario} = stateVariablesCumplidas;
    const {personas, HSE, Documentos, Calidad, Area, Materiales, Herramientas, Equipos} = formulario;

    const updateChecksFormulario = (target) => {
        if (target.name==='observations'){
        setStateVariablesCumplidas(
            {
                ...stateVariablesCumplidas,
                formulario: {
                    ...formulario,
                    [target.name]: target.value,
                } 
            });
        }else{
            const varTemp='\r\n'.concat(target.name ,':');
            setStateVariablesCumplidas(
                {
                    ...stateVariablesCumplidas,
                    formulario: {
                        ...formulario,
                        [target.name]: target.checked,
                        observations: target.checked===false 
                                      ? formulario.observations!==null && formulario.observations.indexOf(varTemp)<0 ?formulario.observations.concat(varTemp): varTemp 
                                      : target.checked && formulario.observations!==null ? formulario.observations.replace(varTemp,''): formulario.observations ,
                    } 
                });
        }
    }
    
    const titleView = () => {
        if(variables!==null)
            return (personas && HSE && Documentos && Calidad && Area && Materiales && Herramientas && Equipos)
                ? variables.lblobjeto3
                : variables.lblobjeto3no
        else
            return ''
    }
    const observationsPlaceHolder = () => {
        return (personas && HSE && Documentos && Calidad && Area && Materiales && Herramientas && Equipos)
        ? `Observación de cumplimiento de variables`
        : `Observación de no cumplimiento de variable(s)`
        
    }
    const textObservaciones = () => {
        if(variables!==null)
            return (personas && HSE && Documentos && Calidad && Area && Materiales && Herramientas && Equipos)
                ? variables.lblobjeto5
                : variables.lblobjeto5no
        else
            return ''
    }

    useEffect(() => {
        setStateVariablesCumplidas({
            ...stateVariablesCumplidas,
            formulario:{
                personas: (variables!==null)?variables.personas:true,
                HSE: (variables!==null)?variables.hse:true,
                Documentos: (variables!==null)?variables.documentos:true,
                Calidad: (variables!==null)?variables.calidad:true,
                Area: (variables!==null)?variables.area:true,
                Materiales:(variables!==null)?variables.materiales:true,
                Herramientas: (variables!==null)?variables.herramientas:true,
                Equipos: (variables!==null)?variables.equipos:true,
                observations:(variables!==null)?variables.observacion:null,
            }
        })
    },[props.apiActividadVariable])

    
    return (
        <div >
            <p style={{height:'70px'}}>{titleView()}</p>
            <div style={{display:'flex', justifyContent:'space-around', marginTop:'15px', marginBottom:'15px'}}>
                <div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="personas" style={{color:personas?'green':'red', fontWeight:'bold'}}>Personas</label>
                        <input checked={personas}  onChange={({target})=>updateChecksFormulario(target)} id='varPersonas' name='personas'  className="form-check-input" type="checkbox"/>
                    </div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="HSE" style={{color:HSE?'green':'red', fontWeight:'bold'}}>HSE</label>
                        <input checked={HSE} onChange={({target})=>updateChecksFormulario(target)} id='varHSE' name="HSE" className="form-check-input" type="checkbox"/>
                    </div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="Documentos" style={{color:Documentos?'green':'red', fontWeight:'bold'}}>Documentos</label>
                        <input checked={Documentos} onChange={({target})=>updateChecksFormulario(target)} id='varDocumentos' name="Documentos" className="form-check-input" type="checkbox"/>
                    </div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="Calidad" style={{color:Calidad?'green':'red', fontWeight:'bold'}}>Calidad</label>
                        <input checked={Calidad} onChange={({target})=>updateChecksFormulario(target)} id='varCalidad'name="Calidad" className="form-check-input" type="checkbox"/>
                    </div>
                </div>
                <div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="Area" style={{color:Area?'green':'red', fontWeight:'bold'}}>Área</label>
                        <input checked={Area} onChange={({target})=>updateChecksFormulario(target)} id='varArea' name="Area" className="form-check-input" type="checkbox"/>
                    </div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="Materiales" style={{color:Materiales?'green':'red', fontWeight:'bold'}}>Materiales</label>
                        <input checked={Materiales} onChange={({target})=>updateChecksFormulario(target)} id='varMateriales' name="Materiales" className="form-check-input" type="checkbox"/>
                    </div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="Herramientas" style={{color:Herramientas?'green':'red', fontWeight:'bold'}}>Herramientas</label>
                        <input checked={Herramientas} onChange={({target})=>updateChecksFormulario(target)} id='varHerramientas' name="Herramientas" className="form-check-input" type="checkbox"/>
                    </div>
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="Equipos" style={{color:Equipos?'green':'red', fontWeight:'bold'}}>Equipos</label>
                        <input checked={Equipos} onChange={({target})=>updateChecksFormulario(target)} id='varEquipos' name="Equipos" className="form-check-input" type="checkbox"/>
                    </div>
                </div>
            </div>
            <p>{textObservaciones()}</p>
            <div className="input-group">
                <textarea onChange={({target})=>updateChecksFormulario(target)} id="varObservations" name="observations" className="form-control" aria-label="observations" 
                   value={formulario.observations} placeholder={observationsPlaceHolder()} style={{height: "100px"}}></textarea>
            </div>
        </div>
    )
}
