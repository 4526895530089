import React from 'react'
import {VscSyncIgnored, VscSync} from 'react-icons/vsc'
import './estilos/btnOnOffSync.css'

//Cada que cambia offLine de principal es llamado este metodo
//No se utiliza la variable porque se ejecuta varias veces y no trae un valor estable
//Mas sin embargo sirve como marcador de que ha ocurrido un cambio de
export const btnOnSync = (validarSincronizar) => {
    const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));      
    if (ejecOff!==undefined && ejecOff !== null && ejecOff.length>0) {
        const reportesPendientes = ejecOff.filter(x => x.tareaterminada===true);
        if (reportesPendientes!==undefined && reportesPendientes !== null && reportesPendientes.length>0) {
            return (<div><VscSync id="circuloOnSync"  onClick={validarSincronizar}/></div>);
        }
    }         
    return (<div><VscSyncIgnored id="circuloOffSync"/></div>);
    
}