import {useState} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { FaSearch, FaTrash, FaSave} from 'react-icons/fa'

export const CambiarImagen = (props) => {

    const [img,setImag] = useState(null)
    const [nombreImagen, setNombreImagen] = useState(null)

    const onBtnAceptar = () => {
         let preview = document.getElementById(props.cambiarImagen.idImagenCambiar)
         preview.src =  img
         props.setCambiarImagen({...props.cambiarImagen, show:false, nombre:nombreImagen})
         setImag(null)
         setNombreImagen(null)
    }

    const onBtnCancelar = () => {
        setImag(null)
        setNombreImagen(null)
        props.setCambiarImagen({...props.cambiarImagen, show:false, nombre: null})
    }

    const onSelectImagen = (e) => {
         const archivo = document.getElementById('fileCargado').files
         setNombreImagen(archivo[0].name)
         setImag(URL.createObjectURL(archivo[0]))
    }

    const onCambiarImagen = () =>{
        document.getElementById('fileCargado').click()
    }

    return <SweetAlert
                show={props.cambiarImagen.show}
                title={'Cambiar Imagen'}
                onCancel={()=>onBtnCancelar()}
                showCancel={true}
                showConfirm={false}
                cancelBtnText={"Cancelar"}
                closeOnClickOutside={false}
                showCloseButton={true}>
                    <div>
                        <button className="btn btn-success btn-sm" onClick={()=>onCambiarImagen()}><FaSearch/> Buscar Imagen</button>
                        <br/>
                        <img id="imgCargaPrevia"  className="p-5" src={img} alt="" style={{width:'100%',height:'100%'}}></img>
                        <br/>
                        <input id='fileCargado' type="file" accept=".png" onChange={onSelectImagen} hidden/>
                        <br/>
                        <button className="btn btn-primary mx-3" onClick={()=>onBtnAceptar()}><FaSave/> Cambiar</button>
                    </div>
                </SweetAlert>
}



export const getBase64Image = (img) =>{
    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  
  
export const resizeBase64Img = (base64, newWidth, newHeight, nivel) => {
    return new Promise((resolve, reject)=>{
        var canvas = document.createElement("canvas");
		canvas.width=newWidth;
		canvas.height = newHeight;
        //canvas.style.width = newWidth.toString()+"px";
        //canvas.style.height = newHeight.toString()+"px";
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {
            context.scale(newWidth/img.width,  newHeight/img.height);
            context.drawImage(img, 0, 0); 
			console.log(nivel);
            resolve(canvas.toDataURL("image/jpeg",nivel));               
        }
    });
}

export const getBase64CompressImage = (img) => {
	 var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/jpeg", 0.7);
    return dataURL.replace(/^data:image\/(png|jpeg);base64,/, "");
    /*const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    canvas.toBlob(blob, MIME_TYPE, QUALITY);
	
    /*canvas.toBlob(
        (blob) => {
            // Handle the compressed image. es. upload or save in local state
            displayInfo('Original file', file);
            displayInfo('Compressed file', blob);
        },
        MIME_TYPE,
        QUALITY
    );
    //var dataURL = canvas.toDataURL(MIME_TYPE);
	var dataURL =canvas.toDataURL(MIME_TYPE,QUALITY)
	//var dataURL = window.URL.createObjectURL(blob);
	*/
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}