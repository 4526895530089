import React, { useState } from 'react'
import { BsSearch, BsPlusSquare, BsPencilSquare } from "react-icons/bs";
import { IconContext } from "react-icons";
import { DatosRecursos } from '../../../componentes/Actividades/DatosRecursos';
import { PopupRecurso } from '../../../componentes/Actividades/PopupRecurso';




export const ViewRecursos = ({objsolicitudRecursos , apiActividadRecursos, esProgramacion, idReporteEjecucion,setActualizarRecursos,esActividad,origenPantalla, esActividadCampo=false,recursosActCampo, setRecursosActCampo}) => {
    const [openModalForm, setOpenModalForm] = useState(false);
    const [nuevoRegistroForm, setnuevoRegistroForm] = useState(false);    
    const modoActividadCampo= esActividadCampo!== undefined && esActividadCampo!==null ? esActividadCampo : false;
    const fechaInicial = objsolicitudRecursos.fechai!==undefined && objsolicitudRecursos.fechai===null ?  
                        apiActividadRecursos!==undefined && apiActividadRecursos.apiRecursos!==undefined && apiActividadRecursos.apiRecursos.fechainicial!== undefined ?  apiActividadRecursos.apiRecursos.fechainicial : null
                    : objsolicitudRecursos.fechai
    const fechaFinal = objsolicitudRecursos.fechaf!==undefined && objsolicitudRecursos.fechaf===null ? 
                    apiActividadRecursos!==undefined && apiActividadRecursos.apiRecursos!==undefined && apiActividadRecursos.apiRecursos.fechafinal!==undefined ? apiActividadRecursos.apiRecursos.fechafinal :null
                    : objsolicitudRecursos.fechaf    
    
    let objRecursos = 
        {
            "idreporte": objsolicitudRecursos.idreporte,
            "idtarea" : objsolicitudRecursos.idtarea,
            "etapatarea": objsolicitudRecursos.etapatarea,
            "origen" : esProgramacion ? "Programación" : "Ejecución",
            rows: apiActividadRecursos !== null && apiActividadRecursos !== undefined &&
                  apiActividadRecursos.datos !== undefined ? apiActividadRecursos.datos :
                  objsolicitudRecursos !== undefined && objsolicitudRecursos !== null &&
                  objsolicitudRecursos.datos !== undefined ? objsolicitudRecursos.datos:
                  [] ,
        };
    //const [rowsForm, setRowsForm] = useState(objRecursos.rows);
    const handleinputsChange = (target) => {
        console.log(target.name, target.value);
    }

    const agregar = () => {
        setnuevoRegistroForm(true)
        setOpenModalForm(true)
    }
    const editar = () => {
        setnuevoRegistroForm(false)
        setOpenModalForm(true); 
    }
    
    
    return (
        <div>
            <div style={estilos.fila}>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1"><BsSearch/></span>
                    <input onChange={({target})=>handleinputsChange(target)} name="search" type="text" className="form-control" aria-label="Buscar" 
                        aria-describedby="basic-addon1"/>
                </div>
                
                <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'2em' }}>
                    <div style={{...estilos.fila, marginBottom:'1rem', marginLeft:'10px'}}>
                        <BsPlusSquare onClick={()=>agregar()} name="Agregar" className="cursor" title='Agregar' style={estilos.estiloIcono}/>
                        <BsPencilSquare onClick={()=>editar()} className="cursor" title='Editar' style={estilos.estiloIcono}/>                        
                    </div>
                </IconContext.Provider>
            </div>
            <div style={{overflow: 'scroll'}}>
                <DatosRecursos idreporte={objRecursos.idreporte} 
                               idtarea={objRecursos.idreporte} 
                               etapatarea={objRecursos.etapatarea} 
                               rows= {objRecursos.rows} 
                               />
            </div>
            <PopupRecurso openModal={openModalForm} 
                          setOpenModal={setOpenModalForm}
                          nuevoRegistro={nuevoRegistroForm}
                          idTareaRecursos={objRecursos.idtarea}
                          esProgramacion= {esProgramacion}
                          idReporteEjecucion={idReporteEjecucion}
                          fechaInicial = {fechaInicial}
                          fechaFinal = {fechaFinal}                          
                          setActualizarRecursos={setActualizarRecursos}         
                          esActividad= {esActividad}
                          origenPantalla={origenPantalla}
                          esActividadCampo={modoActividadCampo}
                          recursosActCampo ={recursosActCampo}
                          setRecursosActCampo={setRecursosActCampo}
                          />
            
        </div>
    )
}


const estilos = {
    fila:{display:'flex', justifyContent:'space-between', alignItems:'center'},
    estiloIcono: {marginLeft:'5px', marginRight:'5px'}
}