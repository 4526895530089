import React from 'react'
import { DataGrid } from '@material-ui/data-grid';


const columns = [
  /* { field: 'id', headerName: 'ID', width: 90 }, */
  /*{
    field: 'idTarea',
    headerName: 'idTarea',
    width: 0,
    editable: false,
    visible:false,
  },*/
  {
    field: 'estado',
    headerName: 'Estado',
    width: 30,
    editable: false,
  },
  {
    field: 'tarea',
    headerName: 'Descripción',
    width: 180,
    editable: false,
  },
  {
    field: 'unidad',
    headerName: 'UND',
    //type: 'number',
    width: 30,
    editable: false,
  },
  {
    field: 'cantidad',
    headerName: 'CANTIDAD',
    //type: 'number',
    width: 40,
    editable: false,
  },
];


export const DatosTarea = ({idActividad, EstadoActividad,rows, setItemSeleccionado}) => {
  const [tareaSeleccionada, setSelection] = React.useState([]);
  let response = {
                  "idactividad": idActividad,
                  "estadoactividad":EstadoActividad,
                  "rows":[]};

  if (rows!==undefined && rows!==null && rows[0]!==undefined){
      response = {
        "idactividad": idActividad,
        "estadoactividad":EstadoActividad,
        "rows": rows.slice()
      }
  }
    

    return (
        <div style={{ height: 380 }}>
            <DataGrid
                rows={response.rows}
                columns={columns}
                pageSize={7}
                checkboxSelection={true}
                disableSelectionOnClick={true}
                density='compact'
                onRowClick={(e)=>console.log(e.row)}
                onSelectionChange={(newSelection) => {
                  setSelection(newSelection.rows) ;
                  console.log(newSelection) ;
                  
                }}
                autoPageSize={true}
                scrollbarSize={10}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  console.log(selectedRowData);
                  setSelection(selectedRowData);
                  if (selectedRowData.length!==1){
                    window.localStorage.setItem('tarSel', null);
                    setItemSeleccionado(null)
                  }
                  else{
                    window.localStorage.setItem('tarSel', JSON.stringify(selectedRowData));
                   
                    setItemSeleccionado(selectedRowData[0])
                  }
                 
                }}
            />
        </div>

    )
}
