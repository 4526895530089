import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import Button from '@material-ui/core/Button';
import { ViewVariables } from './views/ViewVariables';
import '../../componentes/estilos/actividades.css'
import { GetUnaActividad, GetConsultarEtapaTarea, ModificaVariablesEtapaTarea } from '../../componentes/ConsumirApiRest'
import { GuardaVariablesEtapaTarea, CargarObjVariablesEjeEtapaTarea } from '../../componentes/Ejecucion/ejecucionOffline'
import { fechaUTC } from '../../componentes/controlFechas'

export const Variables = (props) => {
    const [stateActividades, setStateActividades] = useState({step:0,titlePage: 'Actividad 3W - { Estado }'});
    const {step, titlePage} = stateActividades;
    const [apiActividad, setApiActividad] = useState(null)    
    const [apiActividadVariable, setApiActividadVariable] = useState(null)    
    const updateStateActividades = (variable, valor) => setStateActividades({...stateActividades, [variable]:valor})
    const cerrar = () => props.setShowActividadCampo(false)

    const GuardarReporteVariable = async () => {
        console.log(apiActividadVariable);  
        if (props.origen !== undefined && props.origen !== null){
            const SyncVariablesTareaRequest={
                id : apiActividadVariable.id,
                idtarea : apiActividadVariable.idtarea,
                etapatarea: props.etapatarea,
                personas: document.getElementById('varPersonas').checked,
                hse: document.getElementById('varHSE').checked,
                documentos: document.getElementById('varDocumentos').checked,
                calidad: document.getElementById('varCalidad').checked,
                area: document.getElementById('varArea').checked,
                materiales: document.getElementById('varMateriales').checked,
                herramientas: document.getElementById('varHerramientas').checked,
                equipos: document.getElementById('varEquipos').checked,
                observacion: document.getElementById('varObservations').value
            }        
            const respuesta = await ModificaVariablesEtapaTarea(SyncVariablesTareaRequest)
        }
        else{
            const variablesEjecucionDto={
                idreporte : apiActividadVariable.id,
                idtarea : apiActividadVariable.idtarea,
                etapatarea: props.etapatarea,
                personas: document.getElementById('varPersonas').checked,
                hse: document.getElementById('varHSE').checked,
                documentos: document.getElementById('varDocumentos').checked,
                calidad: document.getElementById('varCalidad').checked,
                area: document.getElementById('varArea').checked,
                materiales: document.getElementById('varMateriales').checked,
                herramientas: document.getElementById('varHerramientas').checked,
                equipos: document.getElementById('varEquipos').checked,
                observacion: document.getElementById('varObservations').value
            };        
            GuardaVariablesEtapaTarea(variablesEjecucionDto)
        }
       
        cerrar()
    }

    useEffect(() => {
        const getActividad = async () => {
            let datos = null
            console.log(props.stepInicio);
            let tempstep =  0 ;
            if (props.stepInicio!==undefined && props.stepInicio!== null){
                tempstep= props.stepInicio;

            }
            if(tempstep === 2){
                if(props.idAbrirActividad !== undefined && props.idAbrirActividad != null && 
                    props.idreporte !== undefined && props.idreporte != null && 
                    props.etapatarea !== undefined && props.etapatarea != null){
                    datos= await CargarObjVariablesEjeEtapaTarea(props.idAbrirActividad, props.idreporte, props.etapatarea)
                }
                else{
                    datos = await GetUnaActividad(props.idreporte, null,()=>console.log,'variable')
                }
                
                setApiActividadVariable(datos)
            }
            else if (tempstep === 3){
                const itemSeleccionado = JSON.parse(window.localStorage.getItem('tarSel'));
                const usuario = JSON.parse(window.localStorage.getItem('logearUsuario')).perfilUsuario.nombreCompleto;
                const date =fechaUTC();
                const options = {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                };
                let { id, idReporte,estado, tarea} = itemSeleccionado[0];
                datos = await GetConsultarEtapaTarea(id, idReporte,estado)  
                datos.variables.lblobjeto3 = "Hoy " + date.toLocaleDateString('es-CO', options) + " yo " + usuario + " confirmo que las siguientes variables están cumplidas en sú totalidad:"
                datos.variables.lblobjeto3no = "Hoy "+ date.toLocaleDateString('es-CO', options) + " yo " + usuario + " confirmo que la tarea " + tarea +  ",no puede ser ejecutada por falta de:"
                datos.variables.lblobjeto5 = "Por tanto; la tarea " + tarea + ", puede ser ejecutada."
                datos.variables.lblobjeto5no = "Por las siguientes razones:"
                datos.variables.id = idReporte      
                datos.variables.idtarea = id
                datos.variables.tituloPantalla = "Programación de Variables en " + estado + " de Tarea"
                setApiActividadVariable(datos.variables)
            }
            else {
                updateStateActividades('titlePage', '');
            }

            if(tempstep !== 1&&datos!==null){
                updateStateActividades('titlePage',
                    <div> {datos.titulopantalla||datos.tituloPantalla}
                        <button onClick={cerrar} type="button" className="btn btn-outline-danger btn-sm" style={{position:'absolute', right:'10px'}}>
                            Cerrar
                        </button>
                    </div> );
            }
        }
        getActividad()
    }, [props.showActividadCampo])

    
    return (
        <SweetAlert
            show={props.showActividadCampo}
            title={titlePage}
            onConfirm={()=>null}
            showCancel={false}
            showConfirm={false}
            confirmBtnText={"Guardar"}
            closeOnClickOutside={false}
            showCloseButton={true}>
            <div style={{backgroundColor:'aliceblue', borderRadius:'1%'}} className="border">                                
                { (props.stepInicio === 2 || props.stepInicio === 3 )   && <ViewVariables apiActividadVariable={apiActividadVariable}/> }                 
            </div>
            <div>                        
            <Button  variant="contained" color="primary" onClick={()=>GuardarReporteVariable()}>
                Guardar
            </Button>
          </div>
        </SweetAlert>
    )
}
