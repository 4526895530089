import React, { useEffect, useState } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert'
import { VariablesEjeEtapaTarea, CargarRecursosTarea } from '../../componentes/Ejecucion/ejecucionOffline'
import { SincronizarEtapaTarea } from '../../componentes/ConsumirApiRest'


export const SincronizacionReportes= (props) => {    
    const [itemActual, setItemActual] = useState(0);
    const [itemFinal, setItemFinal] = useState(-1);
    const [ejecutando, setEjecutanto] = useState(false);
    const [msgError, setMsgError]= useState(null);
    const getLabel=()=>{
        return ('Sincronizando reporte '.concat(itemActual<0 ? 0 : itemActual,' de ',itemFinal));
    }
    
    const onClickSi=()=>{       
        setMsgError(null);
        
        const enviarItem = async(item) =>{
            setEjecutanto(true);        
            if (item !== undefined && item !== null &&
                item.data64file !== undefined && item.data64file !== null && 
                item.tareaterminada === true && item.sincronizado===false) {         
                const { usuario } =  item;                    
                const variablesTarea = VariablesEjeEtapaTarea(item.idtarea, item.idreporte, item.etapatarea);                                  
                const recursostarea = CargarRecursosTarea(item.idtarea, item.idreporte);
                const terminaEtapaRequest = {
                    idreporte: '00000000-0000-0000-0000-000000000000',//idreporte: item.idreporte,
                    idtarea: item.idtarea,
                    idnotificacion: '',
                    etapatarea: item.etapatarea,
                    fechaconcepto: item.fechaconcepto,
                    cantidadreportada: item.cantidadreportada,
                    observacion: item.observacion,
                    fechafoto: item.fechafoto,
                    filename: item.filename,
                    data64file: item.data64file,
                    data64filetype:item.data64filetype,
                    tareaterminada: item.tareaterminada,
                    descripcion: null,
                    variables : variablesTarea,
                    recursos:recursostarea,
                    sincronizado:false
                };

                // enviar api                                     
                
                console.log('Enviando reporte: ',itemActual+1,', ',item); 
                setItemActual(itemActual+1);                                       
                const response = await SincronizarEtapaTarea(usuario.token, terminaEtapaRequest,item.idreporte);                                                                                                                                          
                if (response !== undefined && response.hasOwnProperty('errores') && response.errores.length>0 ) {
                    let mensajeError = 'Se presentó error(es) al sincronizar';
                    response.errores.forEach(registro=>{
                        mensajeError= mensajeError.concat('\n','Reporte:',itemActual<=0 ? 1 : itemActual,'. Error:',registro.mensaje);
                    })
                    item.sincronizado=false;                    
                    setMsgError(mensajeError);
                    console.log(response);     
                    setEjecutanto(false); 
                    setItemActual(itemActual-1);                 
                }else{                      
                    if (response!==undefined && response!==null && response.hasOwnProperty('idnuevoreporte')){
                        console.log("se realizó con éxito la sincronización del reporte ", itemActual);                      
                    }else{
                        setItemActual(itemActual-1); 
                    }
                    setEjecutanto(false);
                }

                  
            }
        };

        const SincronizaReporteEtapaTarea = () => {            
            const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));  
            setItemActual(0);           
            if  (ejecOff!==undefined && ejecOff !== null && ejecOff.length>0) {                                                 
                ejecOff.forEach((item)=>{ 
                    setTimeout(enviarItem(item),3000);                                    
                });                
            }                            
        };  

        SincronizaReporteEtapaTarea();                
               
    }

    useEffect(() => {
        const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));   
        let cantidadReportes=0;                   
        if (ejecOff!==undefined && ejecOff !== null && ejecOff.length>0) {                                          
            ejecOff.forEach((item)=>{
                if (item !== undefined && item !== null &&
                    item.data64file !== undefined && item.data64file !== null && 
                    item.tareaterminada === true && item.sincronizado===false) {                                                           
                        cantidadReportes+=1;
                }
            });            
        }
        if (cantidadReportes>0){
            setItemFinal(cantidadReportes);
        }else{
            console.log('No existen reportes que sincronizar');
            props.setMostrarSync(false);
        }
    }, []);

    //cierra cuando termina la sincronización
    useEffect(() => {
       if (itemActual===itemFinal)
            props.setMostrarSync(false);
    }, [itemActual]);


    //if (ejecutando){
    //    return (<div> <h1>{getLabel()}</h1> <Cargando></Cargando></div>);
    //}else{
        return (<SweetAlert
            show={true}
            title={"Sincronización de reportes"}
            onConfirm={onClickSi}
            onCancel={ ()=> props.setMostrarSync(false)}
            cancelBtnText={"Cancelar"}
            showCancel={true}
            showConfirm={(ejecutando ||  itemActual === itemFinal) ? false  : true}
            confirmBtnText={"Syncronizar"}
            closeOnClickOutside={false}
            showCloseButton={false}>
            <div style={{backgroundColor:'aliceblue', borderRadius:'1%'}} className="border">
                <div style={{marginBottom:'15px', marginTop:'15px'}}>
                    <p style={{fontSize:'small'}}>{getLabel()}</p>
                </div> 
                {msgError!==null && <div className='alert alert-warning' style={{marginBottom:'15px', marginTop:'15px'}}>
                    <p style={{fontSize:'small'}}>{msgError}</p>
                </div>}               
            </div>
        </SweetAlert>);
    //}
    
}

