import { FaBullseye } from 'react-icons/fa';
import { CargarObjEjecucionEtapaTarea, EliminaEjecucionEtapaTarea, GuardaEjecucionEtapaTarea, CargarObjVariablesEjeEtapaTarea, GuardaVariablesEtapaTarea, CargarObjRecursosEjeEtapaTarea, EliminaRecursosTarea, SetOffLine, GuardaRecursosEtapaTarea, GeneraRespuestaNuevaActividadCampo } from '../componentes/Ejecucion/ejecucionOffline'
import { urlBase } from '../componentes/datosApiRest'

const configFecha = {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12:"false"};


//Consumo de Api para la principal

export const CerrarSesion = async() => {
    const url = urlBase + '/api/Home'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        }
    }
    const remover = () => {
        localStorage.removeItem('offline')
        localStorage.removeItem('logearUsuario')
        localStorage.removeItem('tablaMaestra')
        localStorage.removeItem('actividades')
        localStorage.removeItem('idReporteSync')
        localStorage.removeItem('maestrasUsuario')
        localStorage.removeItem('recSel')
        localStorage.removeItem('tarSel')
        localStorage.removeItem('ejecucionOffline')
        localStorage.removeItem('recursoSel') 
        localStorage.removeItem('recursosOffline') 
        localStorage.removeItem('variablesOffline')
        localStorage.removeItem('usuarioSesion') 
    }

    await fetch(url, requestOptions)
        .then(respuesta => {
            if (respuesta.ok) {
                remover()
                window.location.reload()
            }
        }).catch(error => {
            remover()
            window.location.reload()
        })
}

export const ObtenerPerfil = async(onCambiarOffline) => {
    const url = urlBase + '/api/Home'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => {            
            onCambiarOffline(true, error);
        })

    if (retorno !== undefined && retorno.status === 200) {
        onCambiarOffline(false, null)
        let datos = retorno.json()
        .then(respObj => {
            window.localStorage.setItem('maestrasUsuario', JSON.stringify({ MaestrasUsuario: respObj.maestrasUsuario }))
            return respObj;
        })
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        window.localStorage.setItem('logearUsuario', JSON.stringify({...usuario, perfilUsuario: await datos }))
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        //onCambiarOffline(true, retorno.status)
    }

}

export const Notificaciones = async(props) => {
    const url = urlBase + '/api/Notificacion'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => {
            props.onCambiarOffline(true, error);
        })

    if (retorno !== undefined && retorno.status === 200) {
        props.onCambiarOffline(false, null);
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }));
        let datos = await retorno.json();
        if (datos && datos!==null && datos.length>0){
            const usuario =  JSON.parse(window.localStorage.getItem('logearUsuario'));
            window.localStorage.setItem('logearUsuario', JSON.stringify({...usuario, notificacion: datos}))
        }
        return datos;
    } else {
        let datos =JSON.parse(await retorno.text().then(dato=>{
            return dato;
        }));
        
        return [{
            idNotificacion : '00000000-0000-4dbe-8cc9-e1cc0665f598',
            tipo: 'I',
            detalle: datos.mensaje,
            fechaMov: new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
            fechaRecibido: new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
            funcionalidad: null,
            urlNotificacion: 'api/Programacion',
            urlMetodo: 'POST',
            funcionalidadJson: null
          },];
        //props.onCambiarOffline(true,datos.mensaje);
    }
}

export const confirmarCerrarNotificacion = async(nota, props) => {
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const url = urlBase + '/api/Notificacion'
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`
        },
        body: JSON.stringify(nota)
    };

    const retorno = await fetch(url, requestOptions)
        .catch(error => {
            props.onCambiarOffline(true, error)
        })

    if (retorno !== undefined && retorno.status === 200) {
        window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
        props.onCambiarOffline(false, null)
        const datos = await retorno.json();
        return (datos === true);
    } else if (retorno !== undefined && retorno.status !== 200) {
        return false;
    }

    
}

//Consumo de Api para las Maestras

export const GetDatosMaestra = async(props, onCambiarOffline) => {
    const url = props.configuracion.microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { 
            onCambiarOffline(true, error);
            SetOffLine(true);
        })

    if (retorno !== undefined && retorno.status === 200) {
        SetOffLine(false);
        onCambiarOffline(false, null);
        let datos = retorno.json();
        return datos;
    } else if (retorno === undefined ) {
        SetOffLine(true);
    }
    return false
}

export const GuardarMaestra = async(datos, onCambiarOffline, setCrudEjecutadoBien, microServicio, datosImagen) => {
    const url = microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }


    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        const elId = await retorno.text().then(valor => { return valor })
        if (datosImagen !== null) {
            datosImagen.id = elId
            await guardarImagen(datosImagen)
        }
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}


export const EliminarMaestra = async(datos, onCambiarOffline, setCrudEjecutadoBien, microServicio) => {
    const url = microServicio
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}


const guardarImagen = async(imagen64) => {
    const url = urlBase + '/api/Archivos'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))

    const datos = {
        partitionKey: imagen64.pantalla,
        id: imagen64.id,
        fileName: imagen64.filename,
        fileCab: "",
        data64File: imagen64.data64File
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    }

    await fetch(url, requestOptions).catch(error => {})
}

//Tareas Masivo
export const GuardarCargaMasivaTarea = async(datos, onCambiarOffline, setCrudEjecutadoBien) => {
    const url = urlBase + '/api/Masivos/Importar/Tareas'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    };

    const retorno = await fetch(url, requestOptions)
        .catch(error => { 
            onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        const elText = await retorno.text().then(valor => { return valor })
        setCrudEjecutadoBien({
            titulo: 'Proceso exitoso',
            mensaje: elText,
            mostrar: true,
            tipoMensaje: 'success'
        })
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(res => {   
            if (res.status===400){
                let respError = res.errors;
                let mensaje=[{linea:'0',mensaje:String(JSON.stringify(respError))}];
                setCrudEjecutadoBien({
                    titulo: 'Error',
                    mensaje: mensaje,
                    mostrar: true,
                    tipoMensaje: 'warning'
                });
            }
            else{
                setCrudEjecutadoBien({
                    titulo: 'Error',
                    mensaje: res,
                    mostrar: true,
                    tipoMensaje: 'warning'
                });
            }            
        });
    }
    return false
}

//Actividades
export const GuardarCargaMasiva = async(datos, onCambiarOffline, setCrudEjecutadoBien) => {
    const url = urlBase + '/api/Masivos/Importar/Actividades'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
    };

    const retorno = await fetch(url, requestOptions)
        .catch(error => { onCambiarOffline(true, error) })

    if (retorno !== undefined && retorno.status === 200) {
        const elText = await retorno.text().then(valor => { return valor })
        setCrudEjecutadoBien({
            titulo: 'Proceso exitoso',
            mensaje: elText,
            mostrar: true,
            tipoMensaje: 'success'
        })
        return true
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(res => {
            setCrudEjecutadoBien({
                titulo: 'Error',
                mensaje: res,
                mostrar: true,
                tipoMensaje: 'warning'
            })
        })
    }
    return false
}

export const GetUnaActividad = async(idActividad, idNotificacion, onCambiarOffline, linkDe, esActividadCampo=false) => {
    if (esActividadCampo===false){ 
        let url = urlBase + '/api/Programacion'
        if (linkDe === 'variable') {
            url = urlBase + '/api/Programacion/Variables'
        }

        const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usuario.token}`,
                'Georef': usuario.ubicacion
            },
            body: JSON.stringify({ idactividad: idActividad, idnotificacion: idNotificacion })
        }

        const retorno = await fetch(url, requestOptions)
            .catch(error => { onCambiarOffline(true, error) })
        if (retorno !== undefined && retorno.status === 200) {
            window.localStorage.setItem('offline', JSON.stringify({ modeOffline: false }))
            onCambiarOffline(false, null)
            let datos = retorno.json()
            return datos
        } else if (retorno !== undefined && retorno.status !== 200) {
            //onCambiarOffline(true, retorno.status)
        }
        return false
    }else{       
        if (linkDe === 'variable') {
            let objOffline = await CargarObjVariablesEjeEtapaTarea(idActividad, idActividad, 'Inicio');
            objOffline.idactividad=idActividad;
            return objOffline;
        }else{
            const datos=GeneraRespuestaNuevaActividadCampo(idActividad);
            return datos;
        }        
    }
}

export const GuardarUnaActividad = async(datos, onCambiarOffline, setCrudEjecutadoBien, linkDe) => {
    let url = urlBase + '/api/Programacion/ActualizaActividad'
    if (linkDe === 'variable') {
        url = urlBase + '/api/Programacion/ActualizaVariables'
    }
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datos)
        
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => { 
            onCambiarOffline(true, error);
            const mensajeError = error.message.indexOf('etch')>=0 ? 'No hay internet en su dispositivo' : error.message;
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: mensajeError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    if (retorno !== undefined && retorno.status === 200) {
        const elId = await retorno.text().then(valor => { return valor })
        return elId
    } else if (retorno !== undefined && retorno.status !== 200) {
        let datos = retorno.json()
        datos.then(d => {
            let textoError = ''
            if (d.errors !== undefined) {
                let objetoError = Object.values(d.errors)
                for (let i = 0; i < objetoError.length; i++) {
                    textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                }
            } else {
                textoError = d.mensaje
            }
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: textoError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    }
    return false
}

export const GuardarUnaActividadCampo= async(datos, setCrudEjecutadoBien) => {
    let url = urlBase +  '/api/Programacion/NuevaActividadCampo'
    const datosFinal={
        idactividad:datos.idactividad,
        descripcion:datos.descripcion,
        idunidadmedida:datos.idunidadmedida,
        cantidad:parseFloat(datos.cantidad),
        fechainicial:datos.fechainiprog,
        fechafinal:datos.fechafinprog,
        idsupervisor:datos.idsupervisor,
        idingeniero:datos.idcapataz,
        recursos: [...datos.recursos],
        variables:{...datos.variables}
    }   
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(datosFinal)
        
    }

    const retorno = await fetch(url, requestOptions)
        .catch(error => {             
            const mensajeError = error.message.indexOf('etch')>=0 ? 'No hay internet en su dispositivo' : error.message;            
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: mensajeError,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        })
    if (retorno !== undefined && retorno.status === 200) {
        const elId = await retorno.text().then(valor => { return valor })
        return elId
    } else if (retorno !== undefined && retorno.status !== 200) {
        //*** PENDIENTE             
            //1. CREAR EL REPORTE EJECUCIÓN DE INICIO OFFLINE,
            //3. AFECTAR EL ARBÓL DONDE SE VEA EL REPORTE DE INICIO */
        if (retorno.status===405){
            setCrudEjecutadoBien({
                accion: '',
                titulo: 'Error',
                mensaje: retorno.statusText,
                btnCancelar: false,
                mostrar: true,
                tipoMensaje: 'warning',
                rowId: null
            })
        }else{
            let datos = retorno.json()
            datos.then(d => {
                let textoError = ''
                if (d.errors !== undefined) {
                    let objetoError = Object.values(d.errors)
                    for (let i = 0; i < objetoError.length; i++) {
                        textoError = `${textoError} ${i+1}- ${objetoError[i]}   `
                    }
                } else {
                    textoError = d.mensaje
                }
                setCrudEjecutadoBien({
                    accion: '',
                    titulo: 'Error',
                    mensaje: textoError,
                    btnCancelar: false,
                    mostrar: true,
                    tipoMensaje: 'warning',
                    rowId: null
                })
            })
        }
    }
    return false
}
// Consultar etapas ejecución tarea
export const GetEjecutarActividad = async(idtarea, idreporte) => {
    const url = urlBase + '/api/Ejecucion'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify({ idtarea, idreporte })
    };

    const retorno = await fetch(url, requestOptions)
        .then(resp => {
            if (resp.ok)
                SetOffLine(false);
            return resp.json();            
        })
        .catch(error => {
             console.log({ error });
             SetOffLine(true);
             })
    return await retorno;
}

// Consultar etapas ejecución tarea
export const ConEjecutarActividad = async(idtarea, idreporte, etapatarea) => {   
    let consumirApi=false;
     //en este punto primero se debe validar sí el reporte a consultar tiene un reporte offline
    const ejecOff = JSON.parse(window.localStorage.getItem('ejecucionOffline'));
    if (ejecOff!==undefined && ejecOff!==null){
        const itemReportes = ejecOff.filter(x => x.idreporte.toLowerCase() === idreporte.toLowerCase() 
                                            && x.idtarea.toLowerCase() === idtarea.toLowerCase()
                                            && x.etapatarea.toLowerCase() === etapatarea.toLowerCase());
        if (itemReportes!==undefined && itemReportes!==null && itemReportes.length>0){
            const respuestaOffline= await CargarObjEjecucionEtapaTarea(idtarea, idreporte, etapatarea);
            return {ok:true,...respuestaOffline};
        }else{
            consumirApi=true;
        }
    }else{
        consumirApi=true;
    }
    //no existe un reporte offline asociado con el reporte consultado.
    if(consumirApi){    
        const url = urlBase + '/api/Ejecucion';
        const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'));
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usuario.token}`,
                'Georef': usuario.ubicacion
            },
            body: JSON.stringify({ idtarea, idreporte })
        };
    
        let respuestaApi =false;
        const respuesta = await fetch(url, requestOptions)
            .then(resp =>{ 
                respuestaApi=resp.ok;
                return resp.json();
            })
            .then(respObj => {
                if (respuestaApi){
                    //incluir en variablesOffline el reporte de variables que llegó
                    const variablesEjecucionDto={
                        idreporte : idreporte,
                        idtarea : idtarea,
                        etapatarea: etapatarea,
                        personas: respObj.variables.personas,
                        hse: respObj.variables.hse,
                        documentos: respObj.variables.documentos,
                        calidad: respObj.variables.calidad,
                        area: respObj.variables.area,
                        materiales: respObj.variables.materiales,
                        herramientas: respObj.variables.herramientas,
                        equipos: respObj.variables.equipos,
                        observacion: respObj.variables.observacion,
                        sincronizado:true
                    };        
                    GuardaVariablesEtapaTarea(variablesEjecucionDto);
                    SetOffLine(false);                
                }
                console.log(respObj);
                return respObj;           
            })
            .catch(error => {
                console.log({ error });
                SetOffLine(true);
                let objOffline = CargarObjEjecucionEtapaTarea(idtarea, idreporte, etapatarea);
                respuestaApi=true;
                return  objOffline;
            });
        return {ok:respuestaApi,...respuesta};
    }  
}

// Consultar Etapa Tarea
export const GetConsultarEtapaTarea = async(idtarea, idreporte, btnSlected) => {
    const url = urlBase + '/api/Ejecucion/idreporte=' + idreporte + '&idtarea=' + idtarea + '&etapatarea=' + btnSlected
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
        //body: JSON.stringify({ idtarea, idreporte, btnSlected })
    };
    let respuestaApi =false;
    let respuestaCode='200';
    let respuesta = await fetch(url, requestOptions)
        .then(resp => {
            respuestaApi =resp.ok;
            respuestaCode=resp.status;
            return resp.json();
        })
        .then(respObj => {
            console.log(respObj);
            //incluir en variablesOffline el reporte de variables que llegó
            let variablesEjecucionDto={
                idreporte : respObj.idnuevoreporte,
                idtarea : idtarea,
                etapatarea: btnSlected,
                personas: respObj.variables.personas,
                hse: respObj.variables.hse,
                documentos: respObj.variables.documentos,
                calidad: respObj.variables.calidad,
                area: respObj.variables.area,
                materiales: respObj.variables.materiales,
                herramientas: respObj.variables.herramientas,
                equipos: respObj.variables.equipos,
                observacion: respObj.variables.observacion
            };        
            GuardaVariablesEtapaTarea(variablesEjecucionDto);
            SetOffLine(false);            
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            SetOffLine(true);
            const response = CargarObjEjecucionEtapaTarea(idtarea, idreporte, btnSlected);  
            respuestaApi=true;         
            return response;
        });
        return {'ok':respuestaApi, 'respuestaCode':respuestaCode,...respuesta};
    /*const retorno = await fetch(url, requestOptions)
        .catch(error => { console.log({ error }) })
    return retorno.json();
    */
}

// Terinar Ejecución Etapa Tarea
export const TerminarEtapaTarea = async(terminaEtapaRequest) => {
    const url = urlBase + '/api/Ejecucion/Terminar'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    let tmpRequest={...terminaEtapaRequest};
    tmpRequest.data64file= tmpRequest.data64file.replace(tmpRequest?.data64filetype, '');
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(tmpRequest)
    };
    let respuestaServicio=false;
    
    const respuesta = await fetch(url, requestOptions)
        .then(resp =>{ 
            respuestaServicio=resp.ok;
            if (respuestaServicio===true){
                return resp.json();
            }else{
                return resp.text();
            }
        })
        .then(respObj => {
            console.log(respObj);
            if (respuestaServicio===true){
                EliminaEjecucionEtapaTarea(terminaEtapaRequest.idtarea, terminaEtapaRequest.idreporte, terminaEtapaRequest.etapatarea);
                SetOffLine(false);    
                return respObj;           
            }else{
                if (respObj===undefined){
                    GuardaEjecucionEtapaTarea(terminaEtapaRequest);
                    return {linea:0,mensaje:'Error no determinado en el servicio, por favor informar al administrador del sistema'};
                }
                return JSON.parse(respObj);
            }
            
        })
        .catch(error => {
            console.log({ error });
            SetOffLine(true);
            terminaEtapaRequest.sincronizado=false;
            terminaEtapaRequest.recursos=[];
            GuardaEjecucionEtapaTarea(terminaEtapaRequest);
            let objOffline = CargarObjEjecucionEtapaTarea(terminaEtapaRequest.idtarea, terminaEtapaRequest.idreporte, terminaEtapaRequest.etapatarea);
            return objOffline;
        });    
    return ({'ok':respuestaServicio,  ...respuesta}) ;
}

// Reiniciar Ejecución de Etapa Tarea
export const ReiniciarEtapaTarea = async(reiniciaEtapaRptRequest) => {
    await EliminaEjecucionEtapaTarea(reiniciaEtapaRptRequest.idtarea, reiniciaEtapaRptRequest.idreporte, reiniciaEtapaRptRequest.etapatarea);
    const url = urlBase + '/api/Ejecucion'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(reiniciaEtapaRptRequest)
    };
    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            SetOffLine(false);
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            SetOffLine(true);
            let objOffline = CargarObjEjecucionEtapaTarea(reiniciaEtapaRptRequest.idtarea, reiniciaEtapaRptRequest.idreporte, reiniciaEtapaRptRequest.etapatarea);
            return objOffline;
        });
    return respuesta;
}

// Emitir Concepto Aprobación Etapa Tarea
export const ConceptoEjecucionEtapaTarea = async(conceptoEtapaRptRequest) => {
    const url = urlBase + '/api/Ejecucion';
    let tmpRequest={...conceptoEtapaRptRequest};
    if (tmpRequest.hasOwnProperty('data64file') && tmpRequest.hasOwnProperty('data64filetype')){
        tmpRequest.data64file= tmpRequest.data64file.replace(tmpRequest?.data64filetype, '');
    }    
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'));
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(tmpRequest)
    };
    const retorno = await fetch(url, requestOptions)
        .then(resp => {
            SetOffLine(false);
            return resp.json();            
         })
        .catch(error => { 
            console.log({ error });
            SetOffLine(true);
         });
    return retorno;
}

export const SincronizarEtapaTarea = async(token=null, terminaEtapaRequest, idReporteLocal) => {
    const url = urlBase + '/api/Masivos/SyncEjecucionEtapa'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    let tmpRequest={...terminaEtapaRequest};
    tmpRequest.data64file= tmpRequest.data64file.replace(tmpRequest?.data64filetype, '');
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token===null ? usuario.token : token }`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(tmpRequest)
    };
    let finalizoApi=false;
    const retorno =  await fetch(url, requestOptions)
        .then(resp =>{ 
            finalizoApi= resp.ok;
            return  finalizoApi ? resp.json() : resp.text();            
        })
        .then(respObj => {
            if (finalizoApi){
                SetOffLine(false);                            
                if (respObj.hasOwnProperty('idnuevoreporte') && respObj.idnuevoreporte.length>0 ){                
                    EliminaEjecucionEtapaTarea(terminaEtapaRequest.idtarea, idReporteLocal, terminaEtapaRequest.etapatarea);     
                }
            }
            return respObj;        
        })
        .catch(error => {
            console.log({ error });
            if ( error.message.toLowerCase().indexOf('to fetch') === -1){
                return {'errores':[{
                    'linea':0,
                    'mensaje': error.message
                }]};
            }else{
                return {'errores':[{
                    'linea':0,
                    'mensaje': 'El dispositivo no cuenta con acceso a internet en este momento.'
                }]};
            }
        });
    return await retorno;  
        
}

// Consultar Variables en Etapa Tarea
export const VariablesEjecucionEtapaTarea = async(idtarea, idreporte, etapatarea) => {
    const url = urlBase + '/api/Ejecucion/id=' + idreporte + '&idtarea=' + idtarea + '&etapatarea=' + etapatarea
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
    };
    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            let objOffline = CargarObjVariablesEjeEtapaTarea(idtarea, idreporte, etapatarea);
            return objOffline;
        });
    return respuesta;
}


// Consultar Recursos Actividad
export const GetRecursosActividad = async(idActividad, modoActividadCampo=false) => {
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'));
    const response ={
        "id": idActividad,
        "idtarea":null,
        "etapatarea": modoActividadCampo ? 'Inicio':null,
        "etapaterminada": false,
        "tituloPantalla": "Recursos Programados OffLine",
        "lblobjeto3": "Hoy " + Date().toLocaleString("es-ES", configFecha) + usuario + " confirmo que usé los siguientes recursos para ejecutar la tarea: ",
        "listadoObjetosEstado": [
            {
                "id": "cmdnuevo",
                "nombre": "Nuevo",
                "activo": true,
                "visible": true
            },
            {
                "id": "cmdmodificar",
                "nombre": "Modificar",
                "activo": true,
                "visible": true
            },
            {
                "id": "cmdeliminar",
                "nombre": "Eliminar",
                "activo": true,
                "visible": true
            },
            {
                "id": "cmdcerrar",
                "nombre": "Cerrar",
                "activo": true,
                "visible": true
            }
        ],
        "datatable": [
        ],
        "fechainicial":new Date(Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
        "fechafinal":new Date(Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]
};
    if (modoActividadCampo===false){    
        const url = urlBase + '/api/Programacion/idactividad=' + idActividad        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usuario.token}`,
                'Georef': usuario.ubicacion
            }
        };    
        let respuesta = await fetch(url, requestOptions)
            .then(resp => resp.json())
            .then(respObj => {
                return respObj;
            })
            .catch(error => {
                console.log({ error });                            
                return response;
            });
        return await respuesta;   
    }else{        
        return response;
    }
}

// Consultar Recursos Etapa Tarea
export const GetRecursosTarea = async(idtarea, idreporte, etapatarea) => {
    const url = urlBase + '/api/Ejecucion/idtarea=' + idtarea + '&etapatarea=' + etapatarea + '&id=' + idreporte 
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }};        
    const objOffline = await CargarObjRecursosEjeEtapaTarea(idtarea, idreporte);
    let finalizoApi=false;
    /*let respuesta = await fetch(url, requestOptions)
        .then(resp =>resp.json())
        .then(resp =>{ 
            finalizoApi=resp.ok;
            if (finalizoApi===true){
                resp.json();
            }else{
                resp.text();
            }
        })
        .then(respObj => {
            return respObj;
        })
        .catch(error => {
            console.log({ error });            
            return objOffline;
        });
    await respuesta;
    */
    let respuesta = await fetch(url, requestOptions)
    //.then(resp => resp.json())
    .then(resp =>{
        const clonRespuesta=resp.clone()
        finalizoApi=clonRespuesta.ok
        if (finalizoApi===true){
            return resp.json()
        }else{
            return resp.text()
        }
    })
    .then(respObj => {
        console.log(respObj);
        if (finalizoApi===true){                        
            SetOffLine(false);    
            return respObj;           
        }else{
            if (respObj===undefined){                
                return {linea:0,mensaje:'Error no determinado en el servicio, por favor informar al administrador del sistema'};
            }else{
                return JSON.parse(respObj);
            }            
        }                    
    })
    .catch(error => {
        console.log({ error });            
        return objOffline;
    });        
    await respuesta;
    if ((finalizoApi && objOffline.datatable !== undefined) && objOffline.datatable.length>0){
        respuesta.datatable=[...respuesta.datatable,...objOffline.datatable];
        /*objOffline.datatable.forEach((registro)=>{
             respuesta.datatable.push(registro);
        })*/
    }
         
    return respuesta;
    
}
// Exportar Información a CSV
export const  ExportarTodoCsvAsync = async (viewExportarExcelRequest) =>  {
    const url = urlBase + '/api/Masivos/Exportar'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(viewExportarExcelRequest)
    }

    let respuesta = await fetch(url, requestOptions)
        .then(respObj => {
            console.log(respObj)
            let response = {
                'enviado': true,
                'destino': usuario.correo,
                'error': respObj.statusText
            };
            return response
        })
        .catch(error => {
            console.log({ error })
            let badresponse = {
                'enviado': false,
                'destino': null,
                'error': error
            }
            return badresponse
        })
    return respuesta;
}

// Consultar Tareas de Actividad
export const GetTareasActividad = async(idactividad) => {
    const url = urlBase + '/api/Tareas/idactividad=' + idactividad
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        }
    };

    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);
            //incluir en variablesOffline el reporte de variables que llegó
           /* let variablesEjecucionDto={
                idreporte : respObj.idnuevoreporte,
                idtarea : idtarea,
                etapatarea: btnSlected,
                personas: respObj.variables.personas,
                hse: respObj.variables.hse,
                documentos: respObj.variables.documentos,
                calidad: respObj.variables.calidad,
                area: respObj.variables.area,
                materiales: respObj.variables.materiales,
                herramientas: respObj.variables.herramientas,
                equipos: respObj.variables.equipos,
                observacion: respObj.variables.observacion
            };        
            GuardaVariablesEtapaTarea(variablesEjecucionDto);
            */
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            //let response = CargarObjEjecucionEtapaTarea(idtarea, idreporte, btnSlected);
            return error;
        });
    return await respuesta;
}

// Nueva Tarea Programada
export const NuevaTareaActividad = async(objViewPopupTareaRequest) => {
    const url = urlBase + '/api/Tareas/NuevaTarea'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(objViewPopupTareaRequest)
    };

    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            return error;
        });
    return await respuesta;
}

// Modifica Tarea Programada
export const ModificaTareaActividad = async(objViewPopupTareaRequest) => {
    const url = urlBase + '/api/Tareas/ModificaTarea'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(objViewPopupTareaRequest)
    };

    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            return error;
        });
    return await respuesta;
}

// Modifica Tarea Programada
export const EliminaTareaActividad = async(objViewPopupTareaRequest) => {
    const url = urlBase + '/api/Tareas/EliminaTarea'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(objViewPopupTareaRequest)
    };
    console.log(requestOptions)
    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            return error;
        });
    return await respuesta;
}

// Nuevo Recurso Tarea Programada
export const NuevoRecursoTarea = async(objViewPopupRecursoRequest,consumeServicioRest=true,origenPantalla=null) => {
    if (consumeServicioRest){
        let url = urlBase + '/api/Tareas/Recurso';
        let respuestaServicio=false;
    
        if (objViewPopupRecursoRequest.programacion){
            url = urlBase + '/api/Programacion/Recurso'
        }
    
        const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usuario.token}`,
                'Georef': usuario.ubicacion
            },
            body: JSON.stringify(objViewPopupRecursoRequest)
        };
    
        let respuesta = await fetch(url, requestOptions)
                    //.then(resp => resp.json())
                    .then(resp =>{ 
                        respuestaServicio=resp.ok;
                        if (respuestaServicio===true){
                            return resp.json();
                        }else{
                            return resp.text();
                        }
                    })
                    .then(respObj => {
                        console.log(respObj);
                        if (respuestaServicio===true){                        
                            SetOffLine(false);    
                            return respObj;           
                        }else{
                            if (respObj===undefined){
                                if (origenPantalla==='EjecutarActividad')
                                    GuardaRecursosEtapaTarea(objViewPopupRecursoRequest);
                                return {errores:[{linea:0,mensaje:'Error no determinado en el servicio, por favor informar al administrador del sistema'}]};
                            }
                            return respObj;
                        }                    
                    })
                    .catch(error => {
                        console.log({ error });
                        SetOffLine(true);
                        objViewPopupRecursoRequest.sincronizado=false;
                        if (origenPantalla==='EjecutarActividad'){
                            GuardaRecursosEtapaTarea(objViewPopupRecursoRequest);
                            let objOffline = CargarObjRecursosEjeEtapaTarea(objViewPopupRecursoRequest.Idtarea, objViewPopupRecursoRequest.Idreporte);
                            return objOffline;
                        }else{
                            const mensajeError = error.message.indexOf('etch')>=0 ? 'No hay internet en su dispositivo' : error.message;
                            return {errores:[{linea:0,mensaje: mensajeError}]};
                        }
                            
                    });        
        return await respuesta;
    }else{
        objViewPopupRecursoRequest.sincronizado=false;
        GuardaRecursosEtapaTarea(objViewPopupRecursoRequest);
        let objOffline = CargarObjRecursosEjeEtapaTarea(objViewPopupRecursoRequest.Idtarea, objViewPopupRecursoRequest.Idreporte);
        return objOffline;
    }
    
}

// Modifica Recurso Tarea 
export const ModificaRecursoTarea = async(objViewPopupRecursoRequest,consumeServicioRest=true,origenPantalla=null) => {
    if (consumeServicioRest){

        let url = urlBase + '/api/Tareas/Recurso';
        let respuestaServicio=false;
        if (objViewPopupRecursoRequest.programacion){
            url = urlBase + '/api/Programacion/Recurso'
        }
        const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usuario.token}`,
                'Georef': usuario.ubicacion
            },
            body: JSON.stringify(objViewPopupRecursoRequest)
        };

        let respuesta = await fetch(url, requestOptions)
            .then(resp => {
                respuestaServicio=resp.ok;
                if (respuestaServicio===true){
                    return resp.json();
                }else{
                    return resp.text();
                }
            })            
            .then(respObj => {
                console.log(respObj);
                if (respuestaServicio===true){                        
                    SetOffLine(false);    
                    return respObj;           
                }else{
                    if (respObj===undefined){
                        if (origenPantalla==='EjecutarActividad')
                            GuardaRecursosEtapaTarea(objViewPopupRecursoRequest);
                        return {errores:[{linea:0,mensaje:'Error no determinado en el servicio, por favor informar al administrador del sistema'}]};
                    }
                    return respObj;
                }                    
            })
            .catch(error => {
                console.log({ error });
                SetOffLine(true);
                if(origenPantalla==='EjecutarActividad'){
                    objViewPopupRecursoRequest.sincronizado=false;
                    GuardaRecursosEtapaTarea(objViewPopupRecursoRequest);
                    let objOffline = CargarObjRecursosEjeEtapaTarea(objViewPopupRecursoRequest.idtarea, objViewPopupRecursoRequest.idreporte);
                    return objOffline;
                }else{
                    const mensajeError = error.message.indexOf('etch')>=0 ? 'No hay internet en su dispositivo' : error.message;
                    return {errores:[{linea:0,mensaje: mensajeError}]};
                }
                
            });        
        return await respuesta;
    }else{
        objViewPopupRecursoRequest.sincronizado=false;
        GuardaRecursosEtapaTarea(objViewPopupRecursoRequest);
        let objOffline = await CargarObjRecursosEjeEtapaTarea(objViewPopupRecursoRequest.Idtarea, objViewPopupRecursoRequest.Idreporte);
        return objOffline;
    }
}

// Elimina Recurso Tarea 
export const EliminaRecursoTarea = async(objViewPopupRecursoRequest) => {
    if (objViewPopupRecursoRequest.Id.indexOf('ffline')>=0){
        EliminaRecursosTarea(objViewPopupRecursoRequest.Idtarea, objViewPopupRecursoRequest.Idreporte, objViewPopupRecursoRequest.Idrecurso);
        return{idrecurso:objViewPopupRecursoRequest.Idrecurso,idnuevatarea:'',errores:[],}
    }else{    
        let url = urlBase + '/api/Tareas/Recurso'
        if (objViewPopupRecursoRequest.programacion){
            url = urlBase + '/api/Programacion/Recurso'
        }
        const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${usuario.token}`,
                'Georef': usuario.ubicacion
            },
            body: JSON.stringify(objViewPopupRecursoRequest)
        };
        console.log(requestOptions)
        let finalizoApi=false;
        let respuesta = await fetch(url, requestOptions)
            .then(resp => {
                finalizoApi=resp.ok;
                return resp.json();
            })
            .then(respObj => {
                console.log(respObj);    
                if (finalizoApi===true)        {
                    EliminaRecursosTarea(objViewPopupRecursoRequest.Idtarea, objViewPopupRecursoRequest.Idreporte, objViewPopupRecursoRequest.Idrecurso);
                }
                
                return respObj;
            })
            .catch(error => {
                console.log({ error });
                return {errores:[{linea:0,mensaje: error.message}]};
            });
        return await respuesta;
    }
    
}

// Modifica el reporte de variables en la ejecución de una etapa en una tarea
export const ModificaVariablesEtapaTarea = async(SyncVariablesTareaRequest) => {
    const url = urlBase + '/api/Ejecucion/Variables'
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'))
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify(SyncVariablesTareaRequest)
    };

    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            return error;
        });
    return await respuesta;  
}

// Termina anticipadamente una Actividad
export const PutTerminarActividad = async(idactividad, textoMotivo, textoJustificacion) => {
    const url = urlBase + '/api/Actividades/Terminar';
    const usuario = JSON.parse(window.localStorage.getItem('logearUsuario'));
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
            'Georef': usuario.ubicacion
        },
        body: JSON.stringify({
            'idactividad':idactividad,
            'textomotivo':textoMotivo,
            'textojustificacion':textoJustificacion,
        })
    };

    let respuesta = await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(respObj => {
            console.log(respObj);            
            return respObj;
        })
        .catch(error => {
            console.log({ error });
            return error;
        });
    return await respuesta;
}