import React,{ useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import { IconContext } from "react-icons";
import { BsXSquare } from "react-icons/bs";
import { FaEraser, FaSave  } from "react-icons/fa";
import '../estilos/actividades.css';
import SweetAlert from 'react-bootstrap-sweetalert'
import { NuevoRecursoTarea , ModificaRecursoTarea, EliminaRecursoTarea } from '../../componentes/ConsumirApiRest'
import { fechaUTC } from '../../componentes/controlFechas'

export const PopupRecurso = ({openModal, setOpenModal, nuevoRegistro, idTareaRecursos,esProgramacion, idReporteEjecucion, fechaInicial, fechaFinal,setActualizarRecursos,esActividad,origenPantalla, esActividadCampo=false,recursosActCampo,setRecursosActCampo }) => {
    const modoActividadCampo= esActividadCampo!== undefined && esActividadCampo!==null ? esActividadCampo : false;
    const modoProgramacion = openModal=== true && esProgramacion !== undefined && esProgramacion !== null ? esProgramacion : false;                  
    const modoNuevoRegistro = nuevoRegistro === undefined ? true:nuevoRegistro
    const comboRecurso = JSON.parse(window.localStorage.getItem('maestrasUsuario'))
    const itemSeleccionado = JSON.parse(window.localStorage.getItem('recursoSel'));
    const [idTarea, setIdTarea] =  useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].idtarea : null);
    const [tipoRecurso, setTipoRecurso] =  useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].tiporecurso : null)
    const [idComboRecurso, setidComboRecurso] = useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].idrecurso : null)
    const [txtCantidad, settxtCantidad] = useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].cantidad: 0)
    const [txtFecha, setTxtFecha] = useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].fecha  : fechaInicial!==undefined && fechaInicial!==null ? fechaInicial :  new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
    const [txtFechaFinal, setTxtFechaFinal] = useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].fechafinal  : fechaFinal!==undefined && fechaFinal!==null ? fechaFinal : new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
    const [txtRecurso, settxtRecurso] = useState(openModal===true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].recurso : '')
    const [msgError, setmsgError] = useState('')
    const [formModificado, setformModificado] = useState(false)

    const handleForm = (target) => {
        console.log(target.name, target.value);
        setmsgError('')
        if (openModal){

            if (idTarea === undefined || idTarea === null){
                setIdTarea(idTareaRecursos)
                /*if (modoProgramacion===false){

                    let itemSel = JSON.parse(window.localStorage.getItem('recursoSel'));
                    if (itemSel!==undefined && itemSel !== null){
                        setIdTarea(itemSel[0].idtarea);
                        
                    }
                    else{
                        setIdTarea(itemSeleccionado[0].idtarea);
                    } 

                }*/
            }    

            if (target.name==='cborecurso'){

                const resultado = comboRecurso.MaestrasUsuario.find( recurso => recurso.id === target.value );
                setformModificado(true)
                setidComboRecurso(target.value)
                settxtRecurso(resultado.descripcion.trim())
                setTipoRecurso(resultado.tiporecurso.trim())
                
            }
            else if (target.name==='cantidad'){
                let cantidad=Number(target.value)
                if (target.value!=='' && cantidad<=0){
                    
                    setmsgError('La Cantidad debe ser mayor a 0')
                    //target.cancel(true)
                }
                else{
                    setformModificado(true)
                    settxtCantidad(target.value)
                    
                }
            }
            else if (target.name==='fecha'){
                if (target.value!==''){
                    setformModificado(true)
                    setTxtFecha(target.value)
                    
                    if (modoProgramacion===false){
                        setTxtFechaFinal(target.value)
                    }
                }
                
            }
            else if (target.name==='fechafinal'){
                if (target.value!==''){
                    setTxtFechaFinal(target.value)
                }
            }
        }
    }
    
    const buscarTipoRecurso = (idRecurso) => {
        let respuesta=null;
        const maestrasOff = JSON.parse(window.localStorage.getItem('maestrasUsuario'));
        if (maestrasOff!==undefined){
            const {MaestrasUsuario} = maestrasOff;
            const ItemMaestra = MaestrasUsuario.find( itemMaestra => itemMaestra.maestra==='Recursos' && itemMaestra.id.toLowerCase()===idRecurso.toLowerCase());
            if (ItemMaestra!==undefined && ItemMaestra!==null){
                respuesta=ItemMaestra?.tiporecurso;
            }
        }
        return respuesta;
    }
    const buscarDescripcionRecurso = (idRecurso) => {
        let respuesta=null;
        const maestrasOff = JSON.parse(window.localStorage.getItem('maestrasUsuario'));
        if (maestrasOff!==undefined){
            const {MaestrasUsuario} = maestrasOff;
            const ItemMaestra = MaestrasUsuario.find( itemMaestra => itemMaestra.maestra==='Recursos' && itemMaestra.id.toLowerCase()===idRecurso.toLowerCase());
            if (ItemMaestra!==undefined && ItemMaestra!==null){
                respuesta=ItemMaestra?.descripcion;
            }
        }
        return respuesta;
    }
    
    const guardar = async () => {
        const fechaminima='0001-01-01T00:00:00';
        let objViewPopupRecursoRequest={
            Id:'',
            Idtarea:idTarea,
            Idrecurso:idComboRecurso,
            cantidad:Number(txtCantidad),
            fechainicial: txtFecha,
            fechafinal:openModal && txtFechaFinal!== undefined && txtFechaFinal!==null && txtFechaFinal!==fechaminima ? txtFechaFinal  : new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0],
            Tiporecurso:tipoRecurso===null ? buscarTipoRecurso(idComboRecurso): tipoRecurso,
            programacion:modoProgramacion,
            Idreporte: openModal && idReporteEjecucion!== undefined && idReporteEjecucion!==null ? 
                        idReporteEjecucion : null,
            actividad : esActividad !== undefined ? esActividad : null,
            nuevoRegistro : modoNuevoRegistro
        }
        
        if (modoNuevoRegistro)
        {
            if (objViewPopupRecursoRequest.Idtarea===null && itemSeleccionado !== undefined && itemSeleccionado !== null && itemSeleccionado.length >0 ){
                objViewPopupRecursoRequest.Idtarea=itemSeleccionado[0].idtarea
            }
           if (modoActividadCampo===true){
                let itemsRecursos = recursosActCampo!== undefined && recursosActCampo!==null ? [...recursosActCampo] : [];
                const itemRecursoActcampo = {...objViewPopupRecursoRequest,
                    "id": generarIdRecursoActividadCampo(),
                    "idrecurso": idComboRecurso,
                    "descripcion": buscarDescripcionRecurso(idComboRecurso),
                    "fecha": txtFecha,
                    "fechaFinal": objViewPopupRecursoRequest.fechafinal,
                    "cantidad": Number(txtCantidad),
                    "idtarea" : objViewPopupRecursoRequest.Idtarea,
                }
                objViewPopupRecursoRequest.Id=itemRecursoActcampo.id;
                itemsRecursos.push(itemRecursoActcampo);
                setRecursosActCampo([...itemsRecursos]);
                setmsgError('Se guardó con éxito');
           }else{
                const respuesta = await NuevoRecursoTarea(objViewPopupRecursoRequest, modoActividadCampo ? false : modoProgramacion,origenPantalla)

                if (respuesta.hasOwnProperty('errores') && respuesta.errores!==undefined){
                    if(respuesta.errores.length>0){
                        setmsgError(respuesta.errores[0].mensaje);
                    }else{
                        setmsgError('Se guardó con éxito');
                    }                    
                }
                else{
                    setmsgError('Se guardó con éxito');
                }
           }
            
        }
        else
        {
            if (modoActividadCampo===true){
                let itemsRecursos = recursosActCampo!== undefined && recursosActCampo!==null ? recursosActCampo.filter(x=> x.id !== itemSeleccionado[0].id) : [];
                if (itemsRecursos===undefined || itemsRecursos===null){
                    itemsRecursos=[];
                }
                const itemRecursoActcampo = {...objViewPopupRecursoRequest,
                    "id": itemSeleccionado[0].id,
                    "idrecurso": (idComboRecurso===undefined || idComboRecurso===null) ? itemSeleccionado[0].idrecurso : idComboRecurso,
                    "descripcion": buscarDescripcionRecurso((idComboRecurso===undefined || idComboRecurso===null) ? itemSeleccionado[0].idrecurso : idComboRecurso),
                    "fecha": txtFecha,
                    "fechaFinal": objViewPopupRecursoRequest.fechafinal,
                    "cantidad": Number(txtCantidad),
                    "idtarea" : objViewPopupRecursoRequest.Idtarea,
                }
                //objViewPopupRecursoRequest.Id=
                itemsRecursos.push(itemRecursoActcampo);
                setRecursosActCampo([...itemsRecursos]);
                setmsgError('Se guardó con éxito');
           }else{
                objViewPopupRecursoRequest.Id=itemSeleccionado[0].id
                if (idComboRecurso===undefined || idComboRecurso===null){
                    objViewPopupRecursoRequest.Idrecurso=itemSeleccionado[0].idrecurso
                }            
                const respuesta = await ModificaRecursoTarea(objViewPopupRecursoRequest,modoProgramacion,origenPantalla);                            
                if (respuesta.errores!==undefined && respuesta.errores.length>0){
                    setmsgError(respuesta.errores[0].mensaje)
                }
                else{
                    setmsgError('Se guardó con éxito')
                }
            }
        }
           
    }

    const eliminar = async () => {
        const itemSeleccionado = JSON.parse(window.localStorage.getItem('recursoSel'));  
        let objViewPopupRecursoRequest={
            Id:itemSeleccionado[0].id,
            Idtarea:itemSeleccionado[0].idtarea,
            Idrecurso:idComboRecurso,
            cantidad:Number(txtCantidad),
            fechainicial: txtFecha,
            fechafinal:txtFechaFinal,
            Tiporecurso:tipoRecurso,
            programacion:modoProgramacion,
            Idreporte: openModal && idReporteEjecucion !== undefined && idReporteEjecucion !== null ? 
                        idReporteEjecucion : null
        }        
        if (idComboRecurso===undefined || idComboRecurso===null){
            objViewPopupRecursoRequest.Idrecurso=itemSeleccionado[0].idrecurso;
        }                    
        const respuesta = await EliminaRecursoTarea(objViewPopupRecursoRequest);                            
        if (respuesta.errores.length>0){
            setmsgError(respuesta.errores[0].mensaje);
        }else{
            setmsgError('Se eliminó con éxito');  
        }
      
    }

    const cerrar =() =>{
        setOpenModal(false)
        settxtCantidad(null)
        setTxtFecha(null)
        setTxtFechaFinal(null)
        settxtRecurso(null)
        setidComboRecurso(null)
        setmsgError('')
        setformModificado(false)
        setTipoRecurso(null)
        if (setActualizarRecursos!==undefined){            
            setActualizarRecursos(true)
        } 
            
    }

    useEffect(() => {
        if (openModal && modoNuevoRegistro===false && itemSeleccionado!== undefined && itemSeleccionado!==null && formModificado===false) {                             
            let { recurso, idrecurso, fecha, cantidad, fechaFinal,tiporecurso } = itemSeleccionado[0];
            fecha=fecha===undefined || fecha===null ? fechaInicial : fecha;
            console.log(txtRecurso, idComboRecurso, txtFecha, txtCantidad, txtFechaFinal)
            settxtRecurso(recurso)
            setidComboRecurso(idrecurso)
            setTipoRecurso(tiporecurso)
            if (modoProgramacion===false){
                setTxtFecha( new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
            }
            else{
                setTxtFecha(fecha)
            }
            
            settxtCantidad(Number(cantidad))         
            setTxtFechaFinal(fechaFinal)
            setformModificado(true)	
            console.log(txtRecurso, idComboRecurso, txtFecha, txtCantidad, txtFechaFinal)	
        }
        else if(openModal && modoNuevoRegistro && idTarea===null  && formModificado===false) {
            
            setIdTarea (idTareaRecursos)
            if (fechaFinal!==null && fechaInicial!==null){
                setTxtFecha(fechaInicial)
                setTxtFechaFinal(fechaFinal)
            }
        }
        else if(openModal && modoNuevoRegistro && idTarea!==null && modoProgramacion===false && formModificado===false) {
            setTxtFecha( new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
            setTxtFechaFinal( new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
        }else if(openModal && modoNuevoRegistro && idTarea!==null && modoProgramacion){
            setTxtFecha(fechaInicial);
            setTxtFechaFinal(fechaFinal);
        }
        return () => {
            
        }
    })

    if (openModal===true){
        return (
            <SweetAlert
                show={openModal}
                title={''}
                onConfirm={()=>null}
                showCancel={false}
                showConfirm={false}
                confirmBtnText={"Aceptar"}
                closeOnClickOutside={false}
                showCloseButton={true}> 
                <Modal.Header>
                    {msgError !== ''&&
                        <div className="input-group mb-3">
                            <div style={{width:'113%'}}><p>{msgError}</p></div>
                        </div>
                    }
                  
                    <IconContext.Provider value={{ color: "red", className: "global-class-name", size:'2em' }}>                          
                            <BsXSquare onClick={()=>cerrar()} className="cursor" title='Cancelar' style={estilos.estiloIcono}/>
                    </IconContext.Provider>
                    
                </Modal.Header>  
                <Modal.Body>
                    {modoNuevoRegistro&&
                    <div style={estilos.fila}  className="input-group p-1">
                        <p style={{paddingRight:'20px' }}>Recurso</p>
                        <select value={idComboRecurso} onChange={({target})=>handleForm(target)} id="id" className="form-select form-select-sm" name="cborecurso" disabled={modoNuevoRegistro?false:true}>
                        <option value={null}>{'Seleccione Recurso'}</option>
                            {comboRecurso.MaestrasUsuario.filter(opcion=>opcion.maestra==='Recursos' && (opcion.tiporecurso==='C' || opcion.tiporecurso==='E'))
                                .map((obj)=>{
                                return <option value={obj.id}>{obj.descripcion}</option>
                            })}
                        </select>
                    </div>}

                    {modoNuevoRegistro===false&&
                    <div className="input-group mb-3">
                        <div style={{width:'113%'}}><p>Recurso</p></div>
                        <input onChange={({target})=>handleForm(target)} name="recurso" type="text" className="form-control" disabled="true"
                                placeholder="Recurso" aria-label="recurso" aria-describedby="recurso" value={txtRecurso}/>
                    </div>
                    }
                    
                    {modoProgramacion===true &&
                    <div className="input-group mb-3" style={estilos.fila}>
                        <div style={{width:'113%'}}><p>Fecha Inicial</p></div>
                        <input onChange={({target})=>handleForm(target)} name="fecha" type="datetime-local" className="form-control" 
                            aria-label="fecha" aria-describedby="fecha"  value={txtFecha} />
                    </div>}
                    {modoProgramacion===false &&
                    <div className="input-group mb-3" style={estilos.fila}>
                        <div style={{width:'113%'}}><p>Fecha Inicial</p></div>
                        <input onChange={({target})=>handleForm(target)} name="fecha" type="datetime-local" className="form-control"  disabled="true"
                            aria-label="fecha reporte" aria-describedby="fecha"  value={txtFecha!==null && txtFecha!==null ? txtFecha : fechaInicial} />
                    </div>}

                    {modoProgramacion &&
                    <div className="input-group mb-3" style={estilos.fila}>
                        <div style={{width:'113%'}}><p>Fecha Final</p></div>
                        <input onChange={({target})=>handleForm(target)} name="fechafinal" type="datetime-local" className="form-control" 
                            aria-label="fechafinal" aria-describedby="fechafinal"  value={txtFechaFinal===null ? fechaFinal : txtFechaFinal} />
                    </div>}    
                     
                    <div style={estilos.fila} className="input-group mb-3">
                        <div style={{width:'113%'}}><p>Horas totales de la actividad</p></div>
                        <div className="input-group mb-3">
                            <input onChange={({target})=>handleForm(target)} name="cantidad" type="number" className="form-control" 
                                placeholder="Ingresa la cantidad" aria-label="amount" aria-describedby="basic-addon1" value={txtCantidad} />
                        </div>
                    </div>          
                </Modal.Body>

                
                <Modal.Footer>   
                    <div style={{...estilos.fila, marginBottom:'1rem', marginLeft:'10px'}}>
                        <IconContext.Provider value={{ color: "green", className: "global-class-name", size:'2em' }}>
                            {msgError === '' && Number(txtCantidad)>0 && idComboRecurso!==null &&
                                txtFecha!==null && txtFechaFinal!==null && 
                                <FaSave onClick={()=>guardar()} className="cursor" title='Guardar' style={estilos.estiloIcono}/>
                            }
                            {msgError === '' && !modoNuevoRegistro && Number(txtCantidad)>0 && idComboRecurso!==null &&
                                txtFecha!==null && txtFechaFinal!==null && 
                                <FaEraser onClick={()=>eliminar()} className="cursor" title='Eliminar' style={estilos.estiloIcono}/>
                            }                           
                        </IconContext.Provider>
                    </div>
                </Modal.Footer>
            </SweetAlert>   
        )
    }
    else{
        return () => {
            // TODO: limpiar stateEjecutarActividad
          //  settxtCantidad(null)
          //  settxtFecha(null)
        }
    }
}

const estilos = {
    fila:{display:'flex', justifyContent:'space-between', alignItems:'center'},
    estiloIcono: {marginLeft:'5px', marginRight:'5px'}
}
const generarIdRecursoActividadCampo = () =>{
    return ('offline-RecActCampo-'.concat((Math.random()  * 22).toString(36).substring(7)));
}