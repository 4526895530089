import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import Modal from 'react-bootstrap/Modal'
import { BsSearch,  BsPlusSquare, BsPencilSquare } from "react-icons/bs";
import { FaEraser } from "react-icons/fa";
import { FcSupport, FcInspection } from "react-icons/fc"
import { FaCamera,} from 'react-icons/fa'
import { IconContext } from "react-icons";
import { DatosTarea } from '../../componentes/Tareas/DatosTarea';
import { PopupTarea } from '../../componentes/Tareas/PopupTarea';
import { EliminaTareaActividad  } from '../../componentes/ConsumirApiRest';
import {ViewTerminarActividad} from '../../paginas/unaActividad/views/ViewTerminarActividad';
import {Variables} from '../../paginas/unaActividad/Variables';
import { Recursos } from '../../paginas/unaActividad/Recursos';
import {EjecutarActividad} from '../../paginas/ejecutarActividad/EjecutarActividad';


export const ViewTareas = ({objsolicitudTareas , apiActividadTareas, abirModal, setAbirModal, actualizarDatos,setactualizarDatos,setactualizarDatosTareas}) => {
    const [openModalForm, setOpenModalForm] = useState(abirModal);
    const [nuevoRegistroForm, setnuevoRegistroForm] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [msgError, setmsgError] = useState('');
    const [verTerminar, setVerTerminar] = useState(true);
    const [showRecurso, setShowRecurso ] = useState(false);
    const [showEjecucion, setShowEjecucion ] = useState(false);
    const [itemSeleccionado, setItemSeleccionado] = useState(null);
    const [objRecursos, setobjRecursos] = useState({idreporte: '',
                                                    idtarea : '',
                                                    etapatarea: '',
                                                    idactividad: ''
                                                    });
    const [paginaVer, setpaginaVer] = useState(null);
    const [idReporteEjecucion, setidReporteEjecucion] = useState(null);
    const [estadoTarea, setestadoTarea] = useState(null);
    const [showActividadCampo, setShowActividadCampo ] = useState(false);
    const [idAbrirActividad, setIdAbrirActividad] = useState(objsolicitudTareas.idactividad);
    const [arbol, setArbol] = useState([]);
    const [mostrarTerminarTarea, setMostrarTerminarTarea] = useState(false);

    const objTareas = 
        {
            "idactividad": objsolicitudTareas.idactividad,
            "estadoactividad": objsolicitudTareas.estadoactividad,
            rows: apiActividadTareas !== null && apiActividadTareas !== undefined &&
            objsolicitudTareas.rows!== undefined ? objsolicitudTareas.rows : [] ,
            "apiActividadRecurso":[],
            "fechaInicial":objsolicitudTareas.fechaInicial,
            "fechaFinal":objsolicitudTareas.fechaFinal
        };

    const ValidapaginaVer =(pagina)=>setpaginaVer(pagina)
    const cerrarMostrarTerminarTarea =()=>setMostrarTerminarTarea(false);

    const handleinputsChange = (target) => {
        console.log(target.name, target.value);
        setmsgError('')
    }

    const agregar = () => {
        setmsgError('')
        setnuevoRegistroForm(true)
        setOpenPopup(true)
    }
    const editar = () => {
        setmsgError('')
        setnuevoRegistroForm(false)
        setOpenPopup(true); 
    }

    const eliminar = async () => {
        setmsgError('')
        
        if (itemSeleccionado!=null && itemSeleccionado!== undefined){
            const objViewPopupTareaRequest={
                "idactividad":objTareas.idactividad,
                "Idtarea":itemSeleccionado.id,
                "idunidadmedida":"x",
                "cantidad":0,
                "fechainicial":objsolicitudTareas.fechaInicial,
                "fechafinal":objsolicitudTareas.fechaInicial,
                "descripcion":"x",
            }
            const respuesta = await EliminaTareaActividad(objViewPopupTareaRequest);
            if (respuesta.errores.length>0){
                setmsgError(respuesta.errores[0].mensaje)
            }
            else{
                setmsgError('Se eliminó con éxito')
            }
        }
    }

    const terminarActividad = async() => {
        setmsgError('')        
        if (objsolicitudTareas.idactividad!== undefined && objsolicitudTareas.idactividad!=null){  
            setMostrarTerminarTarea(true);
            /*setVerTerminar(false)          
            const respuesta = await PutTerminarActividad(objsolicitudTareas.idactividad);
            if (respuesta.hasOwnProperty('errores') && respuesta.errores.length>0){
                setmsgError(respuesta.errores[0].mensaje)
                setVerTerminar(true)
            }
            else{
                setmsgError('Se terminó con éxito')
                
            }*/
        }
    }
    
    const recursos = async() => {
        const itemSeleccionado = JSON.parse(window.localStorage.getItem('tarSel'));
        let { id, idReporte,estado, fechainicial, fechafinal} = itemSeleccionado[0];
        let objSolicitudRecursos = {idreporte: idReporte,
                idtarea : id,
                etapatarea:estado,
                idactividad: objsolicitudTareas.idactividad,
                datos:[],
                fechaInicial: fechainicial ? fechainicial : null,
                fechaFinal: fechafinal ? fechafinal : null
                }
    
       //const datos= await GetRecursosTarea(objSolicitudRecursos)
       
       //objSolicitudRecursos.apiActividadRecurso=datos;
       setobjRecursos(objSolicitudRecursos)        
        setShowRecurso(true)
    }

    const variables = () => {
        setmsgError('')
        const itemSeleccionado = JSON.parse(window.localStorage.getItem('tarSel'));
        const {id, idReporte,estado} = itemSeleccionado[0];
        setidReporteEjecucion(idReporte)
        setestadoTarea(estado)
        ValidapaginaVer(3)
        setShowActividadCampo(true)
    }

    const ejecucion = () => {        
        setmsgError('')
        const apiActividades = JSON.parse(window.localStorage.getItem('actividades'))
        setArbol(apiActividades.datatable)
        setShowEjecucion(true)
    }

    const cerrar =() =>{
        setAbirModal(false)
        setOpenModalForm(false)
        if (actualizarDatos!==undefined){
            setactualizarDatos(actualizarDatos===false ? true : false) 
        }
          
    }   
    
    if (openModalForm)
        if (mostrarTerminarTarea){
            return(
                <ViewTerminarActividad
                    idActividad={idAbrirActividad}
                    setMostrarTerminarTarea ={cerrarMostrarTerminarTarea}
                    descripcionActividad={''}
                />
            );
        }else{
            return (
                <SweetAlert
                show={openModalForm}
                title={''}
                // onConfirm={async()=>SincronizaReportesEtapaTarea()}
                onConfirm={()=>null}
                showCancel={false}
                showConfirm={false}
                confirmBtnText={"Aceptar"}
                closeOnClickOutside={false}
                showCloseButton={true}>
                <Modal.Header>
                    {msgError !== ''&&
                                <div className="input-group mb-3">
                                    <div style={{width:'113%'}}><p>{msgError}</p></div>
                                </div>
                            }                    
                    <div style={{width:'113%'}}><p>Programación de Tareas</p></div>
                    <button onClick={()=>cerrar()}  type="button" className="btn btn-outline-danger btn-sm" style={{position:'absolute', right:'10px'}}>
                        Cerrar
                    </button>                   
                </Modal.Header>
                <Modal.Body>
                    {showActividadCampo &&
                    <div>
                        <Variables showActividadCampo={showActividadCampo} 
                                setShowActividadCampo={setShowActividadCampo}
                                titulo={"Ejecución Tarea"}
                                idAbrirActividad={idAbrirActividad}
                                setIdAbrirActividad={setIdAbrirActividad}
                                stepInicio={paginaVer}
                                idreporte={idReporteEjecucion}
                                etapatarea={estadoTarea} 
                                origen ={"Tareas"}                         
                          />
                    </div>}    

                    {showRecurso &&
                    <div> <Recursos objsolicitudRecursos={objRecursos}
                            apiActividadRecurso={objTareas.apiActividadRecurso}
                            stepInicio={1}
                            showActividadCampo={showRecurso} 
                            setShowActividadCampo={setShowRecurso}
                            esProgramacion={true}
                            idReporteEjecucion={objRecursos.idreporte}
                            setactualizarDatosRecursos={recursos}
                            origenPantalla='ViewTareas'
                        />
                    </div>}

                    {showEjecucion &&
                    <div><EjecutarActividad showEjecutar={showEjecucion} idAbrirActividad={idAbrirActividad}
                            arbol={arbol} setShowEjecutar={setShowEjecucion} origen={"Tareas"}
                            setactualizarDatos={setactualizarDatos} 
                            setactualizarDatosTareas={setactualizarDatosTareas}
                        />
                    </div>}

                    <div>                
                        <div style={estilos.fila}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><BsSearch/></span>
                                <input onChange={({target})=>handleinputsChange(target)} name="search" type="text" className="form-control" aria-label="Buscar" 
                                    aria-describedby="basic-addon1"/>
                            </div>
                            
                            <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'2em' }}>
                                <div style={{...estilos.fila, marginBottom:'1rem', marginLeft:'10px'}}>
                                    {!itemSeleccionado &&
                                    <BsPlusSquare onClick={()=>agregar()} name="Agregar" className="cursor" title='Agregar' style={estilos.estiloIcono}/>}  
                                    {itemSeleccionado && 
                                        <BsPencilSquare onClick={()=>editar()} className="cursor" title='Editar' style={estilos.estiloIcono}/>}
                                    {itemSeleccionado && 
                                        <FaEraser onClick={()=>eliminar()} className="cursor" title='Eliminar' style={estilos.estiloIcono}/>}
                                    {itemSeleccionado &&
                                        <FcSupport onClick={()=>recursos()} className="cursor" title='Recursos' style={estilos.estiloIcono}/>}
                                    {itemSeleccionado &&
                                    <FcInspection onClick={()=>variables()} className="cursor" title='Variables' style={estilos.estiloIcono}/>}
                                    {itemSeleccionado &&
                                    <FaCamera onClick={()=>ejecucion()} className="cursor" title='Ejecucion' style={estilos.estiloIcono}/>}
                                </div>
                            </IconContext.Provider>
                        </div>
                        
                        <DatosTarea idActividad={objTareas.idactividad} 
                                    EstadoActividad={objTareas.estadoactividad} 
                                    rows= {objTareas.rows}
                                    setItemSeleccionado={setItemSeleccionado} />
                        
                        <div style={{overflow: 'scroll'}}>    
                            <PopupTarea openModal={openPopup} 
                                    setOpenModal={setOpenPopup}
                                    nuevoRegistro={nuevoRegistroForm}
                                    idActividad = {objTareas.idactividad}
                                    fechaInicial={objTareas.fechaInicial}
                                    fechaFinal={objTareas.fechaFinal}
                                    setactualizarDatosTareas={setactualizarDatosTareas} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                   {verTerminar && <div style={estilos.fila}>
                    <button onClick={async()=>terminarActividad()}  type="button" className="btn btn-outline-danger btn-sm" style={{position:'absolute', right:'10px'}}>
                            Terminar Actividad
                        </button>
                    </div>}
                </Modal.Footer>
                </SweetAlert>
            );
        }
    }    



const estilos = {
    fila:{display:'flex', justifyContent:'space-between', alignItems:'center'},
    estiloIcono: {marginLeft:'5px', marginRight:'5px'}
}

  /*en línea 240 se elilmina pues esta funcionalidad no se tiene encuenta
                        */