import React,{ useState} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { PutTerminarActividad } from '../../../componentes/ConsumirApiRest';

export const ViewTerminarActividad = props =>{
    const comboRazones=[{'id':null,'descripcion':'Seleccione Motivo'},
                        {'id':'Realizada','descripcion':'Realizada'},
                        {'id':'Retirada del alcance por problemas del cliente','descripcion':'Retirada del alcance por problemas del cliente'},
                        {'id':'Retirada del alcance por problemas de SC','descripcion':'Retirada del alcance por problemas de SC'},
                       ];

    const idActividad = props.idActividad && props.idActividad !== null ?  props.idActividad : null;
    const descripcionActividad = props.descripcionActividad && props.descripcionActividad !== null ?  props.descripcionActividad : null;
    const [motivo,setMotivo]=useState(null);
    const [msgError,setMsgError]=useState(null);
    const [justificacion,setJustificacion]=useState(null);
    const [verTerminar,setVerTerminar]=useState(false);

    const onSeleccionaMotivo = (e) => {
        setMsgError(null);
        setMotivo(e.target.value);
        if (e.target.value==='Seleccione Motivo') {
            setVerTerminar(false);
        }else{            
            setVerTerminar(justificacion!==null && justificacion.length>5);
        }
        
    };
    const onChangeJustificacion = (e) => {
        setMsgError(null);
        setJustificacion(e.target.value);
        if (e.target.value==='' || e.target.value.length<=5){
            setVerTerminar(false);    
        }else{
            setVerTerminar(true);    
        }
            
    }

    const ejeuctarAsincrono = async () => {  
        setMsgError(null);      
        if (idActividad!==null && verTerminar){        
            const respuesta = await PutTerminarActividad(idActividad, motivo,justificacion);
            if (respuesta.hasOwnProperty('errores') && respuesta.errores.length>0){            
                setMsgError(respuesta.errores[0].mensaje);
                setVerTerminar(false);
                return;               
            }else if(respuesta.hasOwnProperty('message')){
                setMsgError(respuesta.message);
                setVerTerminar(true);
                return;
            }
            else{
                setMsgError('Se terminó con éxito la actividad');
                setVerTerminar(false);         
            }            
        }
    };
    
    
    const onClickSi = () =>{            
        if (motivo===null){
            window.alert('Es necesario seleccionar el motivo por el cual termina la actividad.');
        }else if( justificacion===null){            
            window.alert('Es necesario especificar la justificación por la cual termina la actividad.');
        }else if (justificacion.length<=5){
            window.alert('la justificación por la cual termina la actividad, no cumple con la longitud mínima (5).');
        }else{
            try{
                ejeuctarAsincrono();
            } catch (error) {
                console.log(error)
                setMsgError(error.message);
            }
            
        }
    } ;

    return (
        <SweetAlert
            show={props.showActividadCampo}
            title={"Terminar Actividad"}
            onConfirm={onClickSi}
            onCancel={ ()=> props.setMostrarTerminarTarea(false)}
            cancelBtnText={"Cancelar"}
            showCancel={true}
            showConfirm={verTerminar}
            confirmBtnText={"Terminar"}
            closeOnClickOutside={false}
            showCloseButton={false}>
            <div style={{backgroundColor:'aliceblue', borderRadius:'1%'}} className="border">
                <div style={{marginBottom:'15px', marginTop:'15px'}}>
                    <p style={{fontSize:'small'}}>{descripcionActividad}</p>
                </div>
                <div className="input-group p-1">
                    <p style={{paddingRight:'20px' }}>Motivo:</p>
                    <select value={motivo} onChange={onSeleccionaMotivo} id="idMotivo" className="form-select form-select-sm" placeholder="Especifique el motivo por la cual termina la actividad" >
                        {comboRazones.map((obj)=>{
                            return <option value={obj.id}>{obj.descripcion}</option>
                        })}
                    </select>   
                </div>         
                
                <div className="input-group p-1">
                    <p style={{paddingRight:'20px' }}>Justificación:</p>
                    <textarea value={justificacion} onChange={onChangeJustificacion} id="idJustificacion" 
                            className="form-control" placeholder="Especifique la justificación por la cual termina la actividad" 
                            style={{height: "100px"}} maxLength={199} ></textarea>
                </div>
                {msgError!==null && <div className='alert alert-warning' style={{marginBottom:'15px', marginTop:'15px'}}>
                    <p style={{fontSize:'small'}}>{msgError}</p>
                </div>}
            </div>
        </SweetAlert>       
    );
}

export default ViewTerminarActividad;