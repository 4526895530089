import React,{ useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import { IconContext } from "react-icons";
import { BsXSquare } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import '../estilos/actividades.css';
import SweetAlert from 'react-bootstrap-sweetalert'
//NuevaTareasActividad
import { NuevaTareaActividad, ModificaTareaActividad} from '../../componentes/ConsumirApiRest'
import { fechaUTC } from '../../componentes/controlFechas'

export const PopupTarea = ({openModal, setOpenModal, nuevoRegistro, idActividad, fechaInicial, fechaFinal, setactualizarDatosTareas }) => {
    const idActividadTarea=idActividad
    const modoNuevoRegistro = nuevoRegistro === undefined ? true:nuevoRegistro
    const comboRecurso = JSON.parse(window.localStorage.getItem('maestrasUsuario'))
    const itemSeleccionado = JSON.parse(window.localStorage.getItem('tarSel'));
    const [idComboUnidadMedida, setComboUnidadMedida] = useState(openModal==true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].unidad : null)
    const [txtCantidad, settxtCantidad] = useState(openModal==true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].cantidad: 0)
    const [txtFechaInicial, settxtFechaInicial] = useState(openModal==true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].fechainicial  : new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
    const [txtFechaFinal, settxtFechaFinal] = useState(openModal==true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].fechafinal  : new Date(fechaUTC().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0])
    const [txtTarea, settxtTarea] = useState(openModal==true && itemSeleccionado!== undefined && itemSeleccionado!==null ? itemSeleccionado[0].tarea.trim() : '')
    const [msgError, setmsgError] = useState('')
    const [formModificado, setformModificado] = useState(false)


  //  const onListaRecursos = (e) => setidComboRecurso(e.target.value)

    const handleForm = (target) => {
        if (openModal){
            console.log(target.name, target.value);
            setmsgError('')

            if (target.name=='cborecurso'){
                setformModificado(true)
                setComboUnidadMedida(target.value)
            // const resultado = comboRecurso.MaestrasUsuario.find( recurso => recurso.id === target.value );
                //settxtTarea(resultado.descripcion.trim())
            }
            else if (target.name=='cantidad'){
                let cantidad=Number(target.value)
                if (target.value!='' && cantidad<=0){
                    
                    setmsgError('La Cantidad debe ser mayor a 0')
                    //target.cancel(true)
                }
                else{
                    setformModificado(true)
                    settxtCantidad(target.value)
                    
                }
            }
            else if (target.name=='fecha'){
                if (target.value!==''){
                    setformModificado(true)
                    settxtFechaInicial(target.value)
                }
                
            }
            else if (target.name=='fechafinal'){
                if (target.value!==''){
                    setformModificado(true)
                    settxtFechaFinal(target.value)
                }
            }
            else if (target.name=='recurso'){
            // if (target.value!==''){
                setformModificado(true)
                settxtTarea(target.value)
            // }
                
            }
        }
    }

    const guardar = async () => {
    if (idActividadTarea!=null && idActividadTarea!== undefined){
            console.log('guardar');
            console.log(idComboUnidadMedida,txtCantidad,txtFechaInicial);
            const objViewPopupTareaRequest={
                "idactividad":idActividadTarea,
                "Idtarea":"",
                "Idunidadmedida":idComboUnidadMedida,
                "cantidad":Number(txtCantidad),
                "fechainicial": txtFechaInicial,
                "fechafinal":txtFechaFinal,
                "Descripcion":txtTarea
            }
            
            if (modoNuevoRegistro)
            {
                const respuesta = await NuevaTareaActividad(objViewPopupTareaRequest);
                if (respuesta.errores.length){
                    setmsgError(respuesta.errores[0].mensaje)
                }
                else{
                    setmsgError('Se guardó con éxito')
                }
            }
            else{
                objViewPopupTareaRequest.Idtarea=itemSeleccionado[0].id
                if (idComboUnidadMedida==undefined || idComboUnidadMedida==null){
                    objViewPopupTareaRequest.Idunidadmedida=itemSeleccionado[0].idunidadmedida
                }

                const respuesta = await ModificaTareaActividad(objViewPopupTareaRequest);
                if (respuesta.errores){
                    setmsgError('Se guardó con éxito')
                }
                else{
                    setmsgError(respuesta.errores[0].mensaje)
                }
            }
           
        }
        
    }

    const cerrar =() =>{
        setOpenModal(false)
        settxtCantidad(null)
        settxtTarea(null)
        settxtFechaInicial(null)
        settxtFechaFinal(null)
        setComboUnidadMedida(null)
        setmsgError('')
        setformModificado(false)
        setactualizarDatosTareas()
    }

    useEffect(() => {
        if (openModal && modoNuevoRegistro==false && itemSeleccionado!== undefined && itemSeleccionado!==null && formModificado==false) {                             
            let { tarea, idReporte, fechainicial, cantidad, fechafinal , unidad} = itemSeleccionado[0];
            console.log(tarea, idReporte, fechainicial, cantidad, fechafinal, unidad)
            console.log(txtTarea, idComboUnidadMedida, txtFechaInicial, txtCantidad, txtFechaFinal)
            settxtTarea(tarea)
            setComboUnidadMedida(unidad)
            settxtFechaInicial(fechainicial)
            settxtCantidad(Number(cantidad))         
            settxtFechaFinal(fechafinal)
            setformModificado(true)	
            console.log(txtTarea, idComboUnidadMedida, txtFechaInicial, txtCantidad, txtFechaFinal)	
        }
        else if(openModal && modoNuevoRegistro &&  formModificado==false &&
                fechaFinal!==null && fechaInicial!==null) {
            settxtFechaInicial(fechaInicial)
            settxtFechaFinal(fechaFinal)
        }
        return () => {
            
        }
    })

    if (openModal==true){
        return (
            <SweetAlert
                show={openModal}
                title={''}
                onConfirm={()=>null}
                showCancel={false}
                showConfirm={false}
                confirmBtnText={"Aceptar"}
                closeOnClickOutside={false}
                showCloseButton={true}> 
                <Modal.Header>
                         {msgError !== ''&&
                            <div className="input-group mb-3">
                                <div style={{width:'113%'}}><p>{msgError}</p></div>
                            </div>
                         }
                </Modal.Header>  
                <Modal.Body>

                    <div className="input-group mb-3">
                        <div style={{width:'113%'}}><p>Tarea</p></div>
                        <input onChange={({target})=>handleForm(target)} name="recurso" type="text" className="form-control" 
                                placeholder="Nombre de la tarea" aria-label="Nombre de la tarea" aria-describedby="Descripción de la tarea" value={txtTarea}/>
                    </div>
                    
                    <div style={estilos.fila}  className="input-group p-1">
                        <p style={{paddingRight:'20px' }}>Unidad Medida</p>
                        <select value={idComboUnidadMedida} onChange={({target})=>handleForm(target)} id="id" className="form-select form-select-sm" name="cborecurso">
                            {comboRecurso.MaestrasUsuario.filter(opcion=>opcion.maestra==='UnidadMedida')
                                .map((obj)=>{
                                return <option value={obj.id}>{obj.descripcion}</option>
                            })}
                        </select>
                    </div>

                    <div className="input-group mb-3" style={estilos.fila}>
                        <div style={{width:'113%'}}><p>Fecha Inicial</p></div>
                        <input onChange={({target})=>handleForm(target)} name="fecha" type="datetime-local" className="form-control" 
                            aria-label="fecha" aria-describedby="fecha"  value={txtFechaInicial} />
                    </div> 
                    <div className="input-group mb-3" style={estilos.fila}>
                        <div style={{width:'113%'}}><p>Fecha Final</p></div>
                        <input onChange={({target})=>handleForm(target)} name="fechafinal" type="datetime-local" className="form-control" 
                            aria-label="fechafinal" aria-describedby="fechafinal"  value={txtFechaFinal  } />
                    </div>     
                    <div style={estilos.fila} className="input-group mb-3">
                        <div style={{width:'113%'}}><p>Cantidad</p></div>
                        <div className="input-group mb-3">
                            <input onChange={({target})=>handleForm(target)} name="cantidad" type="number" className="form-control" 
                                placeholder="Ingresa la cantidad" aria-label="amount" aria-describedby="basic-addon1" value={txtCantidad} />
                        </div>
                    </div>          
                </Modal.Body>

                
                <Modal.Footer>
                    <div style={{...estilos.fila, marginBottom:'1rem', marginLeft:'10px'}}>
                        <IconContext.Provider value={{ color: "gray", className: "global-class-name", size:'2em' }}>
                            {msgError === '' && Number(txtCantidad)>0 && idComboUnidadMedida!==null &&
                                txtFechaInicial!==null && txtFechaFinal!==null && 
                                <FaSave onClick={()=>guardar()} className="cursor" title='Guardar' style={estilos.estiloIcono}/>
                            }
                            <BsXSquare onClick={()=>cerrar()} className="cursor" title='Cerrar' style={estilos.estiloIcono}/>
                        </IconContext.Provider>
                    </div>
                </Modal.Footer>
            </SweetAlert>   
        )
    }
    else{
        return () => {
            // TODO: limpiar stateEjecutarActividad
          //  settxtCantidad(null)
          //  settxtFecha(null)
        }
    }
}

const estilos = {
    fila:{display:'flex', justifyContent:'space-between', alignItems:'center'},
    estiloIcono: {marginLeft:'5px', marginRight:'5px'}
}