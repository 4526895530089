import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import Button from '@material-ui/core/Button';
import { ViewRecursos } from './views/ViewRecursos';
import '../../componentes/estilos/actividades.css'
import { GetUnaActividad , GetRecursosTarea  } from '../../componentes/ConsumirApiRest'
import { GuardaRecursosEtapaTarea } from '../../componentes/Ejecucion/ejecucionOffline'

export const Recursos = (props) => {
    const [stateActividades, setStateActividades] = useState({step:1,titlePage: 'Recursos Tarea',
                                                            apiRecursos:{
                                                                "idreporte": props.objsolicitudRecursos.idreporte,
                                                                "idtarea" : props.objsolicitudRecursos.idtarea,
                                                                "etapatarea": props.objsolicitudRecursos.etapatarea,
                                                                "idactividad": props.objsolicitudRecursos.idactividad,
                                                                "datos":[],
                                                                "fechai":props.objsolicitudRecursos.fechaInicial !== undefined ? props.objsolicitudRecursos.fechaInicial : null,
                                                                "fechaf":props.objsolicitudRecursos.fechaFinal !== undefined ? props.objsolicitudRecursos.fechaFinal : null
                                                            }});
    const programacion = props.esProgramacion !== undefined && props.esProgramacion!== null ? props.esProgramacion : false ;

    const {step, titlePage} = stateActividades;
    const [apiActividad, setApiActividad] = useState(null)    
    const [apiActividadRecurso, setApiActividadRecurso] = useState(props.apiActividadRecurso)    
    const [actualizarRecursos,setActualizarRecursos]= useState(true)    
    const [msgError,setMsgError]=useState(null);
    const {origenPantalla} = props
    const updateStateActividades = (variable, valor) => setStateActividades({...stateActividades, [variable]:valor})
    const cerrar = () => {                   
        props.setShowActividadCampo(false);    
    }

    let objRecursos = stateActividades.apiRecursos;

    const GuardarReporteRecurso = () => {
        console.log(apiActividadRecurso);  
        const recursosEjecucionDto={
            idreporte : objRecursos.idreporte,
            idtarea : objRecursos.idtarea,
            etapatarea: objRecursos.etapatarea
        };        
        GuardaRecursosEtapaTarea(recursosEjecucionDto)
        cerrar()
    }

    useEffect(() => {
        const getRecursos = async () => {
            let datos = null
            console.log(props.stepInicio);
            let tempstep =  0 ;
            if (props.stepInicio!==undefined && props.stepInicio!== null){
                tempstep= props.stepInicio;
            }
            if(tempstep === 1){
                if(objRecursos.idreporte !== undefined && objRecursos.idreporte != null && 
                    objRecursos.etapatarea !== undefined && objRecursos.etapatarea != null){
                   // if (programacion){
                   //     datos= await GetRecursosActividad(objRecursos.idactividad);
                   // }
                   // else{
                        datos= await GetRecursosTarea(objRecursos.idtarea, objRecursos.idreporte, objRecursos.etapatarea)
                   // }
                   
                    //datos= await CargarObjRecursosEjeEtapaTarea(objRecursos.idtarea, objRecursos.idreporte, objRecursos.etapatarea)
                     //se hace  para generar las filas de recursos
                     
                     //CM12-ABR-23; NO ES NECESARIO VALIDAR LA FECHA FINAL DEL FORMATO CON LA FECHA DE HOY, ESTO ES VISUALIAZ
                    //const fechaActual = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]

                    if (datos!==undefined && datos.datatable!==undefined){
                        datos.datatable.forEach((item)=>{                            
                            //CM12-ABR-23; NO ES NECESARIO VALIDAR LA FECHA FINAL DEL FORMATO CON LA FECHA DE HOY, ESTO ES VISUALIAZ
                            //const fechad =new Date (new Date(item.fechaFinal).toDateString())
                            //const fechaActualformato = new Date (new Date(fechaActual).toDateString());                            
                            // if (programacion===false &&
                            // fechad >= fechaActualformato){
                            if (programacion===false ){
                                const itemRec ={
                                    "id": item.id,
                                    "idrecurso": item.idrecurso,
                                    "recurso": item.descripcion,
                                    "fecha": item.fecha,
                                    "fechaFinal": item.fechaFinal,
                                    "cantidad": item.cantidad,
                                    "idtarea" : datos.idtarea,
                                }
                                objRecursos.datos.push(itemRec);
                            }
                            else if (programacion){
                                const itemRec ={
                                    "id": item.id,
                                    "idrecurso": item.idrecurso,
                                    "recurso": item.descripcion,
                                    "fecha": item.fecha,
                                    "fechaFinal": item.fechaFinal,
                                    "cantidad": item.cantidad,
                                    "idtarea" : datos.idtarea,
                                }
                                objRecursos.datos.push(itemRec);
                            }
                        });    
                        datos.datos=objRecursos.datos;
                    }else if (datos.hasOwnProperty('mensaje')){
                        setMsgError(datos.mensaje);                        
                    }
                }
                else{
                    datos = await GetUnaActividad(objRecursos.idreporte, null,()=>console.log,'variable')
                }                
                setApiActividadRecurso(datos)   
                setActualizarRecursos(false)
                updateStateActividades('titlePage', '');
            }

            if(tempstep === 1 && datos!==null){
                updateStateActividades('titlePage',
                    <div> {datos.tituloPantalla}
                        <button onClick={cerrar} type="button" className="btn btn-outline-danger btn-sm" style={{position:'absolute', right:'10px'}}>
                            Cerrar
                        </button>
                    </div> );
            }
        }
        if (actualizarRecursos)
            getRecursos()
    }, [props.showActividadCampo,actualizarRecursos])

    if (msgError){
        return(
            <SweetAlert
                show={props.showActividadCampo}
                title={msgError}
                onConfirm={cerrar}
                showCancel={false}
                showConfirm={true}                
                closeOnClickOutside={false}
                showCloseButton={true}>                                
            </SweetAlert>
            )
    }else{    
        return (
            <SweetAlert
                show={props.showActividadCampo}
                title={titlePage}
                onConfirm={()=>null}
                showCancel={false}
                showConfirm={false}
                confirmBtnText={"Guardar"}
                closeOnClickOutside={false}
                showCloseButton={true}>
                
                <div style={{backgroundColor:'aliceblue', borderRadius:'1%'}} className="border">                                
                    { (props.stepInicio === 1)  && <ViewRecursos 
                                                        objsolicitudRecursos={objRecursos}
                                                        apiActividadRecurso={apiActividadRecurso}
                                                        esProgramacion={programacion}
                                                        esActividad={false}
                                                        idReporteEjecucion ={objRecursos.idreporte}                                                                                                                                                           
                                                        setActualizarRecursos={setActualizarRecursos}
                                                        origenPantalla={origenPantalla}                  
                                                /> }                 
                </div>
                <div>                        
                
            </div>
            </SweetAlert>
        )
                }
}
/*linea 146 se elimina el botón guardar de la vista pues el guardado offline se hace en el popup
<Button  variant="contained" color="primary" onClick={()=>GuardarReporteRecurso()}>
                Guardar
            </Button>
*/