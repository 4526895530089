import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import Steps from '../../componentes/Actividades/Steps'
import { View3W } from './views/View3W';
import { ViewRecursos } from './views/ViewRecursos';
import { ViewVariables } from './views/ViewVariables';
import '../../componentes/estilos/actividades.css'
import {GetUnaActividad,VariablesEjecucionEtapaTarea, GetRecursosTarea, GetRecursosActividad, ModificaRecursoTarea } from '../../componentes/ConsumirApiRest'


export const Actividad = (props) => {
    const [stateActividades, setStateActividades] = useState({step:0,
                                                            titlePage: 'Actividad 3W - { Estado }',
                                                            apiRecursos:{}});
    const {step, titlePage} = stateActividades;
    const [apiActividad, setApiActividad] = useState(null);    
    const [actividadCampo, setActividadCampo] = useState(null);   
    const [apiActividadVariable, setApiActividadVariable] = useState(null);
    const [apiActividadRecursos, setApiActividadRecursos] = useState({
                                                                        datos:[props.apiActividad]
                                                                    });
    const [actualizarRecursos,setActualizarRecursos]= useState(true) ;
    const modoActividadCampo= props.esActividadCampo!== undefined && props.esActividadCampo!==null ? props.esActividadCampo : false;
    const [recursosActCampo,setRecursosActCampo]=useState([]);  
    const [variablesActCampo,setVariablesActCampo]=useState(null); 

    const updateStateActividades = (variable, valor) => {
        setStateActividades({...stateActividades, [variable]:valor});
    }

    const updateStateActividadCampo = (variable, valor) => {
        if (modoActividadCampo){
            setActividadCampo({...actividadCampo, [variable]:valor});
        }
    }
     
    const updateStateActividadCampoNuevo = (datos) => {
        if (modoActividadCampo){
            setActividadCampo({...datos});
        }        
    }
    const cerrar = () => props.setShowActividadCampo(false);

    let objsolicitudRecursos =  {            
            idreporte: props.apiActividadRecurso.idreporte,
            idtarea : props.apiActividadRecurso.idtarea,
            etapatarea: props.apiActividadRecurso.etapatarea,
            fechai:null,
            fechaf:null,
        };

    const CrearObjetoActividadCampo = () =>{
        const objActividadCampo={...actividadCampo, 
                                    recursos: [...recursosActCampo],
                                    variables: {...variablesActCampo}}
        return objActividadCampo;
    }

    useEffect(() => {
        const getActividad = async () => {
            let datos = null
           // console.log(props.stepInicio);
            let tempstep =  0 ;
            if (props.stepInicio!==undefined && props.stepInicio!== null){
                tempstep= props.stepInicio;
            }   
            else if(stateActividades!==undefined && stateActividades.step!== null){
                tempstep= stateActividades.step;
            }
            if (tempstep === 0) {
                if (actividadCampo=== undefined || actividadCampo===null){
                    datos = await GetUnaActividad(props.idAbrirActividad, null,()=>console.log('Offline'),'programacion',modoActividadCampo)                    
                    if (modoActividadCampo===true){
                        objsolicitudRecursos.idtarea=datos.idactividadpmo;
                        updateStateActividadCampoNuevo(datos);
                    }else{
                        setActividadCampo(null)
                    }
                }else{                    
                    datos={...actividadCampo};
                }
                setApiActividad(await datos)                    
                if (datos.fechainiprog!==undefined && datos.fechainiprog!=null &&
                    datos.fechafinprog!==undefined && datos.fechafinprog!=null){

                        objsolicitudRecursos.fechai=datos.fechainiprog
                        objsolicitudRecursos.fechaf=datos.fechafinprog
                }
                            
            }else if(tempstep === 1){
                
               if(props.idAbrirActividad !== undefined && props.idAbrirActividad != null && 
                    props.idreporte !== undefined && props.idreporte != null && 
                    props.etapatarea !== undefined && props.etapatarea != null){
                    datos= await GetRecursosTarea(objsolicitudRecursos)
                    objsolicitudRecursos.fechai=datos.fechainicial;
                    objsolicitudRecursos.fechaf=datos.fechafinal;
                }
                else{
                    //datos = await GetRecursosTarea(props.apiActividadRecurso.idreporte, null,()=>console.log,'variable')                   
                    datos= await GetRecursosActividad(props.idAbrirActividad,modoActividadCampo);   
                    if (modoActividadCampo===true){
                        objsolicitudRecursos.fechai=actividadCampo.fechainiprog;
                        objsolicitudRecursos.fechaf=actividadCampo.fechafinprog;
                        datos.datatable=[...recursosActCampo];
                        if (objsolicitudRecursos.idtarea===null){
                            objsolicitudRecursos.idtarea=actividadCampo.idactividadpmo;
                        }
                        datos.idtarea=objsolicitudRecursos.idtarea;
                    }else{
                        objsolicitudRecursos.fechai=datos.fechainicial;
                        objsolicitudRecursos.fechaf=datos.fechafinal;                        
                    }
                }
                
                //datos= await GetRecursosTarea(objsolicitudRecursos);
                let objApiRecursos={apiRecursos: datos ,
                                    datos:[]}
                //se hace  para generar las filas de recursos
                datos.datatable.forEach((item)=>{
                    const itemRec ={
                        "id": item.id,
                        "idrecurso": item.idrecurso,
                        "recurso": item.descripcion,
                        "fecha": item.fecha,
                        "fechaFinal": item.fechaFinal,
                        "cantidad": item.cantidad,
                        "idtarea" : datos.idtarea,
                    }
                    objApiRecursos.datos.push(itemRec);
                });               
                setApiActividadRecursos(objApiRecursos);
                updateStateActividades('apiRecursos', objApiRecursos.apiRecursos);
                setActualizarRecursos(false)
            }else if(tempstep === 2){
                if(props.idAbrirActividad !== undefined && props.idAbrirActividad != null && 
                    props.idreporte !== undefined && props.idreporte != null && 
                    props.etapatarea !== undefined && props.etapatarea != null){
                    datos= await VariablesEjecucionEtapaTarea(props.idAbrirActividad, props.idreporte, props.etapatarea)
                }
                else{
                    datos = await GetUnaActividad(props.idAbrirActividad, null,()=>console.log,'variable', modoActividadCampo)
                    if (modoActividadCampo===true ){
                        if (variablesActCampo===null){
                            setVariablesActCampo({...datos});
                        }else{
                            datos={...datos,...variablesActCampo};
                        }                        
                    }
                }
                
                setApiActividadVariable(datos)
            }else {
                updateStateActividades('titlePage', '');
            }

            if(tempstep !== 1&&datos!==null){
                updateStateActividades('titlePage',
                    <div> {datos.titulopantalla||datos.tituloPantalla}
                        <button onClick={cerrar} type="button" className="btn btn-outline-danger btn-sm" style={{position:'absolute', right:'10px'}}>
                            Cerrar
                        </button>
                    </div> );
            }
        }

        if (step ===2 || actualizarRecursos || step ===0){
            //(step ===0 && actividadCampo!== undefined && actividadCampo!==null)){
                getActividad();
            }            
    }, [step,actualizarRecursos]);

    
    return (
        <SweetAlert
            show={props.showActividadCampo}
            title={titlePage}
            onConfirm={()=>null}
            showCancel={false}
            showConfirm={false}
            confirmBtnText={"Aceptar"}
            closeOnClickOutside={false}
            showCloseButton={true}>
            <div style={{backgroundColor:'aliceblue', borderRadius:'1%'}} className="border">
                { (step === 0)  && <View3W apiActividad={apiActividad}
                                            esActividadCampo={modoActividadCampo}
                                            updateStateActividadCampo={updateStateActividadCampo}/> }
                { (step === 1)  && <ViewRecursos objsolicitudRecursos={objsolicitudRecursos}
                                                apiActividadRecursos={apiActividadRecursos}
                                                esProgramacion={true}                                                
                                                setActualizarRecursos={setActualizarRecursos}                                                                                                 
                                                esActividad={true}
                                                origenPantalla='Actividad'
                                                esActividadCampo={modoActividadCampo}
                                                recursosActCampo={recursosActCampo}
                                                setRecursosActCampo={setRecursosActCampo}
                                                /> }
                { (step === 2 || props.stepInicio === 2)  && <ViewVariables apiActividadVariable={apiActividadVariable} 
                                                                            esActividadCampo={modoActividadCampo} 
                                                                            variablesActCampo={variablesActCampo} 
                                                                            /> }
                <Steps updateStateActividades={updateStateActividades} 
                       stateActividades={stateActividades}
                       cerrar={cerrar}
                       apiActividad={apiActividad}
                       idActividad={props.idAbrirActividad}
                       setIdActividad={props.setIdAbrirActividad}
                       stepInicio={props.stepInicio}
                       esActividadCampo={modoActividadCampo}
                       objActividadCampo={CrearObjetoActividadCampo()}
                       setVariablesActCampo={setVariablesActCampo}/>
            </div>
        </SweetAlert>
    )
}
